import React from "react";
import { Col, Container, Image, Row, Tab, Tabs } from "react-bootstrap";
import DesktopWrapper from "../../layout/desktop/DesktopWrapper";
import HeaderImage from "../../assets/images/aboutUs/header.png";
import TravelImage from "../../assets/images/aboutUs/travel.png";
import HandShakeIcon from "../../assets/images/aboutUs/hand-shake.svg";
import FistBumpIcon from "../../assets/images/aboutUs/fist-bump.svg";
import SociaCareIcon from "../../assets/images/aboutUs/social-care.svg";
import SolutionIcon from "../../assets/images/aboutUs/solution.svg";
import TieIcon from "../../assets/images/aboutUs/tie.svg";
import AdiPuteraWidjajaImage from "../../assets/images/aboutUs/AdiPuteraWidjaja.png";
import ClaudiaIngkiriwangImage from "../../assets/images/aboutUs/ClaudiaInkiriwang.png";
// import FettyAsmaniatiImage from "../../assets/images/aboutUs/FettyAsmaniati.png";
// import AndrianeClaudiaSinagaImage from "../../assets/images/aboutUs/AndrianeClaudiaSinaga.png";
import SupandiWidiSusantoImage from "../../assets/images/aboutUs/SupandiWidi.png";
import Benny from "../../assets/images/aboutUs/Benny.png";

function AboutUsPage() {
  return (
    <DesktopWrapper>
      <Image src={HeaderImage} className="about-us-image header" />
      <Container className="additional-page">
        <Row className="my-5 large-space">
          <Col md="6" lg="6" xl="6">
            <h4 className="text-primary mb-3">How we started</h4>
            <p className="fw-light">
              PT Tourindo Guide Indonesia Tbk, Pigijo is a technology-based
              startup company in the field of tourism in Indonesia.
            </p>
            <p className="fw-light">
              Established since February 2017 in Jakarta, Pigijo has now
              succeeded in bringing together providers of tourist attractions in
              Indonesia with travelers.
            </p>
            <p className="fw-light">
              Pigijo also has a #SupportLocalExpert movement which helps educate
              and empower tourism activists, especially local guides and MSMEs.
            </p>
          </Col>
          <Col md="6" lg="6" xl="6" className="text-end">
            <Image src={TravelImage} className="travel-img" />
          </Col>
        </Row>
        <Row className="my-5 text-center justify-content-center large-space">
          <Col md="5" lg="5" xl="5">
            <h4 className="text-primary">Our Mission</h4>
            <p className="fw-light">
              We believe that by providing inspiration for foreign tourists to
              come, stay longer, and interact with local residents can help the
              economy of the Indonesian people in the tourism sector.
            </p>
          </Col>
          <Col md="5" lg="5" xl="5">
            <h4 className="text-primary">Our Goal</h4>
            <p className="fw-light">
              Become a tourism platform with added value for tourists in meeting
              all travel needs. Starting from looking for references, bookings,
              transactions, to travel guides.
            </p>
          </Col>
          <Col md="5" lg="5" xl="5" className="mt-5">
            <h4 className="text-primary">Why Indonesia?</h4>
            <p className="fw-light">
              At Pigijo, we believe that Indonesia has a diverse culture,
              friendly people and stunning natural scenery. Therefore, Pigijo is
              here to show that beauty exists on earth, precisely in Indonesia.
            </p>
          </Col>
        </Row>
        <Row className="my-5 text-center justify-content-center values">
          <Col md="12" lg="12" xl="12">
            <h4 className="text-primary mb-5">Our Values</h4>
          </Col>
          <Col md="4" lg="4" xl="4">
            <Image src={HandShakeIcon} />
            <h5 className="text-primary mb-3">Fair Trade</h5>
            <p>We conduct business fairly with our partners and customers.</p>
          </Col>
          <Col md="4" lg="4" xl="4">
            <Image src={TieIcon} />
            <h5 className="text-primary mb-3">
              Professional with High Integrity
            </h5>
            <p>Uphold personal integrity and professionalism.</p>
          </Col>
          <Col md="4" lg="4" xl="4">
            <Image src={FistBumpIcon} />
            <h5 className="text-primary mb-3">Persistence</h5>
            <p>We conduct business fairly with our partners and customers.</p>
          </Col>
          <Col md="4" lg="4" xl="4">
            <Image src={SociaCareIcon} />
            <h5 className="text-primary mb-3">Support Local Business</h5>
            <p>
              All of our partners are local tourism entrepreneurs, so the growth
              of local tourism will have a direct economic impact on the local
              population.
            </p>
          </Col>
          <Col md="4" lg="4" xl="4">
            <Image src={SolutionIcon} />
            <h5 className="text-primary mb-3">
              Creative People for Creative Environment
            </h5>
            <p>
              All of our employees are creative people and work in an
              environment that encourages them to be creative.
            </p>
          </Col>
        </Row>
      </Container>
      <div style={{ backgroundColor: "#F5F5F5" }}>
        <Container className="pt-5">
          <h4 className="text-primary text-center mb-5">Milestone</h4>

          <section class="design-section">
            <div class="timeline">
              <div class="timeline-component timeline-content">
                <h5>2017</h5>
                <p>Company founded</p>
              </div>
              <div class="timeline-middle">
                <div class="timeline-circle"></div>
              </div>
              <div class="timeline-empty"></div>
              <div class="timeline-empty"></div>
              <div class="timeline-middle">
                <div class="timeline-circle"></div>
              </div>
              <div class="timeline-component timeline-content text-start">
                <h5>2018</h5>
                <p>
                  <ul>
                    <li>
                      Start growing business by expanding relationship with the
                      blogger community
                    </li>
                    <li>Soft launching website www.pigijo.com</li>
                    <li>
                      Building relationships with vendors/business partners
                      (vendor acquisition)
                    </li>
                  </ul>
                </p>
              </div>
              <div class="timeline-component timeline-content">
                <h5>2019</h5>
                <p>
                  Become a marketplace for local experiences, air transportation
                  tickets, lodging, local assistants, souvenirs, and restaurant
                  destinations
                </p>
              </div>
              <div class="timeline-middle">
                <div class="timeline-circle"></div>
              </div>
              <div class="timeline-empty"></div>
              <div class="timeline-empty"></div>
              <div class="timeline-middle border-0">
                <div class="timeline-circle"></div>
              </div>
              <div class="timeline-component timeline-content text-start">
                <h5>2020</h5>
                <p>
                  Fundraising through Initial Public Offering (IPO) and listing
                  of shares on the Indonesia Stock Exchange
                </p>
              </div>
            </div>
          </section>
        </Container>
      </div>
      <Container className="py-5 additional-page">
        <h4 className="text-primary text-center">Our Management</h4>
        <Tabs
          defaultActiveKey="commissioner"
          id="management"
          className="my-5 management-tabs"
          fill
        >
          <Tab eventKey="commissioner" title="Commissioner">
            <Row className="management-img text-center justify-content-center">
              <Col sm="4" md="4" lg="4" xl="4">
                <Image src={ClaudiaIngkiriwangImage} />
                <h5 className="mt-4">Claudia Ingkiriwang</h5>
                <p className="fw-light mt-3">Komisaris Utama</p>
              </Col>
              <Col sm="4" md="4" lg="4" xl="4">
                <Image src={SupandiWidiSusantoImage} />
                <h5 className="mt-4">Supandi Widi Susanto</h5>
                <p className="fw-light mt-3">Komisaris Independen</p>
              </Col>
              {/* <Col sm="4" md="4" lg="4" xl="4">
                <Image src={FettyAsmaniatiImage} />
                <h5 className="mt-4">Fetty Asmaniati</h5>
                <p className="fw-light mt-3">Komisaris Independen</p>
              </Col> */}
            </Row>
          </Tab>
          <Tab eventKey="director" title="Director">
            <Row className="management-img text-center justify-content-center">
              <Col sm="4" md="4" lg="4" xl="4">
                <Image src={AdiPuteraWidjajaImage} />
                <h5 className="mt-4">Adi Putera Widjaja</h5>
                <p className="fw-light mt-3">Direktur Utama</p>
              </Col>
              <Col sm="4" md="4" lg="4" xl="4">
                <Image src={Benny} />
                <h5 className="mt-4">Benny</h5>
                <p className="fw-light mt-3">Direktur Keuangan</p>
              </Col>
            </Row>
          </Tab>
        </Tabs>
        <div className="large-space"></div>
      </Container>
    </DesktopWrapper>
  );
}
export default AboutUsPage;
