import React, { useContext } from "react";
import PersonOutlineIcon from "../../../../assets/icons/personOutline.svg";
import ProfileIcon from "../../../../assets/icons/profile.svg";
import OrderIcon from "../../../../assets/icons/order.svg";
import ProfileOrangeIcon from "../../../../assets/icons/profileOrange.svg";
import OrderOrangeIcon from "../../../../assets/icons/orderOrange.svg";
import SignOutIcon from "../../../../assets/icons/signOut.svg";
import { Card, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../../../services/auth";
import { useTranslation } from "react-i18next";

function LeftComponent(params) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  let navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  return (
    <Card className="border-0 shadow-sm profile-left-card">
      <Card.Body>
        <div className="profile-image">
          <Image src={PersonOutlineIcon} />
        </div>
        <hr></hr>
        <div className="my-4">
          <div>
            <Link
              to="/profile"
              className={`${
                pathname.split("/")[1] === "profile"
                  ? "text-primary"
                  : "text-black"
              } text-decoration-none d-flex align-items-center mb-3`}
            >
              <Image
                src={
                  pathname.split("/")[1] === "profile"
                    ? ProfileOrangeIcon
                    : ProfileIcon
                }
              />
              <span className="ps-3">{t("profile")}</span>
            </Link>
          </div>
          <div>
            <Link
              to="/order"
              className={`${
                pathname.split("/")[1] === "order"
                  ? "text-primary"
                  : "text-black"
              } text-decoration-none d-flex align-items-center mb-3`}
            >
              <Image
                src={
                  pathname.split("/")[1] === "order"
                    ? OrderOrangeIcon
                    : OrderIcon
                }
              />
              <span className="ps-3">{t("order")}</span>
            </Link>
          </div>
          <div>
            <Link
              to="/order-flight"
              className={`${
                pathname.split("/")[1] === "order-flight"
                  ? "text-primary"
                  : "text-black"
              } text-decoration-none d-flex align-items-center`}
            >
              <Image
                src={
                  pathname.split("/")[1] === "order-flight"
                    ? OrderOrangeIcon
                    : OrderIcon
                }
              />
              <span className="ps-3">{t("order_flight")}</span>
            </Link>
          </div>
        </div>
        <hr></hr>
        <div
          className="text-danger"
          role="button"
          onClick={() => {
            navigate("/");
            logout();
          }}
        >
          <Image src={SignOutIcon} />
          <span className="ps-3">{t("logout")}</span>
        </div>
      </Card.Body>
    </Card>
  );
}
export default LeftComponent;
