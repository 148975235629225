import React, { useContext, useEffect, useState } from "react";
import {
  Carousel,
  Col,
  Container,
  Image,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import RightArrow from "../../../../assets/icons/rightArrow.svg";
import { Link } from "react-router-dom";
import UserContext from "../../../../services/user";
import { SkeletonCustom } from "../../../../component/global/skeleton";

function Section5() {
  const { loading, getAllMaster } = useContext(UserContext);
  const [data, setData] = useState([]);

  function chunkArray(arr, n) {
    var newN = Math.floor(n / 8);
    var chunkLength = Math.max(arr.length / newN, 1);
    var chunks = [];
    for (var i = 0; i < newN; i++) {
      if (chunkLength * (i + 1) <= arr.length)
        chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
    }
    return chunks;
  }

  const getData = async () => {
    const newData = await getAllMaster(`partner`);
    if (newData) {
      let newArray = [];
      newData.data?.map((item) => {
        if (!(item.category in newArray)) {
          newArray[item.category] = [];
        }
        newArray[item.category].push(item);
      });
      setData(newArray);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container className="section5 mt-xl">
      {loading ? (
        <SkeletonCustom height="500px" />
      ) : (
        <Tabs
          defaultActiveKey={Object.keys(data)?.[0]?.replace(/ /g, "_")}
          className="partners-tab"
        >
          {data &&
            Object.keys(data)?.map((item, index) => (
              <Tab eventKey={item?.replace(/ /g, "_")} title={item} key={index}>
                <Row className={"align-items-center overflow-card"}>
                  {data[item] &&
                    data[item].map((subItem, subIndex) => (
                      <Col
                        xs="3"
                        md="3"
                        lg="3"
                        xl="3"
                        key={subIndex}
                        className="text-center"
                      >
                        <Image
                          src={
                            process.env.REACT_APP_FILE_URL +
                            "/" +
                            subItem.picture
                          }
                          className="img-partners"
                        />
                      </Col>
                    ))}
                </Row>
              </Tab>
            ))}
        </Tabs>
      )}
    </Container>
  );
}

export default Section5;
