import React from "react";
import HeroSection from "../../../component/desktop/card/hero";
import DesktopWrapper from "../../../layout/desktop/DesktopWrapper";
import ListTravelAssistance from "./components/list";

function TravelAssistanceDesktopPage() {
  return (
    <DesktopWrapper>
      <HeroSection active={2} />
      <ListTravelAssistance />
    </DesktopWrapper>
  );
}
export default TravelAssistanceDesktopPage;
