import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ItemDestination from "./itemDestination";
import UserContext from "../../../../services/user";

function Section2(params) {
  const { getAllMaster } = useContext(UserContext);
  const [data, setData] = useState();

  const getData = async (l) => {
    const newData = await getAllMaster(`menu/destinations`);
    if (newData) {
      setData(
        newData?.data
          ?.filter(function (el) {
            return el?._id !== params?.current;
          })
          ?.slice(0, 4)
      );
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container className="section2-destination">
      <div className="mb-5">
        <div className="d-flex align-items-center justify-content-between fs-5 mt-5 pb-4">
          <div className="fs-4 fw-bold w-75">Destinasi Populer Lainnya</div>
        </div>

        <Row className="mb-4 align-items-center justify-content-center">
          {data &&
            data.map((item, index) => (
              <Col sm="12" md="6" lg="3" xl="3" key={index} className="mb-3">
                <ItemDestination data={item} />
              </Col>
            ))}
        </Row>
      </div>
    </Container>
  );
}

export default Section2;
