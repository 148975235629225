const reducer = (state, { type, payload, loadingCheck = null }) => {

  if (type==="loading") {
    return {
      loading: true,
    };
  } else {
    return {
      ...state,
      loading: false,
      loadingCheck: loadingCheck,
      data: payload,
    };
  }
};

export default reducer;
