import FacebookLogin from "@greatsumini/react-facebook-login";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ApiWithAuth from "../../config/api/ApiWithAuth";
import AuthContext from "../../services/auth";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

function SocialMediaLogin(params) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loading, loginByGoogle } = useContext(AuthContext);

  const login = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (tokenResponse) => {
      let response = await loginByGoogle(tokenResponse);
      if (response) {
        setTimeout(() => {
          ApiWithAuth.setToken(response?.accessToken?.token);
          navigate("/");
        }, 500);
      }
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  return (
    <div className="my-4 login-social-media">
      <div className="text-grey fw-light label-login">{t("or")}</div>
      <Button className="buttonFacebook" onClick={() => login()}>
        <div className="icon-google"></div>
        <div className="text-google">{t("or_login_with")} Google</div>
      </Button>
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_KEY}
        onSuccess={(response) => {
          console.log("Login Success!", response);
        }}
        onFail={(error) => {
          console.log("Login Failed!", error);
        }}
        onProfileSuccess={(response) => {
          console.log("Get Profile Success!", response);
        }}
        render={({ onClick, logout }) => (
          <Button
            className="buttonFacebook"
            onClick={onClick}
            onLogoutClick={logout}
          >
            <div className="icon-fb"></div>
            <div className="text-fb">{t("or_login_with")} Facebook</div>
          </Button>
        )}
      />
    </div>
  );
}

export default SocialMediaLogin;
