import React, { useContext, useEffect, useState } from "react";
import { Badge, Button, Card, Col, Form, Image, Row } from "react-bootstrap";
import ReactSelect from "react-select";
import LocalExperienceIcon from "../../../../assets/icons/localExperience.svg";
import PersonOutlineIcon from "../../../../assets/icons/personOutline.svg";
import CalenderInIcon from "../../../../assets/icons/calenderIn.svg";
import OrderIcon from "../../../../assets/icons/orderOrange.svg";
import moment from "moment";
import { Link } from "react-router-dom";
import UserContext from "../../../../services/user";
import { useTranslation } from "react-i18next";

function Order(params) {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const { loading, getAllMaster } = useContext(UserContext);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(9999);

  const getData = async () => {
    const newData = await getAllMaster(
      `order/my-orders?page=${page}&limit=${pageLimit}`
    );
    if (newData) {
      setData(newData.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const badgeBackground = (item) => {
    let color = "light-grey";

    if (item === "settlement" || item === "capture") {
      color = "light-success";
    } else if (item === "pending") {
      color = "light-info";
    } else if (item === "Waiting Confirmation") {
      color = "light-warning";
    } else if (item === "Expired" || item === "deny") {
      color = "light-danger";
    }

    return color;
  };

  const badgeColor = (item) => {
    let color = "text-dark";

    if (item === "settlement" || item === "capture") {
      color = "text-success";
    } else if (item === "pending") {
      color = "text-info";
    } else if (item === "Waiting Confirmation") {
      color = "text-warning";
    } else if (item === "Expired" || item === "deny") {
      color = "text-danger";
    }

    return color;
  };

  return data.length > 0 ? (
    data &&
      data.map((item, index) => (
        <Card className="mb-3 border-0 shadow-sm" key={index}>
          <Card.Body>
            <div className="d-flex align-items-center mb-3">
              <Image src={LocalExperienceIcon} />
              <span className="small ps-2 fw-light">
                {item?.type || "Local Experience"}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <Link to={`/order/detail/${item._id}`} className="judul text-black text-decoration-none">
                  {item?.items?.length > 0 && item?.items[0].name}
                </Link>
                <div className="text-grey fw-light mt-2">
                  {t("booking_id")}: {item.invoiceNumber}
                </div>
                <div className="d-flex fw-light small my-4 w-100">
                  <div>
                    <Image
                      src={PersonOutlineIcon}
                      style={{ maxHeight: "15px" }}
                    />
                    <span className="ps-2">
                      {item?.items?.length > 0 && item?.items[0].quantity} Guest
                    </span>
                  </div>
                  <div className="ms-4">
                    <Image src={CalenderInIcon} style={{ maxHeight: "15px" }} />
                    <span className="ps-2">
                      {moment(item.departureDate).format("ddd, DD MMMM YYYY")}
                    </span>
                  </div>
                  <div className="ms-4">
                    <Image src={OrderIcon} style={{ maxHeight: "15px" }} />
                    <span className="ps-2">
                      {item.paymentMethod === "bank_transfer" &&
                        "VA: " + item?.va?.vaNumber}
                      {item.paymentMethod === "echannel" &&
                        "VA: " +
                          item?.bill?.billerCode +
                          " " +
                          item?.bill?.billKey}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                {new Intl.NumberFormat("id-ID", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 0,
                }).format(item?.grossAmount)}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <Badge
                bg={badgeBackground(item.status)}
                className={`px-4 py-2 ${badgeColor(item.status)}`}
              >
                {item.status.toUpperCase()==="CAPTURE"?"SUCCESS":item.status.toUpperCase()}
              </Badge>
              <Link
                to={`/order/detail/${item._id}`}
                className="text-primary text-decoration-none"
              >
                {t("see_detail")}
              </Link>
            </div>
          </Card.Body>
        </Card>
      ))
  ) : (
    <Card className="border-0 shadow-sm mb-4">
      <Card.Body>
        <>No Transaction Data</>
      </Card.Body>
    </Card>
  );
}

export default Order;
