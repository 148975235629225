import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
// import SortIcon from "../../assets/icons/sort.svg";
// import FilterLocalExperience from "../../pages/localExperiences/desktop/components/filter";
// import ItemLocalExperience from "../../component/desktop/card/itemLocalExperience";
// import PaginationCard from "../../component/desktop/card/pagination";
import UserContext from "../../../services/user";
import { SkeletonCustom } from "../../../component/global/skeleton";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import GuideList from "../../component/desktop/card/guideList";
// import HeroSection2 from "../../component/desktop/card/hero2";
import DesktopWrapper from "../../../layout/desktop/DesktopWrapper";
import ItemColabExperience from "./components/item";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Logo from "../../../assets/images/partners/collab.png";
import FilterLocalExperience from "./components/filter";
import PaginationCard from "../../../component/desktop/card/pagination";

function ListColabExperience(props) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { loading, addMaster } = useContext(UserContext);
  const { category } = useParams();
  const decodedCategory = decodeURIComponent(category);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    type: ["private trip", "open trip"],
    startPrice: 1,
    endPrice: 100000000,
    search: searchParams.get("search") ?? "",
    bannerId: searchParams.get("banner"),
  });
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(200);

  const getData = async () => {
    const newData = await addMaster(
      `product/filter-products?page=${page}&limit=${pageLimit}`,
      filter
    );
    if (newData) {
      // console.log("Data Awal:", newData.data);
      const filteredData = newData.data.filter((item) =>
        item.productDetail.categories
          // .map((cat) => cat.replace(/\s+/g, "-"))
          .includes(decodedCategory)
      );
      // console.log("Data Setelah Filter:", filteredData);
      setData(filteredData);
      setTotal(filteredData.length);
      // setData(newData.data);
      // setTotal(newData.total);
    }
  };

  useEffect(() => {
    if (filter?.type?.length <= 0) {
      filter.type = ["open trip", "private trip"];
    }
    getData();
  }, [category, filter, page]);

  return (
    <Container>
      {/* <div
        id="carouselExampleIndicators"
        class="carousel slide mt-3 "
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
        </div>
        <div class="carousel-inner rounded">
          <div class="carousel-item active">
            <img
              src="https://file-investor.s3.ap-southeast-1.amazonaws.com/carosel/4.png"
              class="d-block w-100"
              alt="..."
            />
          </div>
          <div class="carousel-item">
            <img
              src="https://file-investor.s3.ap-southeast-1.amazonaws.com/carosel/21.png"
              class="d-block w-100"
              alt="..."
            />
          </div>
        </div>
        <button
          class="carousel-control-prev opacity-0"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next opacity-0"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div> */}

      <div className="d-flex align-items-between pb-3">
        <div className="w-100 h5 fw-bold">
          {t("recommended_local_experience")}
        </div>
      </div>
      <Row className="mb-5 align-items-start search-le">
        <Col sm="12" md="12" lg="4" xl="3" className="mb-5">
          <FilterLocalExperience filter={filter} setFilter={setFilter} />
        </Col>
        <Col sm="12" md="12" lg="8" xl="9">
          {loading ? (
            <SkeletonCustom height="100px" />
          ) : (
            <Row className="section2 align-items-center">
              {data &&
                data.map((item, index) => (
                  <Col
                    sm="12"
                    md="6"
                    lg="4"
                    xl="4"
                    className="mb-3"
                    key={index}
                  >
                    <ItemColabExperience data={item} />
                    {/* <GuideList data={item} /> */}
                  </Col>
                ))}
              <Col sm="12" md="12" lg="12" xl="12" className="mt-5">
                <PaginationCard
                  itemsCount={total}
                  itemsPerPage={pageLimit}
                  currentPage={page}
                  setCurrentPage={setPage}
                  alwaysShown={false}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default ListColabExperience;
