import AuthWrapper from "../../layout/desktop/AuthWrapper";
import { useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import UserContext from "../../services/user";
import { useTranslation } from "react-i18next";

const schema = yup
  .object({
    email: yup.string().email().required(),
  })
  .required();

function ResendEmailPage(params) {
  const { t } = useTranslation();
  const { loading, addMaster } = useContext(UserContext);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = async (values) => {
    let response = await addMaster(
      "user/req-forgot-password/sendback-email",
      {
        email: values.email,
      },
      "Resend Email Success"
    );
    if (!response?.err) {
      setTimeout(() => {
        navigate("/");
      }, 500);
    }
  };

  return (
    <AuthWrapper>
      <h4 className="mb-3">Resend Email Verification</h4>
      <h6 className="text-grey fw-light">
        Please resend email verification if you haven't or failed in the
        previous email verification
      </h6>
      <form className="form my-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <Form.Control
            type="email"
            {...register("email")}
            className="control-input mt-2"
            placeholder={t("email_address")}
          />
          <small className="text-danger">{errors.email?.message}</small>
        </div>
        <Button
          variant="primary"
          className="text-white w-100 mt-1"
          type="submit"
        >
          {t("login")}
        </Button>
        <div className="mt-4 fw-light">
          Already got an account?{" "}
          <Link
            to="/login"
            className="text-primary text-decoration-none fw-normal"
          >
            {t("login")}
          </Link>
        </div>
      </form>
    </AuthWrapper>
  );
}

export default ResendEmailPage;
