import React from "react";
import { BrowserView } from "react-device-detect";
import ProfileDesktopPage from "../profile/desktop";

function ProfilePage() {
  return (
    // <BrowserView>
      <ProfileDesktopPage />
    // </BrowserView>
  );
}
export default ProfilePage;
