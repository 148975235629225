import React, { useState } from "react";
import { Accordion, Card, Form, InputGroup } from "react-bootstrap";
import MultiRangeSlider from "multi-range-slider-react";

function FilterLocalExperience(props) {
  const [location, setLocation] = useState([
    {
      id: "Bali",
      name: "Bali",
    },
    {
      id: "Lombok",
      name: "Lombok",
    },
    {
      id: "Nusa Tenggara Timur",
      name: "Nusa Tenggara Timur",
    },
    {
      id: "Bandung",
      name: "Bandung",
    },
    {
      id: "Surabaya",
      name: "Surabaya",
    },
    {
      id: "Jakarta",
      name: "Jakarta",
    },
  ]);

  const [language, setLanguage] = useState([
    {
      id: "Indonesian",
      name: "Indonesian",
    },
    {
      id: "English",
      name: "English",
    },
    {
      id: "Germany",
      name: "Germany",
    },
    {
      id: "Japan",
      name: "Japan",
    },
  ]);

  const onChangeLocation = (e, item) => {
    const location = props.filter?.location;
    if (e?.target?.checked) {
      location.push(item.id);
    } else {
      var i = location.indexOf(item.id);
      if (i !== -1) {
        location.splice(i, 1);
      }
    }
    props.setFilter({
      ...props.filter,
      location: location,
    });
  };

  const onChangeLanguage = (e, item) => {
    const language = props.filter?.language;
    if (e?.target?.checked) {
      language.push(item.id);
    } else {
      var i = language.indexOf(item.id);
      if (i !== -1) {
        language.splice(i, 1);
      }
    }
    props.setFilter({
      ...props.filter,
      language: language,
    });
  };

  return (
    <Card className="border-0 shadow">
      <Card.Body>
        <div className="d-flex align-items-center justify-content-between py-3">
          <span>Filter</span>
          <span
            className="text-primary"
            role="button"
            onClick={() =>
              props.setFilter({
                tipe: [],
                start_price: 0,
                end_price: 200000000,
                location: [],
              })
            }
          >
            Reset
          </span>
        </div>
        <div className="filter-search">
          <Accordion defaultActiveKey={["0", "1"]} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Location</Accordion.Header>
              <Accordion.Body>
                {location &&
                  location.map((item, index) => (
                    <Form.Check
                      key={index}
                      reverse
                      label={item.name}
                      name={`group-${item.id}`}
                      type="checkbox"
                      id={`reverse-checkbox-${item.id}`}
                      onClick={(checked) => {
                        onChangeLocation(checked, item);
                      }}
                    />
                  ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Langauge</Accordion.Header>
              <Accordion.Body>
                {language &&
                  language.map((item, index) => (
                    <Form.Check
                      key={index}
                      reverse
                      label={item.name}
                      name={`group-${item.id}`}
                      type="checkbox"
                      id={`reverse-checkbox-${item.id}`}
                      onClick={(checked) => {
                        onChangeLanguage(checked, item);
                      }}
                    />
                  ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </Card.Body>
    </Card>
  );
}

export default FilterLocalExperience;
