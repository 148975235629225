import React from "react";
import { BrowserView } from "react-device-detect";
import PaymentDesktopPage from "./desktop/payment";

function PaymentPage() {
  return (
    // <BrowserView>
      <PaymentDesktopPage />
    // </BrowserView>
  );
}
export default PaymentPage;
