import React from "react";
import { Container } from "react-bootstrap";
import DesktopWrapper from "../../layout/desktop/DesktopWrapper";

function TermsAndConditionsPage() {
  return (
    <DesktopWrapper>
      <Container className="additional-page" style={{ textAlign: "justify" }}>
        <h4 className="mt-5 mb-3">Terms & Conditions</h4>
        <h6 className="mb-3 fw-light">Last Updated: January 2021</h6>
        <p className="fw-lighter text-dark-grey">
          These terms and conditions, subject to change from time to time, apply
          to all services, directly or indirectly (through distributors)
          available online, via any mobile device, via email or telephone. By
          accessing, browsing, and using our Website or our mobile apps or one
          of our applications through any platform (hereinafter the “Platform”)
          and / or by completing a reservation, you acknowledge and agree to
          read, understand and agree to the terms and conditions listed below.
        </p>
        <h6 className="mt-4">Service</h6>
        <p className="fw-lighter text-dark-grey">
          Services provided by Pigijo are online travel plans and activities.
          You can browse activities (many days, one day, several hours),
          accommodation, car rental, and tour guides.
        </p>
        <p className="fw-lighter text-dark-grey">
          Pigijo curates the information as much as possible and is very careful
          in providing services. We do not guarantee that all information is
          accurate, accurate, complete, true and up to date. Pigijo is not
          responsible for any errors, interruptions, inappropriate, false or
          untold information.
        </p>
        <h6 className="mt-4">Cancellation</h6>
        <p className="fw-lighter text-dark-grey">
          By placing orders and payments from the Pigijo website and
          application, you accept and agree to the terms and conditions of the
          relevant Supplier, especially with regard to the cancellation policy.
          Pigijo is not responsible for any violation of the terms and
          conditions agreed upon or changes as a result of your special request,
          so please read the terms and conditions carefully.
        </p>
        <h6 className="mt-4">Product Prices</h6>
        <p className="fw-lighter text-dark-grey">
          The price selected by your data includes the selected payment method.
        </p>
        <p className="fw-lighter text-dark-grey">
          Pigijo tries to provide the best or lower price and / or promotions
          and from time to time. Each price and promotion has its own terms and
          conditions which may result in changes to the cancellation policy,
          ordering and reducing money. So that the terms and conditions are read
          properly.
        </p>
        <p className="fw-lighter text-dark-grey">
          Pigijo is subject to change when time changes occur if you get a
          booking confirmation.
        </p>
        <h6 className="mt-4">Refund</h6>
        <p className="fw-lighter text-dark-grey">
          Each price specified in Pigijo is only available under certain
          conditions and the price is subject to change depending on ordering,
          ordering and / or inventory limits. The available prices may include
          additional taxes and other fees but in certain circumstances may not
          include taxes and other service fees (such as tip fees for other
          facility fees (if any), and other costs that arise outside of our
          product information); You agree that they are responsible for
          verifying the total fees payable and other terms and details when the
          confirmation email is sent to you. You must verify your order on the
          order sheet; You can cancel the order at any time before confirmation
          is made. Prices are very detailed so you can see the amount to be
          paid, any additional fees using a credit card or inter-bank fees
          charged for shipping will be charged to you and if there is a shortage
          in the amount that you can, Pigijo can provide an email notification
          regarding lacks in the amount you have.
        </p>
        <p className="fw-lighter text-dark-grey">
          Refunds may not be immediately after payment occurs, depending on the
          original payment method. You can contact Pigijo customer service for
          more details on the estimated duration of receiving your funds and we
          will assist you as best we can.
        </p>
        <p className="fw-lighter text-dark-grey">
          In connection with cancellations, if included, there are fees that
          Pigijo will produce as admin fees or fees due to cancellations (eg
          inter-bank transfer fees).
        </p>
        <p className="fw-lighter text-dark-grey">
          Further details regarding the fees that will be charged the service
          fee must be ''. '' Suppliers can contact Pigijo's customers (during
          the operational hours of customer service by Pigijo).
        </p>
        <h6 className="mt-4">Personal Data (Guest)</h6>
        <p className="fw-lighter text-dark-grey">
          When you make an order or access your Personal Account information,
          you will use Secure Server Layer (SSL) access so that it will encrypt
          the information you send through this Website. We consider your
          privacy to be important.
        </p>
        <p className="fw-lighter text-dark-grey">
          Although Pigijo will use its best efforts to ensure its security,
          Pigijo cannot guarantee the strength or effectiveness of this
          encryption and Pigijo does not and will not be responsible for any
          problems that may occur as a result of unauthorized access to the
          information you provide.
        </p>
        <p className="fw-lighter text-dark-grey">
          When you make an order on the Pigijo website or application, we will
          record and store your personal information and data. We will store any
          data that you provide, from time to time, or that we collect from
          using our products and services. Your personal data that is with us,
          can be used by us for our internal purposes to help us in the future
          in providing services to you. In this connection, we may disclose your
          data to the companies in which Pigijo is incorporated, Product
          provider partners, other companies that are partners of Pigijo, travel
          agencies, government agencies and judicial bodies in any jurisdiction.
        </p>
        <h6 className="mt-4">Rights and Obligations</h6>
        <p className="fw-lighter text-dark-grey">
          By using or accessing this Website, it means that you have agreed and
          are required to always pay attention to the prohibited provisions that
          apply to the use of this Website, which are as follows:
        </p>
        <p className="fw-lighter text-dark-grey">
          <ol>
            <li>
              It is only permitted to access and / or use this Website for
              personal purposes, which means that this Website may only be
              accessed and used directly by individuals or businesses who are
              looking for products or services for that individual or business.
              Access and use of the Website outside of personal needs is
              strictly prohibited, and violates these Terms and Conditions.
            </li>
            <li>
              The content available on the Website, including information, data,
              text, images, sound, graphics, video, messages, reviews, or other
              material, including the software infrastructure used to provide
              the Content, is wholly owned by Pigijo or its suppliers. and its
              service providers, including but not limited to partners (as
              applicable). You can use the Website to place an order and you
              hereby guarantee that you will not send a false reservation
              request. You hereby declare to ensure that the payment information
              you provide is accurate You also guarantee to provide accurate
              email and mailing addresses and other details of your contact
              information.
            </li>
            <li>
              Agree not to use the Website or Content for commercial purposes or
              either directly or indirectly other than personal use or for
              purposes that violate the law (prohibited by law) or take any
              action that violates these Terms. Except with written consent from
              Pigijo, you agree not to modify, distribute, transmit, display,
              perform, reproduce, publish, create derivative creations from,
              transfer, copy, license, sell or resell any information, software,
              products or services obtained in connection with the Website.
            </li>
            <li>In using the Service, you are not allowed to:</li>
            <ol type="a">
              <li>Submit or enter any information;</li>
              <li>
                Send or enter any destructive computer program, etc., or enter
                any spam e-mails, chain letters, junk mail, etc;
              </li>
              <li>
                Spread slander or damage the reputation of the Company, Products
                or related third parties;
              </li>
              <li>
                Reveal to others all information, documents, diagrams, data,
                etc., which includes any content that is contrary to morals and
                public order;
              </li>
              <li>
                Registering, whether intentionally or unintentionally, all
                errors or damaging information regarding the name, or personal
                address, telephone number or email address, etc., of any person
                who uses the Service or you;
              </li>
              <li>
                Perform any action that imposes or may impose an unreasonable
                burden on the Website or Website infrastructure;
              </li>
              <li>
                Creating a main link to the Website (including but not limited
                to the order line) for any purpose without Pigijo's written
                consent;
              </li>
              <li>
                Resell, use, copy, control (such as using or installing spider
                or scrape programs), displaying, downloading, or producing any
                Content, software, products, services available through the
                Website for commercial or targeted purposes / competition
                activity;
              </li>
              <li>
                Send or store any material to or through the Website that
                violates laws, regulations, or rules, or that could support
                illegal or criminal activity;
              </li>
              <li>
                Send or store any material that may violate the intellectual
                property or other rights of a particular entity or person,
                including but not limited to copyrights, patents, trademarks,
                trade secrets, confidential information or publications;
              </li>
              <li>
                Send or store material that is prohibited by applicable law or
                violates existing rights and obligations based on contractual
                relationships;
              </li>
              <li>
                Send or store any material which contains software viruses,
                impersonating any person or entity for any purpose;
              </li>
              <li>
                Manipulating or falsifying information with the aim of
                disguising the origin of the statement given;
              </li>
              <li>
                Using the Site in any way that may damage, disable, block, or
                interfere with the use of the Website or users of other computer
                equipment, or cause damage, disruption, or limit the
                functionality of software, hardware, or telecommunications
                equipment;
              </li>
              <li>
                Gaining unauthorized access or making unauthorized modifications
                to the Website or other linked websites, other accounts,
                computer systems, networks connected to the Site through
                hacking, password theft or other similar things;
              </li>
              <li>
                Obtaining or trying to obtain material or information in a way
                that is not intentionally provided by the Website (including but
                not limited to other purposes provided by the Website). This
                includes but is not limited to, obtaining or collecting
                information about other people such as email address;
              </li>
              <li>
                Modify or create derivative works based on the Website or
                reverse engineer or access the underlying software for any
                reason; or
              </li>
              <li>
                Using the Website to build a competitive product or service.
              </li>
            </ol>
          </ol>
        </p>
        <p className="fw-lighter text-dark-grey">By placing an order through the Website, you hereby represent and warrant that you are not subject to any restrictions or limitations by any of the sanctions program, or be subject to any penalties.</p>
        <p className="fw-lighter text-dark-grey">If Pigijo considers that you violate the things that must be followed in the previous point, or Pigijo otherwise reasonably determines any actions from you that are not appropriate, Pigijo can verify the details of these actions with you.</p>
        <h6 className="mt-4">Rights and Obligations</h6>
        <p className="fw-lighter text-dark-grey">You hereby acknowledge and agree to the extent permitted by applicable law:</p>
        <p className="fw-lighter text-dark-grey">
          <ol type="a">
            <li>The services and content of this Website are provided on an "as is "and "as available" basis. We hereby expressly state that we do not make any warranties or warranties, either express or implied, with respect to eligibility. business of the product or service, or the suitability of the products and services we provide for a specific purpose and oppose the abuse of the rights where applicable;</li>
            <li>We do not guarantee that:</li>
            <ol type="i">
              <li>the functions, services and security features provided on this Website will be uninterrupted or error free;</li>
              <li>errors or failures will be corrected; or</li>
              <li>the Website or server provides services. which is free of viruses, malware or harmful components;</li>
            </ol>
            <li>We do not guarantee the accuracy, authenticity, integrity or quality of the content, the Website or the resources available for and from the Website including, but not limited to, any warranty that the content, Website or resources are free from malicious material, is not obscene or controversial;</li>
            <li>Any downloaded material or otherwise obtained through the use of this service is at your own responsibility and risk.</li>
          </ol>
        </p>
      </Container>
    </DesktopWrapper>
  );
}
export default TermsAndConditionsPage;
