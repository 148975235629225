import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Carousel,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import id from "date-fns/locale/id";
import HeroImage1 from "../../..//assets/images/landingPage/hero.png";
import HeroImage2 from "../../..//assets/images/landingPage/hero2.png";
import HeroImage3 from "../../..//assets/images/landingPage/hero3.png";
import LocalExperienceIcon from "../../..//assets/icons/product/localExperience.svg";
import TravelAssistenceIcon from "../../..//assets/icons/product/travelAssistance.svg";
import FlightIcon from "../../..//assets/icons/product/flight.svg";
import TrainIcon from "../../..//assets/icons/product/train.svg";
import AccomodationIcon from "../../..//assets/icons/product/accomodation.svg";
import PigiShopIcon from "../../..//assets/icons/product/pigiShop.svg";
import SearchIcon from "../../..//assets/icons/search.svg";
import DepartureIcon from "../../..//assets/icons/departure.svg";
import ArrivalIcon from "../../..//assets/icons/arrival.svg";
import CalenderInIcon from "../../..//assets/icons/calenderIn.svg";
import CalenderOutIcon from "../../..//assets/icons/calenderOut.svg";
import CityIcon from "../../..//assets/icons/city.svg";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import UserContext from "../../../services/user";
import { toast } from "react-toastify";
import moment from "moment";
import { useTranslation } from "react-i18next";
registerLocale("id", id);

function HeroSection(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getAllMaster } = useContext(UserContext);
  const [departuredSelected, setDeparturedSelected] = useState(null);
  const [destinationSelected, setDestinationSelected] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [cabinClass, setCabinClass] = useState("economy");
  const [search, setSearch] = useState("");
  const [returnValue, setReturnValue] = useState(false);
  const [qtyAdult, setQtyAdult] = useState(1);
  const [qtyChild, setQtyChild] = useState(0);
  const [qtyInfant, setQtyInfant] = useState(0);
  const [passanger, setPassanger] = useState(1);
  const [text, setText] = useState("");
  const [heroImage, setHeroImage] = useState([
    {
      id: 1,
      image: HeroImage1,
    },
    {
      id: 2,
      image: HeroImage2,
    },
    {
      id: 3,
      image: HeroImage3,
    },
  ]);
  const [active, setActive] = useState(1);
  const [modal, setModal] = useState({
    open: false,
    type: "",
  });
  const [city, setCity] = useState([]);
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const handleClose = () => {
    setSearch("");
    setModal({ open: false, type: "" });
  };

  const onChangeQty = (type, pessanger) => {
    if (pessanger === "adult") {
      if (type === "minus" && qtyAdult >= 2) {
        setQtyAdult(qtyAdult - 1);
      } else if (type === "plus" && qtyAdult < 10) {
        setQtyAdult(qtyAdult + 1);
      }
    } else if (pessanger === "child") {
      if (type === "minus" && qtyChild >= 1) {
        setQtyChild(qtyChild - 1);
      } else if (type === "plus" && qtyChild < 10) {
        setQtyChild(qtyChild + 1);
      }
    } else if (pessanger === "infant") {
      if (type === "minus" && qtyInfant >= 1) {
        setQtyInfant(qtyInfant - 1);
      } else if (type === "plus" && qtyInfant < 10) {
        setQtyInfant(qtyInfant + 1);
      }
    }
  };

  const searchAction = (evt) => {
    if (evt?.key === "Enter") {
      navigate(
        `/${active === 1 ? "local-experience" : "travel-assistance"}?search=${
          evt?.target?.value
        }`
      );
    } else {
      setSearch(evt?.target?.value);
    }
  };

  const getDataAirPort = async () => {
    const newData = await getAllMaster(`airport`, { search: search });
    if (newData) {
      setCity(newData?.data);
    }
  };

  const getDataAirPortSearch = async (src, type) => {
    const newData = await getAllMaster(`airport`, { search: src });
    if (newData) {
      let dataSelect = newData?.data?.length > 0 && newData?.data?.[0];
      if (dataSelect) {
        if (type === "departure") {
          setDeparturedSelected(dataSelect);
        } else {
          setDestinationSelected(dataSelect);
        }
      }
    }
  };

  useEffect(() => {
    if (props?.active) {
      setActive(props?.active);
      if (props?.active === 1 || props?.active === 2) {
        setText(searchParams.get("search"));
      } else if (props?.active === 3) {
        getDataAirPortSearch(searchParams.get("departure_city"), "departure");
        getDataAirPortSearch(
          searchParams.get("destination_city"),
          "destination"
        );
        setStartDate(moment(searchParams.get("startDate")).toDate());
        searchParams.get("endDate") &&
          setEndDate(moment(searchParams.get("endDate")).toDate());
        setCabinClass(searchParams.get("class"));
        setQtyAdult(parseInt(searchParams.get("adult")));
        setQtyChild(parseInt(searchParams.get("child")));
        setQtyInfant(parseInt(searchParams.get("infant")));
        setPassanger(
          parseInt(searchParams.get("adult")) +
            parseInt(searchParams.get("child")) +
            parseInt(searchParams.get("infant"))
        );
      }
    }
  }, [props?.active]);

  useEffect(() => {
    if (modal.type === "departure" || modal.type === "destination") {
      getDataAirPort();
    }
  }, [modal, search]);

  return (
    <div
      className="pb-xl text-center bg-image position-relative hero-mobile"
      style={{ maxHeight: "850px" }}
    >
      <Carousel indicators={false} controls={false} pause={false}>
        {heroImage &&
          heroImage.map((item, index) => (
            <Carousel.Item key={index}>
              <Image
                src={item.image}
                style={{ maxHeight: "800px" }}
                width="100%"
              />
            </Carousel.Item>
          ))}
      </Carousel>
      <Container
        className={
          "d-flex w-100 align-self-end position-absolute top-60 start-50 translate-middle pb-5 home-mobile " +
          (active === 3 && "large-bottom")
        }
      >
        <Card
          className="w-100 text-dark mt-auto rounded-4 border-white"
          style={{ minHeight: "300px" }}
        >
          <Card.Body className="px-4">
            <Row className="justify-content-between align-items-center px-2 py-3">
              <Col lg="12" xl="4" className="text-start">
                <div className="pb-3" style={{ fontSize: "18px" }}>
                  Hello, Kawanjo!
                </div>
                <div className="h4 fw-bold">
                  Let’s Discover a New Adventure!
                </div>
              </Col>
              <Col lg="12" xl="8" style={{ fontSize: "14px" }}>
                <Row className="align-items-start">
                  <Col xs="6" sm="4" md="4" lg="2" xl="2" className="px-0 mb-3">
                    <div
                      className={`text-dark text-decoration-none product-icon ${
                        active === 1 && "active"
                      }`}
                      onClick={() => setActive(1)}
                    >
                      <div className="d-inline-block pb-1">
                        <div className="border border-1 rounded-circle p-3 w-100 h-100">
                          <Image src={LocalExperienceIcon} />
                        </div>
                      </div>
                      <div>Local Experience</div>
                    </div>
                  </Col>
                  <Col xs="6" sm="4" md="4" lg="2" xl="2" className="px-0 mb-3">
                    <div
                      className={`text-dark text-decoration-none product-icon ${
                        active === 2 && "active"
                      }`}
                      onClick={() => setActive(2)}
                    >
                      <div className="d-inline-block pb-1">
                        <div className="border border-1 rounded-circle p-3 w-100 h-100">
                          <Image src={TravelAssistenceIcon} />
                        </div>
                      </div>
                      <div>Travel Assistance</div>
                    </div>
                  </Col>
                  <Col xs="6" sm="4" md="4" lg="2" xl="2" className="px-0 mb-3">
                    <div
                      className={`text-dark text-decoration-none product-icon ${
                        active === 3 && "active"
                      }`}
                      onClick={() => setActive(3)}
                    >
                      <div className="d-inline-block pb-1">
                        <div className="border border-1 rounded-circle p-3 w-100 h-100">
                          <Image src={FlightIcon} />
                        </div>
                      </div>
                      <div>Flight</div>
                    </div>
                  </Col>
                  {/* <Col xs="6" sm="4" md="4" lg="2" xl="2" className="px-0 mb-3">
                    <div
                      className={`text-dark text-decoration-none product-icon ${
                        active === 4 && "active"
                      }`}
                      // onClick={() => setActive(4)}
                    >
                      <div className="d-inline-block pb-1">
                        <div className="border border-1 rounded-circle p-3 w-100 h-100">
                          <Image src={TrainIcon} />
                        </div>
                      </div>
                      <div>Train</div>
                    </div>
                  </Col>
                  <Col xs="6" sm="4" md="4" lg="2" xl="2" className="px-0 mb-3">
                    <div
                      className={`text-dark text-decoration-none product-icon ${
                        active === 5 && "active"
                      }`}
                      // onClick={() => setActive(5)}
                    >
                      <div className="d-inline-block pb-1">
                        <div className="border border-1 rounded-circle p-3 w-100 h-100">
                          <Image src={AccomodationIcon} />
                        </div>
                      </div>
                      <div>Hotel</div>
                    </div>
                  </Col>
                  <Col xs="6" sm="4" md="4" lg="2" xl="2" className="px-0 mb-3">
                    <a
                      href="https://shop.pigijo.com"
                      target="_blank"
                      rel="noreferrer"
                      className="text-dark text-decoration-none product-icon"
                    >
                      <div className="d-inline-block pb-1">
                        <div className="border border-1 rounded-circle p-3 w-100 h-100">
                          <Image src={PigiShopIcon} />
                        </div>
                      </div>
                      <div>PigiShop</div>
                    </a>
                  </Col> */}
                </Row>
              </Col>
              <Col lg="12" xl="23">
                {active === 1 && (
                  <InputGroup className="mt-5 search-product">
                    <InputGroup.Text className="bg-light border-end-0 py-3">
                      <Image src={SearchIcon} />
                    </InputGroup.Text>
                    <Form.Control
                      className="border-start-0 fw-light"
                      placeholder="Search Local Experience"
                      onKeyUp={searchAction}
                      defaultValue={text}
                    />
                  </InputGroup>
                )}
                {active === 2 && (
                  <InputGroup className="mt-5 search-product">
                    <InputGroup.Text className="bg-light border-end-0 py-3">
                      <Image src={SearchIcon} />
                    </InputGroup.Text>
                    <Form.Control
                      className="border-start-0 fw-light"
                      placeholder="Search Travel Assistance"
                      onKeyUp={searchAction}
                      defaultValue={text}
                    />
                  </InputGroup>
                )}
                {active === 3 && (
                  <div className="text-end">
                    <div className="d-flex mt-5 search-flight">
                      <fieldset
                        className="w-100"
                        onClick={() =>
                          setModal({
                            type: "departure",
                            open: true,
                          })
                        }
                      >
                        <legend>From</legend>
                        <div className="d-flex align-items-center">
                          <div className="bg-light border-0 pe-2">
                            <Image src={DepartureIcon} />
                          </div>
                          <div className="border-0 fw-light text-center">
                            {departuredSelected ? (
                              <div className="fw-normal">
                                {departuredSelected?.name +
                                  " (" +
                                  departuredSelected?.code +
                                  ")"}
                              </div>
                            ) : (
                              "Departure"
                            )}
                          </div>
                        </div>
                      </fieldset>
                      <fieldset
                        className="w-100"
                        onClick={() =>
                          setModal({
                            type: "destination",
                            open: true,
                          })
                        }
                      >
                        <legend>To</legend>
                        <div className="d-flex align-items-center">
                          <div className="bg-light border-0 pe-2">
                            <Image src={ArrivalIcon} />
                          </div>
                          <div className="border-0 fw-light text-center">
                            {destinationSelected ? (
                              <div className="fw-normal">
                                {destinationSelected?.name +
                                  " (" +
                                  destinationSelected?.code +
                                  ")"}
                              </div>
                            ) : (
                              t("destination")
                            )}
                          </div>
                        </div>
                      </fieldset>
                      <fieldset className="w-100">
                        <legend>Departure</legend>
                        <div
                          className="d-flex align-items-center"
                          onClick={() =>
                            setModal({
                              type: "startDate",
                              open: true,
                            })
                          }
                        >
                          <div className="bg-light border-0 pe-2">
                            <Image src={CalenderInIcon} />
                          </div>
                          <div className="border-0 fw-light text-center">
                            {startDate ? (
                              <div className="fw-normal">
                                {moment(startDate).format("DD MMM YYYY")}
                              </div>
                            ) : (
                              t("choose_date")
                            )}
                          </div>
                        </div>
                      </fieldset>
                      <fieldset
                        className={(returnValue ? "" : "disabled") + " w-100"}
                      >
                        <legend className="d-flex">
                          <div className="me-2">Return</div>
                          <Form.Check
                            type="switch"
                            className="custom-switch"
                            onChange={(val) => {
                              setReturnValue(val.target.checked);
                              if (!val.target.checked) {
                                setEndDate("");
                              }
                            }}
                          />
                        </legend>
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            if (returnValue) {
                              setModal({
                                type: "endDate",
                                open: true,
                              });
                            }
                          }}
                        >
                          <div className="bg-light border-0 pe-2">
                            <Image src={CalenderOutIcon} />
                          </div>
                          <div className="border-0 fw-light text-center">
                            {endDate ? (
                              <div className="fw-normal">
                                {moment(endDate).format("DD MMM YYYY")}
                              </div>
                            ) : (
                              t("choose_date")
                            )}
                          </div>
                        </div>
                      </fieldset>
                      <fieldset
                        style={{ minWidth: "260px" }}
                        onClick={() => {
                          setModal({
                            type: "passanger",
                            open: true,
                          });
                        }}
                      >
                        <legend>Passenger & Cabin Class</legend>
                        <div className="text-center">
                          {passanger} Passenger,{" "}
                          {cabinClass
                            .replace(/-/g, " ")
                            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                              letter.toUpperCase()
                            )}
                        </div>
                      </fieldset>
                    </div>
                    <Button
                      type="primary"
                      className="text-white mt-2"
                      onClick={() => {
                        if (
                          departuredSelected &&
                          destinationSelected &&
                          startDate
                        ) {
                          navigate(
                            `/flights?departure=${
                              departuredSelected?.code
                            }&destination=${
                              destinationSelected?.code
                            }&startDate=${moment(startDate).format(
                              "YYYY-MM-DD"
                            )}&endDate=${
                              endDate && moment(endDate).format("YYYY-MM-DD")
                            }&adult=${qtyAdult}&child=${qtyChild}&infant=${qtyInfant}&class=${cabinClass}&departure_city=${
                              departuredSelected?.name
                            }&destination_city=${destinationSelected?.name}`
                          );
                        }
                      }}
                    >
                      {t("search")}
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Modal
          show={modal.open && modal.type === "departure"}
          onHide={handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Departure</Modal.Title>
          </Modal.Header>
          <Modal.Body className="city-modal">
            <InputGroup className="search-product">
              <InputGroup.Text className="bg-light border-end-0 py-3">
                <Image src={SearchIcon} />
              </InputGroup.Text>
              <Form.Control
                className="border-start-0 fw-light"
                placeholder={t("search")}
                onKeyUp={(key) => setSearch(key?.target?.value)}
              />
            </InputGroup>
            <div className="list-group">
              {city &&
                city.map((item, index) => (
                  <div
                    className="list px-3"
                    key={index}
                    onClick={() => {
                      if (
                        destinationSelected &&
                        item._id === destinationSelected._id
                      ) {
                        toast.error(
                          "Departure cannot be the same as destination"
                        );
                      } else {
                        setDeparturedSelected(item);
                        handleClose();
                      }
                    }}
                  >
                    <div className="d-flex my-3 w-100">
                      <Image src={CityIcon} className="pe-4" />
                      <div className="city-detail">
                        <div className="city-name">
                          {item.name + " (" + item.code + ")"}
                        </div>
                        <div className="city-desc">{item.location}</div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={modal.open && modal.type === "destination"}
          onHide={handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("destination")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="city-modal">
            <InputGroup className="search-product">
              <InputGroup.Text className="bg-light border-end-0 py-3">
                <Image src={SearchIcon} />
              </InputGroup.Text>
              <Form.Control
                className="border-start-0 fw-light"
                placeholder={t("search")}
                onKeyUp={(key) => setSearch(key?.target?.value)}
              />
            </InputGroup>
            <div className="list-group">
              {city &&
                city.map((item, index) => (
                  <div
                    className="list px-3"
                    key={index}
                    onClick={() => {
                      if (
                        departuredSelected &&
                        item._id === departuredSelected._id
                      ) {
                        toast.error(
                          "Destination cannot be the same as departure"
                        );
                      } else {
                        setDestinationSelected(item);
                        handleClose();
                      }
                    }}
                  >
                    <div className="d-flex my-3 w-100">
                      <Image src={CityIcon} className="pe-4" />
                      <div className="city-detail">
                        <div className="city-name">
                          {item.name + " (" + item.code + ")"}
                        </div>
                        <div className="city-desc">{item.location}</div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={modal.open && modal.type === "startDate"}
          onHide={handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("choose_date")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="date-modal">
            <div>
              <DatePicker
                locale="id"
                inline
                onChange={(date) => {
                  if (moment(date).diff(moment(endDate), "days") > 0) {
                    toast.error("Start date must be before end date");
                  } else {
                    setStartDate(date);
                    handleClose();
                  }
                }}
                selected={startDate}
                filterDate={(date) => {
                  return moment() < date;
                }}
                className="inline-calender"
              />
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={modal.open && modal.type === "endDate"}
          onHide={handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("choose_date")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="date-modal">
            <div>
              <DatePicker
                locale="id"
                inline
                onChange={(date) => {
                  if (moment(startDate).diff(moment(date), "days") > 0) {
                    toast.error("End date must be after start date");
                  } else {
                    setEndDate(date);
                    handleClose();
                  }
                }}
                selected={endDate}
                filterDate={(date) => {
                  return startDate ? moment(startDate) < date : moment() < date;
                }}
                className="inline-calender"
              />
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={modal.open && modal.type === "passanger"}
          onHide={handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Passanger and Cabin Class</Modal.Title>
          </Modal.Header>
          <Modal.Body className="date-modal">
            <Row>
              <Col lg="6" xl="6" className="first-vertical">
                <div className="h5">Passenger</div>
                <div className="d-flex align-items-center justify-content-between passanger">
                  <div className="w-100">
                    <div>{t("adult")}</div>
                    <div className="text-grey fw-lighter small pt-1">
                      Age 12+
                    </div>
                  </div>
                  <div className="button-passanger">
                    <Button onClick={() => onChangeQty("minus", "adult")}>
                      -
                    </Button>
                    <div className="value-passanger">{qtyAdult}</div>
                    <Button onClick={() => onChangeQty("plus", "adult")}>
                      +
                    </Button>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between passanger">
                  <div className="w-100">
                    <div>Children</div>
                    <div className="text-grey fw-lighter small pt-1">
                      Age 2-11
                    </div>
                  </div>
                  <div className="button-passanger">
                    <Button onClick={() => onChangeQty("minus", "child")}>
                      -
                    </Button>
                    <div className="value-passanger">{qtyChild}</div>
                    <Button onClick={() => onChangeQty("plus", "child")}>
                      +
                    </Button>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between passanger">
                  <div className="w-100">
                    <div>Infant</div>
                    <div className="text-grey fw-lighter small pt-1">
                      Under 2
                    </div>
                  </div>
                  <div className="button-passanger">
                    <Button onClick={() => onChangeQty("minus", "infant")}>
                      -
                    </Button>
                    <div className="value-passanger">{qtyInfant}</div>
                    <Button onClick={() => onChangeQty("plus", "infant")}>
                      +
                    </Button>
                  </div>
                </div>
              </Col>
              <Col lg="6" xl="6">
                <div className="h5">Cabin Class</div>
                <label className="radio-cabin" for="economy" role="button">
                  <div
                    className={
                      (cabinClass === "economy" ? "fw-normal text-black" : "") +
                      " form-check-label"
                    }
                  >
                    Economy
                  </div>
                  <input
                    name="cabinClass"
                    type="radio"
                    id="economy"
                    className="form-check-input"
                    value="economy"
                    checked={cabinClass === "economy"}
                    onClick={() => setCabinClass("economy")}
                  />
                </label>
                <label className="radio-cabin" for="business" role="button">
                  <div
                    className={
                      (cabinClass === "business"
                        ? "fw-normal text-black"
                        : "") + " form-check-label"
                    }
                  >
                    Business
                  </div>
                  <input
                    name="cabinClass"
                    type="radio"
                    id="business"
                    className="form-check-input"
                    value="business"
                    checked={cabinClass === "business"}
                    onClick={() => setCabinClass("business")}
                  />
                </label>
                <label className="radio-cabin" for="firstclass" role="button">
                  <div
                    className={
                      (cabinClass === "firstclass"
                        ? "fw-normal text-black"
                        : "") + " form-check-label"
                    }
                  >
                    First Class
                  </div>
                  <input
                    name="cabinClass"
                    type="radio"
                    id="firstclass"
                    className="form-check-input"
                    value="firstclass"
                    checked={cabinClass === "firstclass"}
                    onClick={() => setCabinClass("firstclass")}
                  />
                </label>
              </Col>
              <Col lg="12" xl="12" className="mt-2">
                <Button
                  type="primary"
                  className="text-white w-100 ml-auto"
                  onClick={() => {
                    setPassanger(qtyAdult + qtyChild + qtyInfant);
                    handleClose();
                  }}
                >
                  {t("save")}
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
}

export default HeroSection;
