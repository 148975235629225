import React, { useContext, useEffect, useState } from "react";
import { Badge, Card, Col, Image, Row } from "react-bootstrap";
import LocalExperienceIcon from "../../../../assets/icons/localExperience.svg";
import LeftArrowIcon from "../../../../assets/icons/leftArrow.svg";
import WhatsappIcon from "../../../../assets/icons/whatsapp.svg";
import EmailIcon from "../../../../assets/icons/email.svg";
import CautionIcon from "../../../../assets/icons/caution.svg";
import { Link, useParams } from "react-router-dom";
import UserContext from "../../../../services/user";
import moment from "moment";
import { useTranslation } from "react-i18next";

function OrderDetail(params) {
  const { t } = useTranslation();
  const { getMaster, loading } = useContext(UserContext);
  const [data, setData] = useState({});
  const { id } = useParams();

  const getData = async () => {
    const newData = await getMaster(`order`, id);
    if (newData) {
      setData(newData.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Link to="/order" className="text-primary text-decoration-none">
        <Image src={LeftArrowIcon} />
        <span className="ps-2">{t("back_to_active_order")}</span>
      </Link>
      <Card className="mt-4 border-0 shadow-sm">
        <Card.Body>
          <div className="d-flex align-items-center mb-3">
            <Image src={LocalExperienceIcon} />
            <span className="small ps-2 fw-light">
              {data.type || "Local Experience"}
            </span>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div className="fs-18 fw-bold">
              {data.items?.length > 0 && data.items[0]?.name}
            </div>
            <div className="text-grey fw-light mb-2">
              {t("booking_id")}: {data.invoiceNumber}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div>{t("date_of_departure")}</div>
            <div className="text-primary">
              {moment(data?.departureDate).format("LL")}
            </div>
          </div>
          <hr></hr>
          <div className="d-flex align-items-center justify-content-between">
            <div>{t("total_payment")}</div>
            <div className="text-primary">
              {new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
              }).format(data?.grossAmount)}
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="mt-4 border-0 shadow-sm">
        <Card.Body className="d-flex align-items-center justify-content-between">
          <div className="fs-18 mb-0">{t("trip_details")}</div>
          <Link
            to={`/local-experience/detail/${
              data.items?.length > 0 && data.items[0]?.id
            }`}
            className="text-primary text-decoration-none"
          >
            <span className="pe-2">{t("see_full_details")}</span>
            <Image src={LeftArrowIcon} style={{ transform: "scaleX(-1)" }} />
          </Link>
        </Card.Body>
      </Card>
      <Card className="mt-4 border-0 shadow-sm">
        <Card.Body>
          <div className="fs-18 mb-4">{t("participant_details")}</div>
          {data?.customers &&
            data?.customers?.map(
              (item, index) =>
                item?.firstName && (
                  <div className="d-flex mb-4" key={index}>
                    <div className="fs-18 fw-light" style={{ width: "20px" }}>
                      {index + 1}.
                    </div>
                    <div className="fw-light">
                      <div className="d-flex align-items-center fw-light pb-2">
                        <div className="fs-18 mb-0 pe-3">
                          {item?.salutation +
                            " " +
                            item?.firstName +
                            " " +
                            item?.lastName}
                        </div>
                      </div>
                      <div className="d-flex pb-2 fs-12 text-muted">
                        <div style={{ minWidth: "150px" }}>
                          {t("phone_number")}
                        </div>
                        <div>: {item?.phone}</div>
                      </div>
                      <div className="d-flex fs-12 text-muted">
                        <div style={{ minWidth: "150px" }}>
                          {t("email_address")}
                        </div>
                        <div>: {item?.email}</div>
                      </div>
                    </div>
                  </div>
                )
            )}
        </Card.Body>
      </Card>
      <Card className="mt-4 border-0 shadow-sm">
        <Card.Body>
          <div className="fs-18 mb-4">{t("order_summary")}</div>
          <hr></hr>
          <div className="d-flex align-items-center justify-content-between text-muted small fw-light mb-3 mt-4 fs-12">
            <div>
              {data.items?.length > 0 && data.items[0]?.quantity} {t("guest")}
            </div>
            <div>
              {" "}
              {new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
              }).format(data?.grossAmount)}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between text-muted small fw-light mb-3 fs-12">
            <div>{t("admin_fee")}</div>
            <div>Rp0</div>
          </div>
          <div className="d-flex align-items-center justify-content-between text-muted small fw-light mb-4 fs-12">
            <div>{t("total_payment")}</div>
            <div>
              {" "}
              {new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
              }).format(data?.grossAmount)}
            </div>
          </div>
          <hr></hr>
          <div className="mb-4 mt-4">{t("payment_details")}</div>
          <div className="d-flex align-items-center justify-content-between text-muted small fw-light mb-3 fs-12">
            <div>{t("payment_method")}</div>
            {data.paymentMethod === "bank_transfer" && (
              <div>
                {data.paymentMethod?.toUpperCase() +
                  " " +
                  data.va?.bank?.toUpperCase()}
              </div>
            )}
            {data.paymentMethod === "credit_card" && (
              <div>{data.paymentMethod?.toUpperCase()}</div>
            )}
            {(data.paymentMethod === "gopay" ||
              data.paymentMethod === "shopeepay") && (
              <div>{data.paymentMethod?.toUpperCase()}</div>
            )}
            {data.paymentMethod === "echannel" && (
              <div>
                {data.paymentMethod?.toUpperCase() +
                  " " +
                  data.bill?.bank?.toUpperCase()}
              </div>
            )}
          </div>
          <div className="d-flex align-items-center justify-content-between text-muted small fw-light mb-3 fs-12">
            <div>{t("va_number")}</div>
            {data.paymentMethod === "bank_transfer" && (
              <div>{data.va?.vaNumber}</div>
            )}
            {data.paymentMethod === "credit_card" && <div>-</div>}
            {(data.paymentMethod === "gopay" ||
              data.paymentMethod === "shopeepay") && (
              <a
                target="_blank"
                rel="noreferrer"
                className="text-primary"
                href={
                  data?.eWalletActions?.length > 0 &&
                  data?.eWalletActions[1]?.url
                }
              >
                Pay Here
              </a>
            )}
            {data.paymentMethod === "echannel" && (
              <div>{data.bill?.billerCode + " " + data.bill?.billKey}</div>
            )}
          </div>
          <div className="d-flex align-items-center justify-content-between text-muted small fw-light mb-3 fs-12">
            <div>{t("date_transaction")}</div>
            <div>{moment(data.createtAt).format("LLL")}</div>
          </div>
        </Card.Body>
      </Card>
      <Card className="mt-4 border-0 shadow-sm">
        <Card.Body>
          <div className="fs-18 mb-4">{t("reschedule_policy")}</div>
          <hr></hr>
          <div className="text-muted fw-light my-4 fs-12">
            Rescheduling can only be done 1 (one) time and it needs to be
            confirmed in advance according to the availability of Travel
            Assistance. Please contact Customer Service for rescheduling.
          </div>
          <Row className="fs-12">
            <Col sm="12" md="6" lg="6" xl="6">
              <div className="d-flex align-items-start">
                <Image src={WhatsappIcon} />
                <div className="ps-2">
                  <div className="mb-2">(+62) 082246598802</div>
                  <div className="d-flex text-muted fw-light mb-1">
                    <div style={{ minWidth: "200px" }}>Monday - Friday</div>
                    <div>: 09.00 - 17.00</div>
                  </div>
                  <div className="d-flex text-muted fw-light">
                    <div style={{ minWidth: "200px" }}>
                      Saturday, Monday, Holiday
                    </div>
                    <div>: 11.00 - 15.00</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm="12" md="6" lg="6" xl="6">
              <div className="d-flex align-items-start">
                <Image src={EmailIcon} />
                <div className="ps-2">
                  <div className="mb-2">cs@pigijo.com / info@pigijo.com</div>
                  <div className="d-flex text-muted fw-light mb-1">
                    Maximum Response 2 x 24 H
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-4 border-0 shadow-sm">
        <Card.Body className="d-flex align-items-center">
          <Image src={CautionIcon} />
          <div className="ps-4">
            <div className="fs-18 mb-2">Be a Smart Traveler!</div>
            <div className="fw-light fs-14 mb-1">
              Pigijo is not responsible for things / communications that occur
              outside of orders.
            </div>
            <div className="fw-light fs-14">
              If you need assistance, please contact Pigijo Customer Service.
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default OrderDetail;
