import { Link } from "react-router-dom";
import { Button, Container, Image } from "react-bootstrap";
import { SkeletonCustom } from "../../../../component/global/skeleton";
import RightArrow from "../../../../assets/icons/rightArrow.svg";
import ItemGroup from "../../../../component/desktop/card/itemGroup";
import { useTranslation } from "react-i18next";

function Section1B(props) {
  const { t } = useTranslation();
  return (
    <div className="section1b mb-5">
      {props?.loading ? (
        <SkeletonCustom height="300px" />
      ) : (
        <div className="bg">
          <Container className="mb-5 pt-4">
            <div className="d-flex align-items-center justify-content-between fs-5 mt-3 pb-4 text-white">
              <div className="fs-4 fw-bold w-75" style={{ width: "60%" }}>
                {t("recommended_this_week")}
              </div>
              <Link
                className="text-white text-decoration-none see-all-1"
                to="/local-experience?Recommended%20This%20Week"
              >
                {t("see_all")} <Image src={RightArrow} />
              </Link>
            </div>
            <ItemGroup data={props.data} />
            <Button
              as={Link}
              to="/local-experience?Recommended%20This%20Week"
              className="see-all-2 color w-100 text-center text-info"
              variant="light-info"
            >
              View all Local Experience
            </Button>
          </Container>
        </div>
      )}
    </div>
  );
}

export default Section1B;
