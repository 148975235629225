import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import LeftTopIcon from "../../assets/auth/left-top.png";
import RightBottomIcon from "../../assets/auth/right-bottom.png";
import PigijoImage from "../../assets/auth/pigijo.png";
// import PigijoImage from "../../assets/auth/pigijo2.png";
import Halo1 from "../../assets/auth/halo.png";
import Halo2 from "../../assets/auth/halo-2.png";

function AuthWrapper(props) {
  return (
    <div className="container-fluid auth-wrapper">
      <Row className="no-gutter">
        <Col
          md="6"
          className={`d-none d-md-flex bg-image ${
            props?.type === "register" ? "bg-2" : "bg-1"
          }`}
        >
          <h4 className="text-primary">
            {props?.type === "register"
              ? "Welcome back, Kawanjo!"
              : "Start your Adventure, Kawanjo!"}
          </h4>
          <Image
            src={props?.type === "register" ? Halo2 : Halo1}
            className={`bottom ${
              props?.type === "register" ? "type2" : "type1"
            }`}
          />
        </Col>

        <Col className="bg-light position-relative">
          <Image src={LeftTopIcon} className="bg-img top" />
          <Image src={RightBottomIcon} className="bg-img bottom" />
          <div className="login d-flex align-items-center py-5">
            <Container>
              <div className="mx-auto text-center mb-4">
                <Link to="/">
                  <Image src={PigijoImage} className="top" />
                </Link>
              </div>
              <Row>
                <Col
                  lg="10"
                  xl="7"
                  className="mx-auto text-left text-md-center"
                >
                  {props.children}
                </Col>
              </Row>
            </Container>
          </div>
          {/* <div className="small fw-light text-center footer-text w-100">
            © 2021-2023 Pigijo is a trademark of PT Tourindo Guide Indonesia Tbk
          </div> */}
        </Col>
      </Row>
    </div>
  );
}

export default AuthWrapper;
