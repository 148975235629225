import { useContext, useState } from "react";
import { Button, Form, Image, Modal, Nav } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import UserIcon from "../../../assets/icons/user.svg";
import PigijoLogo from "../../../assets/Pigijo.svg";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ApiWithAuth from "../../../config/api/ApiWithAuth";
import AuthContext from "../../../services/auth";
import { useTranslation } from "react-i18next";

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

function ResendEmailModal(params) {
  const { t } = useTranslation();
  const { loading, resendEmail } = useContext(AuthContext);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const handleClose = () => {
    reset();
    params.setShow({
      type: "",
      open: false,
    });
  };

  const handleLoginModal = () => {
    reset();
    params.setShow({
      type: "login",
      open: true,
    });
  };

  const onSubmit = async (values) => {
    let response = await resendEmail(values.email);
    if (response) {
      setTimeout(() => {
        ApiWithAuth.setToken(response?.accessToken?.token);
        navigate("/");
        handleClose();
      }, 500);
    }
  };

  return (
    <>
      <Modal
        show={params.show?.open && params.show?.type === "resendEmail"}
        onHide={handleClose}
        centered
      >
        <Modal.Body className="p-5">
          <div className="text-center">
            <Image src={PigijoLogo} />
          </div>
          <hr></hr>
          <div className="fw-bold h5 pb-1">Resend Email Verification</div>
          <div className="text-muted fw-light small pb-4">
            Please resend email verification if you haven't or failed in the
            previous email verification
          </div>
          <div className="mb-4">
            <Form.Control
              type="email"
              {...register("email")}
              className="control-input mt-2"
              placeholder={t("email_address")}
            />
            <small className="text-danger">{errors.email?.message}</small>
          </div>
          <Button
            variant="primary"
            className="text-white w-100"
            onClick={handleSubmit(onSubmit)}
            disabled={loading}
          >
            Send
          </Button>
          <hr></hr>
          <div className="text-center fw-light mt-3">
            <div>
              Already got an account?{" "}
              <span
                className="text-primary"
                role="button"
                onClick={handleLoginModal}
              >
                {t("login")}
              </span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ResendEmailModal;
