import React from "react";
import { Button, Card, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function ItemDestination(props) {
  const { t } = useTranslation();
  return (
    <Link
      to={`/destination/${props?.data?._id}`}
      className="text-dark text-decoration-none"
    >
      <Card className="border-0 package">
        <Image
          className="card-img-top rounded"
          src={process.env.REACT_APP_FILE_URL + "/" + props?.data?.picture}
        />
        <div className="description text-white">
          <div>{t("destination")}</div>
          <div className="mt-2 font-destination">{props?.data?.caption}</div>
        </div>
        <div className="description-link">
          <Button variant="light">{t("see_destination")}</Button>
        </div>
      </Card>
    </Link>
  );
}

export default ItemDestination;
