import React, { useContext, useEffect, useState } from "react";
import { Card, Container, Image } from "react-bootstrap";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import DesktopWrapper from "../../../layout/desktop/DesktopWrapper";
import BaliImage from "../../../assets/images/destination/bali.png";
import SectionGlobal from "../../../component/desktop/card/sectionProduct";
import Section1 from "./component/section1";
import Section2 from "./component/section2";
import UserContext from "../../../services/user";

function DestinationDesktopPage() {
  const { loading, getMaster, addMaster } = useContext(UserContext);
  let { id } = useParams();
  const [data, setData] = useState([]);
  const [dataDestination, setDataDestination] = useState({});

  const upperWord = (str) => {
    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(" ");
  };

  const getDataDestination = async () => {
    const newData = await getMaster(`menu/destination/detail`, id);
    if (newData) {
      setDataDestination(newData.data);
      const newCity = newData?.data?.city?.map((item) => item.city);
      getData(newCity)
    }
  };

  const getData = async (location) => {
    const newData = await addMaster(`product/filter-products?page=1&limit=12`, {
      location: location,
    });
    if (newData) {
      setData(newData.data);
    }
  };

  useEffect(() => {
    getDataDestination();
  }, [id]);

  return (
    <DesktopWrapper>
      <Container className="py-5">
        <div className="header-destination">
          <div className="text-center py position-relative">
            <Image src={process.env.REACT_APP_FILE_URL+"/"+dataDestination?.picture} className="img-fluid img-destination" />
            <Card className="border-0 shadow rounded-4 w-95 mx-auto body-destination">
              <Card.Body className="text-start p-4">
                <h5 className="fw-bold pb-3">About {dataDestination?.caption}</h5>
                <p className="text-muted fw-light">
                {dataDestination?.description &&
                      parse(dataDestination?.description)}
                </p>
              </Card.Body>
            </Card>
          </div>
        </div>
        <SectionGlobal
          type={"Local Experience Seru di " + dataDestination?.caption}
          data={data}
        />
        {/* <Section1 /> */}
        <Section2 current={dataDestination?._id} />
      </Container>
    </DesktopWrapper>
  );
}
export default DestinationDesktopPage;
