import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import SortIcon from "../../../../assets/icons/sort.svg";
import FilterTravelAssistance from "./filter";
import ItemTravelAssistance from "../../../../component/desktop/card/itemTravelAssistance";
import TravelAssistanceImage3 from "../../../../assets/images/demo/travelAssistance3.png";
import PaginationCard from "../../../../component/desktop/card/pagination";
import UserContext from "../../../../services/user";
import { SkeletonCustom } from "../../../../component/global/skeleton";
import { useSearchParams } from "react-router-dom";

function ListTravelAssistance() {
  const [searchParams] = useSearchParams();
  const { loading, addMaster } = useContext(UserContext);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    language: [],
    location: [],
    search: searchParams.get("search")??"",
  });
  const [total, setTotal] = useState(1200);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(12);

  const getData = async () => {
    const newData = await addMaster(
      `user/travel-assistance?page=${page}&limit=${pageLimit}`,
      filter
    );
    if (newData) {
      setData(newData.data);
    }
  };
  
  useEffect(() => {
    getData();
  }, [pageLimit, page, filter]);

  return (
    <Container>
      <div className="d-flex align-items-between pb-5">
        <div className="w-50 h5 fw-bold">Recommendation Travel Assistance</div>
        <div className="w-50 text-primary text-end">
          <Image src={SortIcon} />
          <span>Sort by</span>
        </div>
      </div>
      <Row className="mb-5 align-items-start">
        <Col sm="12" md="6" lg="3" xl="3" className="mb-5">
          <FilterTravelAssistance filter={filter} setFilter={setFilter} />
        </Col>
        <Col sm="12" md="6" lg="9" xl="9">
          {loading ? (
            <SkeletonCustom height="100px" />
          ) : (
            <Row className="section3 align-items-center">
              {data &&
                data.map((item, index) => (
                  <Col
                    sm="12"
                    md="6"
                    lg="4"
                    xl="4"
                    className="mb-3"
                    key={index}
                  >
                    <ItemTravelAssistance data={item} />
                  </Col>
                ))}
              <Col sm="12" md="12" lg="12" xl="12" className="mt-5">
                <PaginationCard
                  itemsCount={total}
                  itemsPerPage={12}
                  currentPage={page}
                  setCurrentPage={setPage}
                  alwaysShown={false}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default ListTravelAssistance;
