import React from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import DesktopWrapper from "../../../layout/desktop/DesktopWrapper";
import HeroPartnersImage from "../../../assets/images/partners/hero.png";
import Partner1Image from "../../../assets/images/partners/partner1.png";
import Partner2Image from "../../../assets/images/partners/partner2.png";
import { Link } from "react-router-dom";

function PartnersDesktopPage() {
  return (
    <DesktopWrapper>
      <Container className="text-center">
        <div className="w-100 text-primary h5 mt-4 mb-3">Become a Partner</div>
        <Image src={HeroPartnersImage} className="mb-4 w-100" />
        <div className="w-100 text-primary h5 mb-3">
          Why Partner with Pigijo?
        </div>
        <ol className="partners-number text-start text-primary mb-4">
          <li className="pb-3">
            <div className="pb-2">Free</div>
            <div className="text-muted fw-light small">
              There is no fee to start a business on the Pigijo marketplace.
            </div>
          </li>
          <li className="pb-3">
            <div className="pb-2">Local and International Tourist</div>
            <div className="text-muted fw-light small">
              Pigijo is a reference source for local and foreign tourists to
              plan their trips.
            </div>
          </li>
          <li className="pb-3">
            <div className="pb-2">Smart, Economical & Precise Promotions</div>
            <div className="text-muted fw-light small">
              Pigijo will promote your products to tourists in need, with a
              light commission system.
            </div>
          </li>
          <li className="pb-3">
            <div className="pb-2">Providing Various Traveling Needs</div>
            <div className="text-muted fw-light small">
              You can become a Pigijo supplier as a provider of traveling needs
              for domestic and foreign tourists.
            </div>
          </li>
          <li className="pb-3">
            <div className="pb-2">Achieve Up to 3x Sales Increase</div>
            <div className="text-muted fw-light small">
              You will get more sales with promotion management.
            </div>
          </li>
        </ol>
        <div className="w-100 text-primary h5 mb-4">Register Now!</div>
        <Row className="mb-5 text-start">
          <Col sm="12" md="12" lg="6" xl="6">
            <Card className="border-0 shadow-sm">
              <Card.Body>
                <Image src={Partner2Image} className="w-100 pb-3" />
                <div className="pb-2">Partner Local Experience</div>
                <div className="small text-muted fw-light pb-4">
                  Join us as a partner and promote your tourist attractions
                  through our website to get a variety of attractive offers and
                  benefits.
                </div>
                <Button as={Link} to="/partners/register" variant="primary" className="w-100 text-white">
                  Register Partner
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col sm="12" md="12" lg="6" xl="6">
            <Card className="border-0 shadow-sm">
              <Card.Body>
                <Image src={Partner1Image} className="w-100 pb-3" />
                <div className="pb-2">Travel Assistance</div>
                <div className="small text-muted fw-light pb-4">
                  It's time to provide knowledge about the destinations that you
                  master to various travelers from various regions in the world.
                </div>
                <Button as={Link} to="/partners/register/travel-assistance" variant="primary" className="w-100 text-white">
                  Register Travel Assistance
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </DesktopWrapper>
  );
}
export default PartnersDesktopPage;
