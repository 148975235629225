import React, { useContext, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Card, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import ReactSelect from "react-select";
import FooterNavigation from "../footerNavigation";
import UserContext from "../../../../../services/user";
import { useTranslation } from "react-i18next";

const schema = yup
  .object({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    title: yup.string().required("Title is required"),
    countryCode: yup.string().required("Country Code is required"),
    phoneNumber: yup.string().required("Phone Number is required"),
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email is required"),
    lastJob: yup.string().required("Last Job is required"),
    lastEducation: yup.string().required("Last Education is required"),
    shortDescription: yup.string().required("Short Description is required"),
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    cPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();

function Step1(params) {
  const { t } = useTranslation();
  const [dataTitle, setDataTitle] = useState([
    {
      value: "Mr",
      label: "Mr",
    },
    {
      value: "Ms",
      label: "Ms",
    },
    {
      value: "Mrs",
      label: "Mrs",
    },
  ]);
  const [dataCountryCode, setDataCountryCode] = useState([
    {
      value: "+62",
      label: "+62",
    },
  ]);
  const { registerForm } = useContext(UserContext);
  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const submitStep = async (values) => {
    const response = await registerForm(values);
    if (response) {
      params.nextStep();
    }
  };

  return (
    <Card className="card-step border-0 shadow-sm">
      <Card.Body>
        <div className="pb-3">{t("profile")}</div>
        <div className="mb-4">
          <Controller
            control={control}
            name="title"
            render={({ field: { onChange, value, ref } }) => (
              <ReactSelect
                ref={ref}
                className="select"
                classNamePrefix="select"
                placeholder={t("title")}
                isSearchable={false}
                value={dataTitle.find((c) => c.value === value)}
                onChange={(val) => onChange(val.value)}
                options={dataTitle}
              />
            )}
          />
          <small className="text-danger">{errors.title?.message}</small>
        </div>
        <div className="mb-4">
          <Form.Control
            {...register("firstName")}
            className="control-input mt-2"
            placeholder={t("first_name")}
          />
          <small className="text-danger">{errors.firstName?.message}</small>
        </div>
        <div className="mb-4">
          <Form.Control
            {...register("lastName")}
            className="control-input mt-2"
            placeholder={t("last_name")}
          />
          <small className="text-danger">{errors.lastName?.message}</small>
        </div>
        <Row className="mt-2 mb-4">
          <Col sm="4" md="4" lg="4" xl="4">
            <Controller
              control={control}
              name="countryCode"
              render={({ field: { onChange, value, ref } }) => (
                <ReactSelect
                  ref={ref}
                  className="select"
                  classNamePrefix="select"
                  isSearchable={false}
                  placeholder="+xx"
                  value={dataCountryCode.find((c) => c.value === value)}
                  onChange={(val) => onChange(val.value)}
                  options={dataCountryCode}
                />
              )}
            />
            <small className="text-danger">{errors.countryCode?.message}</small>
          </Col>
          <Col sm="8" md="8" lg="8" xl="8">
            <Form.Control
              {...register("phoneNumber")}
              className="control-input"
              placeholder={t("phone_number")}
            />
            <small className="text-danger">{errors.phoneNumber?.message}</small>
          </Col>
        </Row>
        <div className="mb-4">
          <Form.Control
            {...register("email")}
            type="email"
            className="control-input mt-2"
            placeholder={t("email_address")}
          />
          <small className="text-danger">{errors.email?.message}</small>
        </div>
        <div className="mb-3">
          <Form.Control
            type="password"
            className="control-input"
            placeholder="Password"
            {...register("password")}
          />
          <small className="text-danger">{errors.password?.message}</small>
        </div>
        <div className="mb-3">
          <Form.Control
            type="password"
            className="control-input"
            placeholder="Retype Password"
            {...register("cPassword")}
          />
          <small className="text-danger">{errors.cPassword?.message}</small>
        </div>
        <div className="mb-4">
          <Form.Control
            className="control-input mb-3"
            placeholder="Last Job"
            {...register("lastJob")}
          />
          <small className="text-danger">{errors.lastJob?.message}</small>
        </div>
        <div className="mb-4">
          <Form.Control
            className="control-input mb-3"
            placeholder="Last Education"
            {...register("lastEducation")}
          />
          <small className="text-danger">{errors.lastEducation?.message}</small>
        </div>
        <div className="mb-4">
          <Form.Control
            as="textarea"
            className="control-input mb-3"
            placeholder="Short Description"
            {...register("shortDescription")}
          />
          <small className="text-danger">
            {errors.shortDescription?.message}
          </small>
        </div>
        <FooterNavigation
          totalSteps={params.totalSteps}
          currentStep={params.currentStep}
          previousStep={params.previousStep}
          nextStep={handleSubmit(submitStep)}
          disabled={false}
          submit={params.submit}
        />
      </Card.Body>
    </Card>
  );
}

export default Step1;
