import React, { useContext, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import DesktopWrapper from "../../../layout/desktop/DesktopWrapper";
import { Link, redirect, useNavigate } from "react-router-dom";
import UserContext from "../../../services/user";
import moment from "moment";
import BeatLoader from "react-spinners/BeatLoader";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import DipayImg from "../../../assets/images/dipay/dipay.png";
import DipayActivationImg from "../../../assets/images/dipay/Activation.svg";
import ReactSelect from "react-select";
import { toast } from "react-toastify";

var popupModal = (function () {
  var modal = null;
  return {
    openPopup(url) {
      modal = window
        .picoModal({
          content:
            '<iframe frameborder="0" style="height:90vh; width:100%;" src="' +
            url +
            '"></iframe>',
          width: "75%",
          closeButton: false,
          overlayClose: false,
          escCloses: false,
        })
        .show();
    },
    closePopup() {
      try {
        modal.close();
      } catch (e) {}
    },
  };
})();

const schema = yup
  .object({
    card_number: yup.string().required(),
    card_exp_month: yup.string().required(),
    card_exp_year: yup.string().required(),
    card_cvv: yup.string().required(),
  })
  .required();

const schema2 = yup
  .object({
    phone_number: yup.string().required(),
  })
  .required();

const schema3 = yup
  .object({
    pin: yup.string().required(),
  })
  .required();

function PaymentDesktopPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { payment, data, getMaster, getAllMaster, addMaster } =
    useContext(UserContext);
  const [dataDetail, setDataDetail] = useState({});
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [dataBefore, setDataBefore] = useState({});
  const [dataPayment, setDataPayment] = useState([]);
  const [tokenCC, setTokenCC] = useState({});
  const [modal, setModal] = useState({
    open: false,
    body: "",
    isSubmit: false,
    onSubmit: () => {},
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      card_number: "",
      card_exp_month: "",
      card_exp_year: "",
      card_cvv: "",
    },
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm({
    resolver: yupResolver(schema2),
    defaultValues: {
      phone_number: "",
    },
  });

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    reset: reset3,
    formState: { errors: errors3 },
  } = useForm({
    resolver: yupResolver(schema3),
    defaultValues: {
      pin: "",
    },
  });

  const getCCToken = async (values) => {
    const newData = await addMaster("payment/got-token-cc", values);
    if (!newData?.err) {
      setTokenCC({
        token_id: newData?.data?.toke_id,
        hash: newData?.data?.hash,
      });
      submitPayment(
        "credit_card",
        "credit_card",
        "credit card",
        newData?.data?.toke_id
      );
    }
  };

  const showCC = () => {
    setModal({
      open: true,
      type: "cc",
    });
  };

  const showDipay = () => {
    setModal({
      open: true,
      type: "dipay",
    });
  };

  const showLinked = () => {
    setModal({
      open: true,
      type: "dipay-linked",
    });
  };

  const showOtp = async (values) => {
    if (
      localStorage.getItem("accountId") &&
      localStorage.getItem("accountId") !== "undefined"
    ) {
      setModal({
        open: true,
        type: "dipay-pay",
      });
    } else {
      const param = {
        email: localStorage.getItem("email"),
        onBoardingPartner: "1232121",
        name:
          localStorage.getItem("firstName") +
          " " +
          localStorage.getItem("lastName"),
        partnerReferenceNo: "789911910711111",
        phoneNo: "62" + values?.phone_number,
        deviceInfo: {
          os: "x",
          city: "x",
          referralCode: "x",
          dateOfBirth: "x",
          manufacture: "x",
          latitude: "x",
          osVersion: "x",
          deviceId: "x",
          model: "x",
          phoneNo: "62" + values?.phone_number,
          longitude: "x",
        },
      };
      const newData = await addMaster(
        "dipay/registration-account-creation",
        param
      );
      if (!newData?.err) {
        localStorage.setItem("accountId", newData?.data?.accountId);
        localStorage.setItem("phoneNo", newData?.data?.additionalInfo?.phoneNo);
        reset3();
        setModal({
          open: true,
          type: "dipay-pin",
        });
      } else {
        reset3();
      }
    }
  };

  const showPay = async (values) => {
    const param = {
      phoneNo: localStorage.getItem("phoneNo"),
      email: localStorage.getItem("email"),
      accountId: localStorage.getItem("accountId"),
      additionalInfo: {
        pin: values?.pin,
      },
    };
    const newData = await addMaster("dipay/account-binding", param);
    if (!newData?.err) {
      localStorage.setItem("accessTokenDipay", newData?.data?.accessToken);
      setModal({
        open: true,
        type: "dipay-pay",
      });
    } else {
    }
  };

  const showPin = () => {
    reset3();
    setModal({
      open: true,
      type: "dipay-pin2",
    });
  };

  const showProcess = (values) => {
    submitPayment(
      "dipay",
      "bca",
      "dipay",
      "string",
      localStorage.getItem("phoneNo"),
      localStorage.getItem("accessTokenDipay"),
      values?.pin
    );
  };

  const submitPayment = async (
    type,
    bank,
    category,
    ccToken,
    phoneNumberDipay = null,
    accessTokenDipay = null,
    pinDipay = null
  ) => {
    setLoadingPayment(true);
    const param = {
      productId: dataBefore?.dataBefore?._id,
      quantity: dataBefore?.dataBefore?.qty,
      departureDate: moment(dataBefore?.dataBefore?.startDate).format(
        "YYYY-MM-DD"
      ),
      paymentMethod: type,
      bankName: bank,
      phoneNumberDipay: phoneNumberDipay,
      pinDipay: pinDipay,
      accessTokenDipay: accessTokenDipay,
      ccToken: ccToken,
      customers: dataBefore?.customers,
    };
    if (dataBefore?.dataBefore?.voucherCode) {
      param.voucher = dataBefore?.dataBefore?.voucherCode;
    }
    const newData = await addMaster("order/create-order", param);
    if (!newData?.err) {
      setLoadingPayment(false);
      dataBefore.payment = newData?.data;
      const newData2 = await payment(dataBefore);
      if (newData2) {
        if (category === "e-money") {
          if (isMobile) {
            window.open(
              newData2?.payment?.eWalletActions?.length > 0 &&
                newData2?.payment?.eWalletActions[1]?.url
            );
            redirect("/local-experience/receipt/" + newData2?.payment?._id);
          } else {
            setModal({
              open: true,
              type: "qr",
              redirect: "/local-experience/receipt/" + newData2?.payment?._id,
              body: (
                <Image
                  src={
                    newData2?.payment?.eWalletActions?.length > 0 &&
                    newData2?.payment?.eWalletActions[0]?.url
                  }
                  className="img-qr"
                />
              ),
            });
          }
        } else if (
          category === "credit card" &&
          newData2?.payment?.credit_card_redirect_url
        ) {
          var options = {
            performAuthentication: function (redirect_url) {
              popupModal.openPopup(redirect_url);
            },
            onSuccess: function (response) {
              toast.success(response?.status_message);
              popupModal.closePopup();
              navigate("/local-experience/receipt/" + newData2?.payment?._id);
            },
            onFailure: function (response) {
              toast.error(response?.status_message);
              popupModal.closePopup();
            },
            onPending: function (response) {
              toast.error(response?.status_message);
              popupModal.closePopup();
            },
          };
          window.MidtransNew3ds.authenticate(
            newData2?.payment?.credit_card_redirect_url,
            options
          );
        } else {
          navigate("/local-experience/receipt/" + newData2?.payment?._id);
        }
      }
    } else {
      reset3();
      setLoadingPayment(false);
    }
  };

  const getData = async () => {
    const newData = await getMaster("product", data?.dataBefore?._id);
    if (newData) {
      setDataDetail(newData.data);
    }
  };

  const getDataPayment = async () => {
    const newData = await getAllMaster("payment/payment-methods");
    if (newData) {
      const groups = newData?.data.reduce((groups, item) => {
        const group = groups[item.category] || [];
        group.push(item);
        groups[item.category] = group;
        return groups;
      }, {});
      setDataPayment(groups);
    }
  };

  useEffect(() => {
    if (data?.dataBefore) {
      setDataBefore(data);
      getData();
      getDataPayment();
    } else {
      // navigate("/")
    }
  }, []);

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
    textAlign: "center",
  };

  return (
    <DesktopWrapper>
      {loadingPayment ? (
        <div className="my-5">
          <BeatLoader
            color={"#ff5b00"}
            loading={loadingPayment}
            cssOverride={override}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          <div className="h4 text-center mt-4">Memproses Pembayaran</div>
        </div>
      ) : (
        <Container className="detail-product py-4">
          <Row>
            <Col sm="12" md="12" lg="12" xl="12" className="mb-4">
              <h4>{t("payment_confirmation")}</h4>
            </Col>
            <Col sm="12" md="12" lg="8" xl="8">
              {dataPayment &&
                Object.keys(dataPayment).map((item, index) => (
                  <div className="mb-4" key={index}>
                    <h6 className="fw-bold">
                      {item === "e-money" ? "QRIS" : item.toUpperCase()}
                    </h6>
                    <Card className="border-0 shadow-sm">
                      {dataPayment[item].map((subItem, subIndex) => (
                        <Card.Body key={subIndex} className="py-0">
                          {subItem.name !== "shopeePay" && (
                            <div
                              className="d-flex align-items-center justify-content-between py-1"
                              role="button"
                              onClick={() =>
                                item === "credit card"
                                  ? showCC()
                                  : submitPayment(
                                      subItem.value,
                                      subItem.name,
                                      item
                                    )
                              }
                            >
                              <div>
                                {subItem.name === "gopay"
                                  ? "QRIS"
                                  : subItem.name.toUpperCase()}
                              </div>
                              <Image
                                src={
                                  process.env.REACT_APP_ASSET_URL +
                                  "/" +
                                  subItem.picture
                                }
                                className="img-payment"
                              />
                            </div>
                          )}

                          {dataPayment[item]?.length > 1 &&
                            subIndex + 1 !== dataPayment[item]?.length && (
                              <hr className="my-0"></hr>
                            )}
                        </Card.Body>
                      ))}
                    </Card>
                  </div>
                ))}

              <div className="mb-4">
                <h6 className="fw-bold">DIPAY</h6>
                <Card className="border-0 shadow-sm">
                  <Card.Body className="py-0">
                    <div
                      className="d-flex align-items-center justify-content-between py-1"
                      role="button"
                      onClick={showDipay}
                    >
                      <div>DIPAY</div>
                      <Image src={DipayImg} className="img-payment" />
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
            <Col sm="12" md="12" lg="4" xl="4">
              <Card className="border-0 shadow-sm mt-4">
                <Card.Body className="p-3">
                  <h6 className="mb-4">{t("booking")}</h6>
                  <Tabs defaultActiveKey="detail" className="mb-3 payment-tab">
                    <Tab eventKey="detail" title="Detail">
                      <div>
                        <h6 className="text-dark-grey">
                          {t("ticket_information")}
                        </h6>
                        <Card className="bg-light-grey mb-3">
                          <Card.Body className="p-2">
                            <div className="d-flex">
                              <Image
                                src={
                                  dataDetail?.generalInformation?.picture
                                    ?.length > 0 &&
                                  process.env.REACT_APP_FILE_URL +
                                    "/" +
                                    dataDetail?.generalInformation?.picture[0]
                                }
                                className="image-information"
                              />
                              <div className="ms-2">
                                <h6>{dataDetail?.generalInformation?.name}</h6>
                                <small className="text-muted fw-light">
                                  {dataDetail?.productDetail?.city +
                                    ", " +
                                    dataDetail?.productDetail?.province}
                                </small>
                              </div>
                            </div>
                            <hr className="line-information"></hr>
                            <div className="small">
                              <div className="d-flex align-items-center justify-content-between pb-2">
                                <div className="fw-light">
                                  {t("date_of_departure")}
                                </div>
                                <div>
                                  {moment(
                                    dataBefore?.dataBefore?.startDate
                                  ).format("LL")}
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between py-2">
                                <div className="fw-light">
                                  {t("trip_duration")}
                                </div>
                                <div>
                                  {dataDetail?.pricingDetail?.duration} days
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>

                        <h6 className="text-dark-grey">
                          {t("customer_information")}
                        </h6>
                        <Card className="bg-light-grey">
                          <Card.Body className="p-2">
                            <span>
                              {dataBefore?.title +
                                " " +
                                dataBefore?.firstName +
                                " " +
                                dataBefore?.lastName}
                            </span>
                            <hr className="line-information"></hr>
                            <div className="small">
                              <div className="pb-2">
                                <div className="fw-light pb-2">
                                  {t("phone_number")}
                                </div>
                                <div>
                                  {dataBefore?.countryCode +
                                    " " +
                                    dataBefore?.phoneNumber}
                                </div>
                              </div>
                              <div className="py-2">
                                <div className="fw-light pb-2">
                                  {t("email_address")}
                                </div>
                                <div>{dataBefore?.email}</div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Tab>
                    <Tab eventKey="price" title={t("price")}>
                      <h6>{dataDetail?.generalInformation?.name}</h6>
                      <div className="small fw-light mt-3">
                        <div className="pb-3">{t("ticket_price")}</div>
                        <ul className="text-grey">
                          <li className="pb-2">
                            <div className="d-flex justify-content-between">
                              <div>
                                {t("guest")} (x{dataBefore?.dataBefore?.qty})
                              </div>
                              <div>
                                {new Intl.NumberFormat("id-ID", {
                                  style: "currency",
                                  currency: "IDR",
                                  minimumFractionDigits: 0,
                                }).format(
                                  dataDetail?.pricingDetail?.crossOutPrice *
                                    dataBefore?.dataBefore?.qty
                                )}{" "}
                              </div>
                            </div>
                          </li>
                        </ul>
                        <hr className="line-information"></hr>

                        <div className="pb-3">{t("other_costs")}</div>
                        <ul className="text-grey">
                          <li className="pb-2">
                            <div className="d-flex justify-content-between">
                              <div>{t("tax")}</div>
                              <div>{t("included")} </div>
                            </div>
                          </li>
                        </ul>
                        <hr className="line-information"></hr>
                        <ul className="text-grey">
                          <li className="pb-2">
                            <div className="d-flex justify-content-between">
                              <div>{t("discount")}</div>
                              <div>
                                -{" "}
                                {new Intl.NumberFormat("id-ID", {
                                  style: "currency",
                                  currency: "IDR",
                                  minimumFractionDigits: 0,
                                }).format(
                                  (dataDetail?.pricingDetail?.crossOutPrice -
                                    dataDetail?.pricingDetail?.publishedPrice) *
                                    dataBefore?.dataBefore?.qty
                                )}
                              </div>
                            </div>
                          </li>
                        </ul>
                        <hr className="line-information"></hr>
                        <ul className="text-grey">
                          <li className="pb-2">
                            <div className="d-flex justify-content-between">
                              <div>
                                {t("wholesales")} (x
                                {dataBefore?.dataBefore?.qty})
                              </div>
                              <div>
                                -{" "}
                                {new Intl.NumberFormat("id-ID", {
                                  style: "currency",
                                  currency: "IDR",
                                  minimumFractionDigits: 0,
                                }).format(
                                  dataBefore?.dataBefore?.wholesalesDiscount
                                )}
                              </div>
                            </div>
                          </li>
                        </ul>
                        <ul className="text-grey">
                          <li className="pb-2">
                            <div className="d-flex justify-content-between">
                              <div>
                                Campaign (x{dataBefore?.dataBefore?.qty})
                              </div>
                              <div>
                                -{" "}
                                {new Intl.NumberFormat("id-ID", {
                                  style: "currency",
                                  currency: "IDR",
                                  minimumFractionDigits: 0,
                                }).format(
                                  dataBefore?.dataBefore?.campaignDiscount
                                )}
                              </div>
                            </div>
                          </li>
                        </ul>
                        <hr></hr>
                        <div className="d-flex align-items-center justify-content-between fw-normal">
                          <div>{t("total_payment")}</div>
                          <div className="text-primary h6">
                            {new Intl.NumberFormat("id-ID", {
                              style: "currency",
                              currency: "IDR",
                              minimumFractionDigits: 0,
                            }).format(dataBefore?.dataBefore?.totalPrice)}{" "}
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Modal
            show={modal.open && modal.type === "qr"}
            onHide={() =>
              setModal({
                open: false,
                body: "",
              })
            }
            centered
            className="bg-grey bg-opacity-75"
          >
            <Modal.Body>
              <div className="mb-5">{modal.body}</div>
              <Button
                className="text-white w-100"
                variant="grey"
                onClick={() => {
                  navigate(modal.redirect);
                  setModal({
                    open: false,
                    body: "",
                  });
                }}
              >
                Done
              </Button>
            </Modal.Body>
          </Modal>

          <Modal
            show={modal.open && modal.type === "cc"}
            onHide={() =>
              setModal({
                open: false,
                type: "",
              })
            }
            centered
            className="bg-grey bg-opacity-75"
          >
            <Modal.Body>
              <div className="mb-5">
                <Row>
                  <Col sm="12" md="12" lg="12" xl="12">
                    <div className="mb-3">
                      <Form.Control
                        className="control-input"
                        placeholder="Card Number"
                        {...register("card_number")}
                      />
                      <small className="text-danger">
                        {errors.card_number?.message}
                      </small>
                    </div>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="4">
                    <div className="mb-3">
                      <Form.Control
                        className="control-input"
                        placeholder="Card Exp Month"
                        {...register("card_exp_month")}
                      />
                      <small className="text-danger">
                        {errors.card_exp_month?.message}
                      </small>
                    </div>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="4">
                    <div className="mb-3">
                      <Form.Control
                        className="control-input"
                        placeholder="Card Exp Year"
                        {...register("card_exp_year")}
                      />
                      <small className="text-danger">
                        {errors.card_exp_year?.message}
                      </small>
                    </div>
                  </Col>
                  <Col sm="12" md="4" lg="4" xl="4">
                    <div className="mb-3">
                      <Form.Control
                        className="control-input"
                        placeholder="cvv"
                        {...register("card_cvv")}
                      />
                      <small className="text-danger">
                        {errors.card_cvv?.message}
                      </small>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="w-100 d-flex">
                <Button
                  className="text-white w-100"
                  variant="grey"
                  onClick={() =>
                    setModal({
                      open: false,
                      type: "",
                    })
                  }
                >
                  Close
                </Button>
                <Button
                  className="text-white w-100"
                  onClick={handleSubmit(getCCToken)}
                >
                  Submit
                </Button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={modal.open && modal.type === "dipay"}
            onHide={() =>
              setModal({
                open: false,
                type: "",
              })
            }
            centered
            className="bg-grey bg-opacity-75"
          >
            <Modal.Body>
              <div className="text-center mb-4">
                <Image src={DipayActivationImg} className="mb-4" />
                <div className="fs-4 mb-4">Lebih mudah pakai Dipay</div>
                <div className="mb-4">
                  Yuk gunakan Dipay untuk pembayaran yang lebih mudah dan
                  berbagai penawaran lainnya
                </div>
                <div className="fw-light mb-4">
                  Dengan Aktivasi Anda telah setuju dengan{" "}
                  <a href="https://dipay.id/terms-conditions" target="_blank">
                    Syarat dan Ketentuan
                  </a>{" "}
                  &{" "}
                  <a href="https://dipay.id/privacy-policy" target="_blank">
                    Kebijakan Privasi
                  </a>{" "}
                  Dipay
                </div>
              </div>
              <Button className="text-white w-100" onClick={showLinked}>
                Aktivasi
              </Button>
            </Modal.Body>
          </Modal>

          <Modal
            show={modal.open && modal.type === "dipay-linked"}
            onHide={() =>
              setModal({
                open: false,
                type: "",
              })
            }
            centered
            className="bg-grey bg-opacity-75"
          >
            <Modal.Body>
              <div className="text-center">
                <div className="fs-4 mb-4">Aktivasi Dipay</div>
                <div className="mb-4">
                  Silakan masukkan nomor handphone yang terdaftar pada aplikasi
                  Dipay
                </div>

                <div className="mb-4">
                  <Row>
                    <Col sm="12" md="3" lg="3" xl="3">
                      <ReactSelect
                        className="select"
                        classNamePrefix="select"
                        isSearchable={false}
                        placeholder="+xx"
                        defaultValue={{
                          value: "+62",
                          label: "+62",
                        }}
                        name="title"
                        options={[
                          {
                            value: "+62",
                            label: "+62",
                          },
                        ]}
                      />
                    </Col>
                    <Col sm="12" md="9" lg="9" xl="9">
                      <Form.Control
                        className="control-input"
                        placeholder={t("phone_number")}
                        {...register2("phone_number")}
                      />
                      <small className="text-danger">
                        {errors2.phone_number?.message}
                      </small>
                    </Col>
                  </Row>
                </div>

                <Button
                  className="text-white w-100 mb-4"
                  onClick={handleSubmit2(showOtp)}
                >
                  Hubungkan
                </Button>
                <div className="fw-light mb-2">belum memiliki akun Dipay?</div>
              </div>
              <Button
                variant="outline-primary"
                className="w-100"
                onClick={(e) => {
                  e.preventDefault();
                  window.open("https://dipay.id/download", "_blank");
                }}
              >
                Download & Daftar Sekarang
              </Button>
            </Modal.Body>
          </Modal>

          {/* <Modal
            show={modal.open && modal.type === "dipay-otp"}
            onHide={() =>
              setModal({
                open: false,
                type: "",
              })
            }
            centered
            className="bg-grey bg-opacity-75"
          >
            <Modal.Body>
              <div className="text-center">
                <div className="fs-4 mb-4">Masukkan Kode Verifikasi</div>
                <div className="mb-4">
                  Kode verifikasi telah dikirimkan ke +
                  {localStorage.getItem("phoneNo")}
                </div>

                <div className="mb-4">
                  <Form.Control
                    className="control-input"
                    placeholder={t("otp_code")}
                    {...register3("otp_code")}
                  />
                  <small className="text-danger">
                    {errors3.otp_code?.message}
                  </small>
                </div>

                <Button className="text-white w-100 mb-4" onClick={showPay}>
                  Verifikasi
                </Button>
                <div className="fw-light mb-2">
                  Tidak menerima kode?{" "}
                  <a href="#" onClick="return false;">
                    Kirim ulang
                  </a>
                </div>
              </div>
            </Modal.Body>
          </Modal> */}

          <Modal
            show={modal.open && modal.type === "dipay-pay"}
            onHide={() =>
              setModal({
                open: false,
                type: "",
              })
            }
            centered
            className="bg-grey bg-opacity-75"
          >
            <Modal.Body>
              <div className="text-center">
                <div className="fs-4 mb-4">Konfirmasi Pembayaran</div>
                <div className="mb-4">
                  <Row>
                    <Col
                      sm="12"
                      md="6"
                      lg="6"
                      xl="6"
                      className="text-start mb-2"
                    >
                      {dataDetail?.generalInformation?.name}
                    </Col>
                    <Col sm="12" md="6" lg="6" xl="6" className="text-end mb-2">
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        minimumFractionDigits: 0,
                      }).format(dataBefore?.dataBefore?.totalPrice)}
                    </Col>
                    <Col sm="12" md="6" lg="6" xl="6" className="text-start">
                      Biaya Admin
                    </Col>
                    <Col sm="12" md="6" lg="6" xl="6" className="text-end">
                      Rp 0
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col
                      sm="12"
                      md="6"
                      lg="6"
                      xl="6"
                      className="text-start mb-2"
                    >
                      Total Pembayaran
                    </Col>
                    <Col sm="12" md="6" lg="6" xl="6" className="text-end mb-2">
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        minimumFractionDigits: 0,
                      }).format(dataBefore?.dataBefore?.totalPrice)}
                    </Col>
                  </Row>
                </div>
                <Button className="text-white w-100 mb-2" onClick={showPin}>
                  Bayar
                </Button>
                <Button
                  className="text-white w-100"
                  variant="grey"
                  onClick={() => {
                    setModal({
                      open: false,
                      body: "",
                    });
                  }}
                >
                  Batal
                </Button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={
              modal.open &&
              (modal.type === "dipay-pin" || modal.type === "dipay-pin2")
            }
            onHide={() =>
              setModal({
                open: false,
                type: "",
              })
            }
            centered
            className="bg-grey bg-opacity-75"
          >
            <Modal.Body>
              <div className="text-center">
                <div className="fs-4 mb-4">PIN Dipay</div>
                <div className="mb-4">
                  Silahkan masukkan 6 digit kode PIN Dipay Anda
                </div>

                <div className="mb-4">
                  <Form.Control
                    className="control-input"
                    placeholder={t("pin_code")}
                    type="password"
                    {...register3("pin")}
                  />
                  <small className="text-danger">{errors3.pin?.message}</small>
                </div>

                <Button
                  className="text-white w-100 mb-4"
                  onClick={handleSubmit3(
                    modal.type === "dipay-pin" ? showPay : showProcess
                  )}
                >
                  Verifikasi
                </Button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={modal.open && modal.type === "dipay-process"}
            onHide={() =>
              setModal({
                open: false,
                type: "",
              })
            }
            centered
            className="bg-grey bg-opacity-75"
          >
            <Modal.Body>
              <div className="text-center">
                <div className="fs-4 mb-4">Pembayaran sedang diproses</div>
                <Button
                  className="text-white w-100"
                  variant="grey"
                  onClick={() => {
                    setModal({
                      open: false,
                      body: "",
                    });
                  }}
                >
                  Selesai
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </Container>
      )}
    </DesktopWrapper>
  );
}
export default PaymentDesktopPage;
