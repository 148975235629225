import React, { useState, useEffect } from "react";
import GuideCard from "./guideCard.js";
import axios from "axios";
import { Link } from "react-router-dom";
// import GuideProduct from "./guideProduct.js";
import { useTranslation } from "react-i18next";

const GuideList = () => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState([]);
  const [regions, setRegions] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_GUIDE);
        if (response.data) {
          const usersWithId = Object.values(response.data).map(
            (user, index) => ({
              id: Object.keys(response.data)[index],
              ...user,
            })
          );
          setUserData(usersWithId);
          const regionsArray = usersWithId.map((user) => user.region);
          const uniqueRegions = [...new Set(regionsArray)];
          setRegions(uniqueRegions);
          const languagesArray = usersWithId.reduce((acc, curr) => {
            Object.values(curr.language).forEach((lang) => {
              if (!acc.includes(lang)) {
                acc.push(lang);
              }
            });
            return acc;
          }, []);
          setLanguages(languagesArray);
        } else {
          console.error("Data is null or undefined");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleRegionChange = (e) => {
    console.log("Selected Region:", e.target.value);
    setSelectedRegion(e.target.value);
  };
  const handleLanguageChange = (e) => {
    console.log("Selected Language:", e.target.value);
    setSelectedLanguage(e.target.value);
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-2">
          <select
            id="regionSelect"
            className="form-select mt-2 mb-3"
            value={selectedRegion}
            onChange={handleRegionChange}
            style={{
              ":hover": {
                backgroundColor: "var(--primary-color)",
              },
            }}
          >
            <option value="">{t("location")}</option>
            {regions.map((region, index) => (
              <option key={index} value={region}>
                {region}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-2 w-auto">
          <select
            id="languageSelect"
            className="form-select mt-2 mb-3"
            value={selectedLanguage}
            onChange={handleLanguageChange}
          >
            <option value="">{t("bahasa")}</option>
            {languages.map((language, index) => (
              <option key={index} value={language}>
                {language}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row">
        {userData
          .filter(
            (user) =>
              (!selectedRegion || user.region === selectedRegion) &&
              (!selectedLanguage ||
                Object.values(user.language).includes(selectedLanguage))
          )
          .map((user) => (
            <GuideCard key={user.id} user={user} />
          ))}
      </div>
    </div>
  );
};
export default GuideList;
