import React, { useReducer } from "react";
import { toast } from "react-toastify";
import ApiWithoutAuth from "../../config/api/ApiWithoutAuth";
import AuthContext from "./index";
import AuthReducer from "./reducer";
import { LOGIN, LOGOUT, GET_USER_DATA } from "./types";

const AuthState = ({ children }) => {
  const initialState = {
    loading: false,
    payload: {},
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const login = async (email, password) => {
    try {
      const body = {
        email: email,
        password: password,
      };
      const data = await ApiWithoutAuth.postDataWithoutAuth(
        `/user/login`,
        JSON.stringify(body)
      );
      const response = data?.data;
      if (!response?.err) {
        localStorage.setItem("accessToken", response?.data?.accessToken?.token);
        localStorage.setItem(
          "expiresIn",
          response?.data?.accessToken?.expiresIn
        );
        localStorage.setItem("_id", response?.data?.user?._id);
        localStorage.setItem(
          "fullName",
          !response?.data?.user?.role
            ? response?.data?.user?.firstName +
                " " +
                response?.data?.user?.lastName
            : response?.data?.user?.fullName
        );
        localStorage.setItem(
          "firstName",
          response?.data?.user?.firstName || ""
        );
        localStorage.setItem("lastName", response?.data?.user?.lastName || "");
        localStorage.setItem("role", response?.data?.user?.role || "member");
        localStorage.setItem("title", response?.data?.user?.salutation || "");
        localStorage.setItem(
          "email",
          response?.data?.user?.contact?.email
        );
        localStorage.setItem(
          "countryCode",
          response?.data?.user?.contact?.countryCode
        );
        localStorage.setItem(
          "phone",
          response?.data?.user?.contact?.phone
        );
      } else {
        toast.error(JSON.stringify(response?.err));
      }
      dispatch({ type: LOGIN, payload: data });
      return response?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const loginByGoogle = async (code) => {
    try {
      const body = {code};
      const data = await ApiWithoutAuth.postDataWithoutAuth(
        `/user/login/with-google`,
        JSON.stringify(body)
      );
      const response = data?.data;
      if (!response?.err) {
        localStorage.setItem("accessToken", response?.data?.accessToken?.token);
        localStorage.setItem(
          "expiresIn",
          response?.data?.accessToken?.expiresIn
        );
        localStorage.setItem("_id", response?.data?.user?._id);
        localStorage.setItem(
          "fullName",
          !response?.data?.user?.role
            ? response?.data?.user?.firstName +
                " " +
                response?.data?.user?.lastName
            : response?.data?.user?.fullName
        );
        localStorage.setItem(
          "firstName",
          response?.data?.user?.firstName || ""
        );
        localStorage.setItem("lastName", response?.data?.user?.lastName || "");
        localStorage.setItem("role", response?.data?.user?.role || "member");
        localStorage.setItem("title", response?.data?.user?.salutation || "");
        localStorage.setItem(
          "email",
          response?.data?.user?.contact?.email
        );
        localStorage.setItem(
          "countryCode",
          response?.data?.user?.contact?.countryCode || ""
        );
        localStorage.setItem(
          "phone",
          response?.data?.user?.contact?.phone || ""
        );
      } else {
        toast.error(JSON.stringify(response?.err));
      }
      dispatch({ type: LOGIN, payload: data });
      return response?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const logout = async () => {
    try {
      dispatch({ type: LOGOUT });
      localStorage.clear();
    } catch (err) {
      console.error(err.message);
    }
  };

  const getUser = async () => {
    try {
      const data = await ApiWithoutAuth.getDataWithoutAuth(
        "/tokens/user/" + localStorage.getItem("_id")
      );
      localStorage.setItem("user", data?.data?.data);
      dispatch({ type: GET_USER_DATA });
    } catch (err) {
      console.error(err.message);
    }
  };

  const { loading, payload } = state;

  return (
    <AuthContext.Provider
      value={{
        loading,
        payload,
        login,
        loginByGoogle,
        logout,
        getUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthState;
