import { useRef } from "react";
import ItemLocalExperience from "./itemLocalExperience";
import { SkeletonCustom } from "../../global/skeleton";

const sideScroll = (element, speed, distance, step) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);
};

function ItemGroup(props) {
  const contentWrapper = useRef(null);

  return (
    <div>
      {props?.loading ? (
        <SkeletonCustom height="300px" />
      ) : (
        <div className="group-all">
          <div
            className="btn-group prev-btn"
            onClick={() => sideScroll(contentWrapper.current, 10, 300, -10)}
          ></div>
          <div className="group-le" ref={contentWrapper}>
            {props?.data &&
              props?.data.map((item, index) => (
                <div key={index} className="mb-3 item-le">
                  <ItemLocalExperience data={item} />
                </div>
              ))}
          </div>
          <div
            className="btn-group next-btn"
            onClick={() => sideScroll(contentWrapper.current, 10, 300, 10)}
          ></div>
        </div>
      )}
    </div>
  );
}

export default ItemGroup;
