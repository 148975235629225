import React from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import DesktopWrapper from "../../layout/desktop/DesktopWrapper";
import { useState, useEffect } from "react";
import axios from "axios";
// import KategoriKeuangan1 from "../../assets/documents/KategoriKeuangan1.pdf";
// import KategoriKeuangan2 from "../../assets/documents/KategoriKeuangan2.pdf";
// import KategoriKeuangan3 from "../../assets/documents/KategoriKeuangan3.pdf";
// import KategoriKeuangan4 from "../../assets/documents/KategoriKeuangan4.pdf";
// import KategoriKeuangan5 from "../../assets/documents/KategoriKeuangan5.pdf";
// import KeterbukaanInformasi1 from "../../assets/documents/KeterbukaanInformasi1.pdf";
// import KeterbukaanInformasi2 from "../../assets/documents/KeterbukaanInformasi2.pdf";
// import KeterbukaanInformasi3 from "../../assets/documents/KeterbukaanInformasi3.pdf";
// import KeterbukaanInformasi4 from "../../assets/documents/KeterbukaanInformasi4.pdf";
// import KeterbukaanInformasi5 from "../../assets/documents/KeterbukaanInformasi5.pdf";

function InvestorPage() {
  const [activeTab, setActiveTab] = useState(1);
  const [document, setDocument] = useState([
    // {
    //   _id: 1,
    //   descriptionID:
    //     "Laporan Keuangan PT Tourindo Guide Indonesia Tbk 31 Desember 2023",
    //   articleDate: "2024-03-27",
    //   selectType: "Laporan Keuangan",
    //   file: KategoriKeuangan1,
    // },
    // {
    //   _id: 2,
    //   descriptionID:
    //     "Laporan Keuangan PT Tourindo Guide Indonesia Tbk 30 Juni 2023",
    //   articleDate: "2023-07-24",
    //   selectType: "Laporan Keuangan",
    //   file: KategoriKeuangan2,
    // },
    // {
    //   _id: 3,
    //   descriptionID:
    //     "Laporan Keuangan PT Tourindo Guide Indonesia Tbk 31 Desember 2022",
    //   articleDate: "2023-03-30",
    //   selectType: "Laporan Keuangan",
    //   file: KategoriKeuangan3,
    // },
    // {
    //   _id: 4,
    //   descriptionID: "Laporan Keuangan Juni 2022",
    //   articleDate: "2022-07-28",
    //   selectType: "Laporan Keuangan",
    //   file: KategoriKeuangan4,
    // },
    // {
    //   _id: 5,
    //   descriptionID: "Laporan Keuangan 2021",
    //   articleDate: "2022-04-01",
    //   selectType: "Laporan Keuangan",
    //   file: KategoriKeuangan5,
    // },
    // {
    //   _id: 6,
    //   descriptionID: "Pengumuman RUPST PGJO Tahun 2024",
    //   articleDate: "2024-03-21",
    //   selectType: "Keterbukaan Informasi",
    //   file: KeterbukaanInformasi1,
    // },
    // {
    //   _id: 7,
    //   descriptionID: "Perubahan Sekertaris Perusahaan PGJO",
    //   articleDate: "2024-01-11",
    //   selectType: "Keterbukaan Informasi",
    //   file: KeterbukaanInformasi2,
    // },
    // {
    //   _id: 8,
    //   descriptionID: "Perubahan Komite Audit PGJO",
    //   articleDate: "2024-01-11",
    //   selectType: "Keterbukaan Informasi",
    //   file: KeterbukaanInformasi3,
    // },
    // {
    //   _id: 9,
    //   descriptionID: "Perubahan Komite Nominasi dan Remunerasi",
    //   articleDate: "2023-05-23",
    //   selectType: "Keterbukaan Informasi",
    //   file: KeterbukaanInformasi4,
    // },
    // {
    //   _id: 10,
    //   descriptionID: "Perubahan Komite Audit",
    //   articleDate: "2023-05-23",
    //   selectType: "Keterbukaan Informasi",
    //   file: KeterbukaanInformasi5,
    // },
  ]);
  const [composition, setComposition] = useState([]);
  const [selectedType, setSelectedType] = useState("Rapat Umum Pemegang Saham");
  const [selectedFinance, setSelectedFinance] = useState("Laporan Keuangan");

  const [selectedYear, setSelectedYear] = useState("Tahun");
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);

  const uniqueYears = [
    ...new Set(
      document.map((item) => new Date(item.articleDate).getFullYear())
    ),
  ];

  // useEffect(() => {
  //   axios
  //     .get(process.env.REACT_APP_API_INVESTOR_DOCUMENT)
  //     .then((response) => {
  //       // console.log(response.data);
  //       setDocument(response.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_INVSTR_BASEURL}/document`)
      .then((response) => {
        // console.log(response.data);
        setDocument(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_INVSTR_BASEURL}/shareholder`)
      .then((response) => {
        // console.log(response.data);
        setComposition(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function sortDocumentsByDate(documents) {
    return documents
      .map((doc) => ({
        ...doc,
        articleDate: new Date(doc.articleDate),
      }))
      .sort((a, b) => b.articleDate - a.articleDate);
  }

  function formatDate(date) {
    return date.toLocaleDateString();
  }

  function updateToggle(id) {
    setActiveTab(id);
  }

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };
  const handleTypeFinance = (event) => {
    setSelectedFinance(event.target.value);
  };

  const handleRowClick = (documentId) => {
    setSelectedDocumentId(documentId);
  };

  const formatNumber = (num) => {
    return new Intl.NumberFormat("id-ID").format(num);
  };
  return (
    <DesktopWrapper>
      <Container className="additional-page" style={{ textAlign: "justify" }}>
        <h3 className="mt-5 mb-4 text-primary">Investor Relations</h3>
        <h6 className="mb-5 fw-light">PT Tourindo Guide Indonesia Tbk</h6>
        <div className=" tab tab-investor">
          <ul className="d-flex border-bottom list-unstyled ms-0">
            <li
              className={activeTab === 1 ? "active-tab" : ""}
              onClick={() => updateToggle(1)}
            >
              Stock Information
            </li>
            <li
              className={activeTab === 2 ? "active-tab" : ""}
              onClick={() => updateToggle(2)}
            >
              Information Disclosure
            </li>
            <li
              className={activeTab === 3 ? "active-tab" : ""}
              onClick={() => updateToggle(3)}
            >
              Finance
            </li>
          </ul>

          <div className={activeTab === 1 ? "show-content" : "content"}>
            <Card className="small mb-5">
              <Card.Body>
                <Row>
                  <Col sm="6" md="3" lg="3" xl="3" className="mb-4">
                    <div className="text-dark-grey mb-2 fw-light">
                      Stock code
                    </div>
                    <div>PGJO</div>
                  </Col>
                  <Col sm="6" md="3" lg="3" xl="3" className="mb-4">
                    <div className="text-dark-grey mb-2 fw-light">
                      Outstanding Shares
                    </div>
                    <div>-</div>
                  </Col>
                  <Col sm="6" md="3" lg="3" xl="3" className="mb-4">
                    <div className="text-dark-grey mb-2 fw-light">
                      Market Cap
                    </div>
                    <div>23.89 B</div>
                  </Col>
                  <Col sm="6" md="3" lg="3" xl="3" className="mb-4">
                    <div className="text-dark-grey mb-2 fw-light">
                      Listing Date
                    </div>
                    <div>08 January 2020</div>
                  </Col>
                  <Col sm="6" md="12" lg="12" xl="12" className="mb-4">
                    <div className="text-dark-grey mb-2 fw-light">
                      Effective date
                    </div>
                    <div>27 December 2019</div>
                  </Col>
                  <Col sm="6" md="3" lg="3" xl="3" className="mb-4">
                    <div className="text-dark-grey mb-2 fw-light">Nominal</div>
                    <div>80</div>
                  </Col>
                  <Col sm="6" md="3" lg="3" xl="3" className="mb-4">
                    <div className="text-dark-grey mb-2 fw-light">
                      IPO price
                    </div>
                    <div>80</div>
                  </Col>
                  <Col sm="6" md="3" lg="3" xl="3" className="mb-4">
                    <div className="text-dark-grey mb-2 fw-light">
                      IPO Stock
                    </div>
                    <div>150.00 M</div>
                  </Col>
                  <Col sm="6" md="3" lg="3" xl="3" className="mb-4">
                    <div className="text-dark-grey mb-2 fw-light">
                      Total IPO
                    </div>
                    <div>12.00 B</div>
                  </Col>
                  <Col sm="6" md="6" lg="3" xl="3" className="mb-4">
                    <div className="text-dark-grey mb-2 fw-light">
                      Underwriter
                    </div>
                    <div>PT Surya Fajar Sekuritas</div>
                  </Col>
                  <Col sm="6" md="6" lg="3" xl="3" className="mb-4">
                    <div className="text-dark-grey mb-2 fw-light">
                      Share Registrar
                    </div>
                    <div>PT Adimitra Jasa Korpora</div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <h6 className="mb-2">Shareholders Composition</h6>
            <div className="mb-3 text-dark-grey small fw-light">
              Effective at 31 Maret 2024
            </div>
            <Table className="investor-table mb-5">
              <thead>
                <tr className="text-primary">
                  <td>Name of Shareholders</td>
                  <td>Number of Shares</td>
                  <td>Percentage</td>
                </tr>
              </thead>
              <tbody>
                {composition.map((compositionItem) => (
                  <tr key={compositionItem.id}>
                    <td>{compositionItem.name}</td>
                    <td>{formatNumber(compositionItem.numberOfShares)}</td>
                    <td>{compositionItem.percentage + " %"}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className={activeTab === 2 ? "show-content" : "content"}>
            <select
              id="jenisRapat"
              name="jenisRapat"
              className="my-3"
              onFocus={(event) => {
                event.target.size = 0;
              }}
              onBlur={(event) => {
                event.target.size = 0;
              }}
              onChange={handleTypeChange}
              value={selectedType}
            >
              <option value="Rapat Umum Pemegang Saham">
                Rapat Umum Pemegang Saham
              </option>
              <option value="Keterbukaan Informasi">
                Keterbukaan Informasi
              </option>
              <option value="Siaran Pers">Siaran Pers</option>
            </select>
            <select
              id="Tahun"
              name="Tahun"
              className="select"
              value={selectedYear}
              onChange={(event) => {
                setSelectedYear(event.target.value);
              }}
            >
              <option value="Tahun">Tahun</option>
              {uniqueYears.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>

            <Table className="table-disclosure mb-5">
              <thead className="text-primary">
                <tr>
                  <td>Tanggal</td>
                  <td>Tipe Dokumen</td>
                  <td>Deskripsi</td>
                  <td>Unduh</td>
                </tr>
              </thead>
              <tbody>
                {sortDocumentsByDate(document)
                  .filter(
                    (item) =>
                      item.selectType === selectedType &&
                      (selectedYear === "Tahun" ||
                        new Date(item.articleDate).getFullYear() ==
                          selectedYear ||
                        selectedYear === "")
                  )
                  .map((documentItem) => (
                    <tr key={documentItem._id}>
                      <td>{formatDate(documentItem.articleDate)}</td>
                      <td>{documentItem.selectType}</td>
                      <td>{documentItem.descriptionID}</td>
                      <td>
                        <button
                          className="rounded border-0 btn btn-primary text-white"
                          onClick={() =>
                            window.open(documentItem.fileDocument.url, "_blank")
                          }
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          PDF
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          <div className={activeTab === 3 ? "show-content" : "content"}>
            <select
              id="LaporanKeuangan"
              name="jenisRapat"
              className="my-3"
              onFocus={(event) => {
                event.target.size = 0;
              }}
              onBlur={(event) => {
                event.target.size = 0;
              }}
              onChange={handleTypeFinance}
              value={selectedFinance}
            >
              <option value="Laporan Keuangan">Laporan Keuangan</option>
              <option value="Laporan Tahunan">Laporan Tahunan</option>
            </select>
            <select
              id="Tahun"
              name="Tahun"
              className="select"
              value={selectedYear}
              onChange={(event) => {
                setSelectedYear(event.target.value);
              }}
            >
              <option value="Tahun">Tahun</option>
              {uniqueYears.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>

            <Table className="table-disclosure mb-5">
              <thead className="text-primary">
                <tr>
                  <td>Tanggal</td>
                  <td>Tipe Dokumen</td>
                  <td>Deskripsi</td>
                  <td>Unduh</td>
                </tr>
              </thead>
              <tbody>
                {sortDocumentsByDate(document)
                  .filter(
                    (item) =>
                      item.selectType === selectedFinance &&
                      (selectedYear === "Tahun" ||
                        new Date(item.articleDate).getFullYear() ==
                          selectedYear ||
                        selectedYear === "")
                  )
                  .map((documentItem) => (
                    <tr key={documentItem._id}>
                      <td>{formatDate(documentItem.articleDate)}</td>
                      <td>{documentItem.selectType}</td>
                      <td>{documentItem.descriptionID}</td>
                      <td>
                        <button
                          className="rounded border-0 btn btn-primary text-white"
                          onClick={() =>
                            window.open(documentItem.fileDocument.url, "_blank")
                          }
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          PDF
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Container>
    </DesktopWrapper>
  );
}
export default InvestorPage;
