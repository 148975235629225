import React from "react";
import HeroSection from "../../../component/desktop/card/hero";
import HeroSection2 from "../../../component/desktop/card/hero2";
import DesktopWrapper from "../../../layout/desktop/DesktopWrapper";
import ListLocalExperience from "./components/list";

function LocalExperienceDesktopPage() {
  return (
    <DesktopWrapper>
      {/* <HeroSection active={1} /> */}
      <HeroSection2 active={1} />
      <ListLocalExperience />
    </DesktopWrapper>
  );
}
export default LocalExperienceDesktopPage;
