import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";

export default function PassangerForm({
  control,
  register,
  errors,
  setValue,
  getValues,
  dataCountryCode,
  dataSalutation,
}) {
  const { t } = useTranslation();
  const { fields, remove, insert, replace } = useFieldArray({
    control,
    name: "customers",
  });

  const changeSameCustomer = (data) => {
    if (data.target.checked) {
      let newData = {
        salutation: getValues("title"),
        firstName: getValues("firstName"),
        lastName: getValues("lastName"),
        type: "ADULT",
        idCard: "",
        bornDate: "",
      };
      setValue("customers.0", newData);
    }
  };

  return (
    <>
      {fields.map((item, index) => (
        <div key={index}>
          <div className="d-flex align-items-center justify-content-between bg-primary-2 p-2 rounded mb-2 mt-4">
            <div className="text-primary">
              Passanger {index + 1}: {item.type?.toUpperCase()}
            </div>
            {index === 0 && (
              <div className="d-flex align-items-center">
                <div className="pe-3 fw-light small">
                  {t("same_with_the_customer")}
                </div>
                <Form.Check
                  type="switch"
                  className="custom-switch xl"
                  onChange={changeSameCustomer}
                />
              </div>
            )}
          </div>
          <Row className="pt-3">
            <Col sm="12" md="2" lg="2" xl="2" className="mb-2">
              <Controller
                control={control}
                name={`customers.${index}.salutation`}
                render={({ field: { onChange, value, ref } }) => (
                  <ReactSelect
                    ref={ref}
                    className="select"
                    classNamePrefix="select"
                    placeholder="Title*"
                    isSearchable={false}
                    value={dataSalutation?.[item.type]?.find(
                      (c) => c.value === value
                    )}
                    onChange={(val) => onChange(val.value)}
                    options={dataSalutation?.[item.type]}
                  />
                )}
              />
              <small className="text-danger">
                {errors.customers?.[index]?.salutation?.message}
              </small>
            </Col>
            <Col sm="12" md="5" lg="5" xl="5" className="mb-2">
              <Form.Control
                className="control-input"
                placeholder="First Name*"
                defaultValue={`${item.firstName}`}
                {...register(`customers[${index}].firstName`)}
              />
              <small className="text-danger">
                {errors.customers?.[index]?.firstName?.message}
              </small>
            </Col>
            <Col sm="12" md="5" lg="5" xl="5" className="mb-2">
              <Form.Control
                className="control-input"
                placeholder="Last Name*"
                defaultValue={`${item.lastName}`}
                {...register(`customers[${index}].lastName`)}
              />
              <small className="text-danger">
                {errors.customers?.[index]?.lastName?.message}
              </small>
            </Col>
            <Col sm="12" md="2" lg="2" xl="2" className="mb-2"></Col>
            <Col sm="12" md="10" lg="10" xl="10" className="py-1 mb-2">
              <small className="fw-light text-grey">{t("as_on_id_card")}</small>
            </Col>
          </Row>
          <Row className="py-4">
            <Col sm="12" md="6" lg="6" xl="6" className="mb-2">
              <div className="text-primary">KTP/Paspor</div>
              <Form.Control
                className="control-input"
                placeholder="ID Card / Passport*"
                defaultValue={`${item.idCard}`}
                {...register(`customers[${index}].idCard`)}
              />

              <small className="text-danger">
                {errors.customers?.[index]?.idCard?.message}
              </small>
            </Col>
            <Col sm="12" md="6" lg="6" xl="6" className="mb-2">
              <div className="text-primary">Date Of Birth</div>
              <Form.Control
                className="control-input"
                placeholder="Born Date"
                type="date"
                defaultValue={`${item.bornDate}`}
                {...register(`customers[${index}].bornDate`)}
              />
            </Col>
          </Row>
        </div>
      ))}
    </>
  );
}
