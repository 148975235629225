import { BrowserRouter, Route, Routes } from "react-router-dom";
import DestinationPage from "../pages/destinations";
import HomePage from "../pages/home";
import LocalExperiencePage from "../pages/localExperiences";
import BookingFormPage from "../pages/localExperiences/bookingForm";
import DetailLocalExperiencePage from "../pages/localExperiences/detail";
import PaymentPage from "../pages/localExperiences/payment";
import ReceiptPage from "../pages/localExperiences/receipt";
import PartnersPage from "../pages/partners";
import CompletedPage from "../pages/partners/completed";
import PartnerChoosePage from "../pages/partners/partners";
import PartnerRegisterPage from "../pages/partners/register";
import ProfilePage from "../pages/profile";
import TravelAssistancePage from "../pages/travelAssistances";
import DetailTravelAssistancePage from "../pages/travelAssistances/detail";
import ScrollToTop from "../services/scrollToTop";
import AuthState from "../services/auth/state.js";
import UserState from "../services/user/state.js";
import PrivateRoute from "./PrivateRoutes";
import { ToastContainer } from "react-toastify";
import FlightPage from "../pages/flights";
import BookingFlightPage from "../pages/flights/bookingForm";
import PaymentFlightPage from "../pages/flights/payment";
import ReceiptFlightPage from "../pages/flights/receipt";
import TermsAndConditionsPage from "../pages/additional/termsAndConditions";
import PrivacyPolicyPage from "../pages/additional/privacyPolicy";
import InvestorPage from "../pages/additional/investor";
import AboutUsPage from "../pages/additional/aboutUs";
import LoginPage from "../pages/auth/login";
import RegisterPage from "../pages/auth/register";
import ResendEmailPage from "../pages/auth/resend";
import ResetPasswordPage from "../pages/auth/reset";
import ForgotPasswordPage from "../pages/auth/forgot";
import ColabExperiencesPages from "../pages/colabExperiences/index.js";
import Guide from "../pages/guide/index.js";
import GuideDetails from "../pages/guide/desktop/components/guideDetails.js";
import SailingKomodo from "../pages/additional/categoryPage.js";

const AppRoutes = () => {
  // const {currentUser} = useAuth()
  return (
    <AuthState>
      <UserState>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route>
              {/* <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} /> */}
              {/* {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )} */}
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/resend-email" element={<ResendEmailPage />} />
              <Route path="/forgot-password" element={<ResetPasswordPage />} />
              <Route
                path="/send-reset-password"
                element={<ForgotPasswordPage />}
              />
              <Route
                path="/verification-success"
                element={<HomePage type="verification-success" />}
              />
              {/* <Route
                path="/forgot-password"
                element={<HomePage type="forgot-password" />}
              /> */}
              {/* <Route
                path="/send-reset-password"
                element={<HomePage type="send-reset-password" />}
              /> */}
              <Route path="destination/:id" element={<DestinationPage />} />

              {/* LOCAL EXPERIENCE */}
              <Route
                path="local-experience"
                element={<LocalExperiencePage />}
              />
              <Route
                path="local-experience/detail/:id"
                element={<DetailLocalExperiencePage />}
              />
              <Route
                path="local-experience/booking/:id"
                element={<BookingFormPage />}
              />
              <Route
                path="local-experience/payment/:id"
                element={<PaymentPage />}
              />
              <Route
                path="local-experience/receipt/:id"
                element={<ReceiptPage />}
              />
              <Route path="sailingkomodo" element={<SailingKomodo />} />

              {/* TRAVEL ASISTANCE */}
              <Route
                path="travel-assistance"
                element={<TravelAssistancePage />}
              />
              <Route
                path="travel-assistance/detail/:id"
                element={<DetailTravelAssistancePage />}
              />
              <Route
                path="travel-assistance/booking/:id"
                element={<BookingFormPage />}
              />
              <Route
                path="travel-assistance/payment/:id"
                element={<PaymentPage />}
              />
              <Route
                path="travel-assistance/receipt/:id"
                element={<ReceiptPage />}
              />

              {/* PARTNERS */}
              <Route path="partners" element={<PartnersPage />} />
              <Route path="partners/register" element={<PartnerChoosePage />} />
              <Route
                path="partners/register/:type"
                element={<PartnerRegisterPage />}
              />
              <Route path="partners/completed" element={<CompletedPage />} />

              {/* FLIGHT */}
              <Route path="flights" element={<FlightPage />} />

              {/* ADDITIONAL PAGE */}
              <Route
                path="terms-and-conditions"
                element={<TermsAndConditionsPage />}
              />
              <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="investor" element={<InvestorPage />} />
              <Route path="about-us" element={<AboutUsPage />} />
              <Route
                path="/local-experience/:category"
                element={<ColabExperiencesPages />}
              />
              <Route path="guide" element={<Guide />} />
              <Route path="guide/:id" element={<GuideDetails />} />

              <Route element={<PrivateRoute />}>
                {/* PROFILE */}
                <Route path="flights/booking" element={<BookingFlightPage />} />
                <Route path="flights/payment" element={<PaymentFlightPage />} />
                <Route
                  path="flights/receipt/:id"
                  element={<ReceiptFlightPage />}
                />
                <Route path="profile" element={<ProfilePage />} />
                <Route path="order" element={<ProfilePage />} />
                <Route path="order-flight" element={<ProfilePage />} />
                <Route path="order/detail/:id" element={<ProfilePage />} />
                <Route
                  path="order-flight/detail/:id"
                  element={<ProfilePage />}
                />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </UserState>
    </AuthState>
  );
};

export default AppRoutes;
