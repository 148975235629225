import React from "react";
import { BrowserView } from "react-device-detect";
import PartnerRegisterDesktopPage from "./desktop/partnerRegister";

function PartnerRegisterPage() {
  return (
    // <BrowserView>
      <PartnerRegisterDesktopPage />
    // </BrowserView>
  );
}
export default PartnerRegisterPage;
