import React from "react";
import { BrowserView } from "react-device-detect";
import DetailTravelAssistanceDesktopPage from "./desktop/detail";

function DetailTravelAssistancePage() {
  return (
    // <BrowserView>
      <DetailTravelAssistanceDesktopPage />
    // </BrowserView>
  );
}
export default DetailTravelAssistancePage;
