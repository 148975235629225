import React, { useContext, useEffect, useState } from "react";
import DesktopWrapper from "../../../layout/desktop/DesktopWrapper";
import HeroSection2 from "../../../component/desktop/card/hero2";
import Section1 from "./components/section1";
import Section2 from "./components/section2";
import Section4 from "./components/section4";
import Section5 from "./components/section5";
import SectionGlobal from "../../../component/desktop/card/sectionProduct";
import UserContext from "../../../services/user";
import { toast } from "react-toastify";
import ForgetPasswordModal from "../../../component/desktop/modal/forgetPasswordModal";
import ResetPasswordModal from "../../../component/desktop/modal/resetPasswordModal";
import { SkeletonCustom } from "../../../component/global/skeleton";
import Section1B from "./components/section1b";
import Section1C from "./components/section1c";
import { useTranslation } from "react-i18next";

function HomeDesktopPage(props) {
  const { t } = useTranslation();
  const { loading, addMaster, getAllMaster } = useContext(UserContext);
  const [dataRecommended, setDataRecommended] = useState([]);
  const [dataFooter, setDataFooter] = useState([]);
  const [dataLEFooter, setDataLEFooter] = useState([null, null, null, null]);
  const [categoryDesaWisata, setCategoryDesaWisata] = useState([]);
  const [category, setCategory] = useState([]);
  const [dataRecommendedWeek, setDataRecommendedWeek] = useState([]);
  const [desaWisata, setDesaWisata] = useState([]);
  const [filter, setFilter] = useState({
    type: ["open trip", "private trip"],
    start_price: 0,
    end_price: 999999999,
    location: [],
    search: "",
  });
  const [show, setShow] = useState({
    type: "",
    open: false,
  });

  const getDataFooter = async () => {
    const newData = await getAllMaster(`menu/footers`);
    if (newData) {
      setDataFooter(newData?.data);
      newData?.data?.map((item, index) => {
        getData(12, setDataLEFooter, [item?.name], index);
      });
    }
  };

  const getDataBaktiBca = async () => {
    getData(12, setDesaWisata, ["Desa Wisata"]);
  };

  const getDataRecommendedWeek = async () => {
    getData(12, setDataRecommendedWeek, ["Recommended This Week"]);
  };

  const getData = async (pageLimit, setData, tag, index = null) => {
    filter.tag = tag;
    const newData = await addMaster(
      `product/filter-products?page=1&limit=${pageLimit}`,
      filter
    );
    if (newData) {
      if (index !== null) {
        setData((items) => [
          ...items.slice(0, index),
          {
            ...items[index],
            data: newData?.data,
          },
          ...items.slice(index + 1),
        ]);
      } else {
        setData(newData?.data);
      }
    }
  };

  useEffect(() => {
    if (categoryDesaWisata?.length > 0) {
      getData(4, setDesaWisata, categoryDesaWisata);
    }
  }, [categoryDesaWisata]);

  useEffect(() => {
    if (category?.length > 0) {
      getData(40, setDataRecommended, category);
    }
  }, [category]);

  useEffect(() => {
    getDataFooter();
    getDataBaktiBca();
    getDataRecommendedWeek();
  }, []);

  useEffect(() => {
    if (props.type === "verification-success") {
      toast.success("Verifikasi email Anda berhasil!. Silahkan Login.");
    } else if (props.type === "forgot-password") {
      setShow({
        type: "resetPassword",
        open: true,
      });
    } else if (props.type === "send-reset-password") {
      setShow({
        type: "forgetPassword",
        open: true,
      });
    }
  }, [props.type]);

  return (
    <DesktopWrapper>
      {/* <HeroSection /> */}
      <HeroSection2 />
      <Section1C
        setCategoryDesaWisata={setCategoryDesaWisata}
        data={desaWisata}
      />
      <Section1 />
      <Section1B loading={loading} data={dataRecommendedWeek} />

      {loading ? (
        <SkeletonCustom height="300px" />
      ) : (
        <Section2
          setCategory={setCategory}
          data={dataRecommended}
          type={t("recommended_local_experience")}
        />
      )}
      {/* <Section3 /> */}
      <Section4 />

      {loading ? (
        <SkeletonCustom height="300px" />
      ) : (
        dataFooter?.map((item, index) => (
          <SectionGlobal
            data={dataLEFooter?.[index]?.data}
            type={item?.name}
            key={index}
          />
        ))
      )}

      <Section5 />
      <ResetPasswordModal setShow={setShow} show={show} />
      <ForgetPasswordModal setShow={setShow} show={show} />
    </DesktopWrapper>
  );
}
export default HomeDesktopPage;
