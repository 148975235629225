import i18next from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      search_trip: "Search trip",
      become_partner: "Become Partner",
      login: "Login",
      register: "Register",
      language: "EN",
      profile: "Profile",
      choose_departure: "Choose a Departure",
      choose_destination: "Choose a Destination",
      guest: "Guest",
      recommended_this_week: "Recommended This Week",
      see_all: "See all",
      recommended_local_experience: "Recommended Local Experience",
      most_popular_destination: "Most Popular Destination",
      tourist_village: "Tourist Village",
      with_a_focus_on_quality_and_customer_satisfaction:
        "With a focus on quality and customer satisfaction, our industry partner sets the standard for excellence in their field.",
      about_us: "About us",
      investor: "Investor",
      terms_conditions: "Terms & Conditions",
      privacy_policy: "Privacy Policy",
      find_us: "Find Us",
      enjoy_a_variety_of_special_offers_for_pigijo_users:
        "Enjoy a variety of special offers for Pigijo users.",
      forget_password: "Forget Password?",
      or: "or",
      or_login_with: "login with",
      dont_have_an_account: "don't have an account?",
      register_now: "Register Now",
      resend_verification_email: "Resend Verification Email",
      register_now_for_convenience_in_booking_tour_packages:
        "Register now for convenience in booking tour packages.",
      email_address: "Email Address",
      password: "Password",
      confirm_password: "Confirm Password",
      i_agree_with: "I agree with",
      use_pigijo: "use Pigijo",
      already_have_an_account: "already have an account?",
      welcome_kawanjo:
        "Welcome Kawanjo! Before we start an Exciting Adventure, first open your email to complete Verification. Verify Now",
      about: "About",
      results_for_local_experiences: "Results for Local Experiences in",
      sort_by: "Sort by",
      price_range: "Price Range",
      location: "Location",
      airline: "Airline",
      time_to_go: "Time to Go",
      time_arrives: "Time Arrive",
      whats_included: "What’s included?",
      important_information: "Important information",
      cancellation_policy: "Cancellation Policy",
      guest_requirements: "Guest Requirements",
      choose_date: "Choose Date",
      voucher_code: "Voucher Code",
      order_summary: "Order Summary",
      total_price: "Total Price",
      cancel: "Cancel",
      book_now: "Book Now",
      customer_data: "Customer Data",
      title: "Title",
      first_name: "First Name",
      last_name: "Last Name",
      phone_number: "Phone Number",
      as_on_id_card:
        "As on ID Card/passport/driving license (without degree or special characters)",
      eticket_will_be_sent_to_this_email_address:
        "E-ticket will be sent to this Email Address",
      visitor_data: "Visitor Data",
      same_with_the_customer: "Same with the customer",
      search: "Search",
      change_search: "Change Search",
      destination: "Destination",
      see_destination: "See Destination",
      products: "Products",
      information: "Information",
      contact_us: "Contact Us",
      our_office: "Our Office",
      start_from: "Start from",
      schedule: "Schedule",
      itinerary: "Itinerary",
      meeting_point: "Meeting Point",
      additional_info: "Additional Info",
      can_not_be_cancelled: "can not be cancelled",
      up_to: "Up to",
      person: "Person",
      days: "Days",
      enter_voucher_code: "Enter Voucher Code",
      select_number_of_guests: "Select number of guests",
      date_of_departure: "Date of Departure",
      trip_duration: "Trip Duration",
      guests: " Guests",
      booking_form: "Booking Form",
      continue_to_payment: "Continue to Payment",
      adult: "Adult",
      payment_confirmation: "Payment Confirmation",
      booking_confirmation: "Booking Confirmation",
      booking: "Booking",
      ticket_information: "Ticket Information",
      customer_information: "Customer Information",
      price: "Price",
      ticket_price: "Ticket Price",
      other_costs: "Other Costs",
      total_payment: "Total Payment",
      tax: "Tax",
      discount: "Discount",
      wholesales: "Wholesales",
      included: "Included",
      successful_payment: "Successful Payment",
      successful_booking: "Successful Booking",
      date_transaction: "Date Transaction",
      payment_method: "Payment Method",
      va_number: "VA Number",
      price_paid: "Price Paid",
      view_order_details: "View order details",
      back_to_homepage: "Back to Homepage",
      order: "Order",
      order_flight: "Order Flight",
      logout: "Logout",
      see_detail: "See Detail",
      back_to_active_order: "back to active order",
      see_full_details: "See Full Details",
      trip_details: "Trip Details",
      participant_details: "Participant Details",
      admin_fee: "Admin Fee",
      payment_details: "Payment Details",
      reschedule_policy: "Reschedule Policy",
      flight_details: "Flight Details",
      passanger_details: "Passanger Details",
      booking_code: "Booking Code",
      booking_id: "Booking ID",
      update_profile: "Update Profile",
      update_password: "Update Password",
      old_password: "Old Password",
      new_password: "New Password",
      retype_new_password: "Retype New Password",
      save: "Save",
      become_our_partner: "Become Our Partner",
      otp_code: "OTP Code",
      pin_code: "PIN Code",
      pigijo_collaboration: "Pigijo Collaboration with Bakti BCA",
      bahasa: "Select Language",
      facility: "Facilities",
      baggage: "Baggage",
      meal: "In-flight Meal",
    },
  },
  id: {
    translation: {
      search_trip: "Cari trip",
      become_partner: "Jadi Mitra",
      login: "Masuk",
      register: "Daftar",
      language: "ID",
      profile: "Profil",
      choose_departure: "Pilih Asal",
      choose_destination: "Pilih Tujuan",
      guest: "Tamu",
      recommended_this_week: "Rekomendasi Minggu Ini",
      see_all: "Lihat Semua",
      recommended_local_experience: "Rekomendasi Local Experience",
      most_popular_destination: "Destinasi Terpopuler",
      tourist_village: "Desa Wisata",
      with_a_focus_on_quality_and_customer_satisfaction:
        "Dengan fokus pada kualitas dan kepuasan pelanggan, mitra industri kami menetapkan standar keunggulan di bidangnya.",
      about_us: "Tentang kami",
      investor: "Investor",
      terms_conditions: "Syarat dan Ketentuan",
      privacy_policy: "Kebijakan Privasi",
      find_us: "Follow kami di",
      enjoy_a_variety_of_special_offers_for_pigijo_users:
        "Nikmati berbagai penawaran khusus untuk pengguna Pigijo.",
      forget_password: "Lupa Password?",
      or: "atau",
      or_login_with: "daftar dengan",
      dont_have_an_account: "belum punya akun?",
      register_now: "Daftar Sekarang",
      resend_verification_email: "kirim ulang email verifikasi",
      register_now_for_convenience_in_booking_tour_packages:
        "Daftar sekarang untuk memudahkan pemesanan paket wisata.",
      email_address: "Alamat Email",
      password: "Kata Sandi",
      confirm_password: "Konfirmasi Kata Sandi",
      i_agree_with: "Saya setuju dengan",
      use_pigijo: "Pigijo",
      already_have_an_account: "sudah mempunyai akun?",
      welcome_kawanjo:
        "Selamat Kawanjo! Sebelum kita memulai petualangan yang asik, buka email terlebih dahulu untuk melengkapi verifikasi. Verifikasi Sekarang",
      about: "Tentang",
      results_for_local_experiences: "Hasil Pencarian",
      sort_by: "Sortir dengan",
      price_range: "Rentang Harga",
      location: "Lokasi",
      airline: "Maskapai",
      time_to_go: "Waktu Pergi",
      time_arrives: "Waktu Tiba",
      whats_included: "Sudah Termasuk",
      important_information: "Informasi Penting",
      cancellation_policy: "Kebijakan Pembatalan",
      guest_requirements: "Persyaratan Tamu",
      choose_date: "Pilih Tanggal",
      voucher_code: "Kode Voucher",
      order_summary: "Ringkasan Pesanan",
      total_price: "Total Harga",
      cancel: "Batalkan",
      book_now: "Pesan Sekarang",
      customer_data: "Data Pelanggan",
      title: "Titel",
      first_name: "Nama Depan",
      last_name: "Nama Belakang",
      phone_number: "Nomor Telepon",
      as_on_id_card:
        "Sesuai dengan Kartu Identitas/Paspor/SIM (tanpa gelar atau tanda baca)",
      eticket_will_be_sent_to_this_email_address:
        "Tiket akan dikirimkan ke email",
      visitor_data: "Data Tamu",
      same_with_the_customer: "Sama dengan data pelanggan",
      search: "Cari",
      change_search: "Ubah Pencarian",
      destination: "Tujuan Wisata",
      see_destination: "Lihat Tujuan Wisata",
      products: "Produk",
      information: "Informasi",
      contact_us: "Hubungi Kami",
      our_office: "Lokasi Kantor",
      start_from: "Mulai dari",
      schedule: "Jadwal",
      itinerary: "Rencana perjalanan",
      meeting_point: "Titik Kumpul",
      additional_info: "Informasi Tambahan",
      can_not_be_cancelled: "tidak dapat dibatalkan",
      up_to: "sampai",
      person: "orang",
      days: "Hari",
      enter_voucher_code: "Masukkan Kode Voucher",
      select_number_of_guests: "Pilih jumlah tamu",
      date_of_departure: "Tanggal Keberangkatan",
      trip_duration: "Durasi Perjalanan",
      guests: " Tamu",
      booking_form: "Formulir Pemesanan",
      continue_to_payment: "Lanjut ke Pembayaran",
      adult: "Dewasa",
      payment_confirmation: "Konfirmasi Pembayaran",
      booking_confirmation: "Konfirmasi Pemesanan",
      booking: "Pemesanan",
      ticket_information: "Informasi Tiket",
      customer_information: "Informasi Pelanggan",
      price: "Harga",
      ticket_price: "Harga Tiket",
      other_costs: "Biaya Lainnya",
      total_payment: "Total Pembayaran",
      tax: "Pajak",
      discount: "Diskon",
      wholesales: "Grosir",
      included: "Sudah Termasuk",
      successful_payment: "Pembayaran Sukses",
      successful_booking: "Pemesanan Sukses",
      date_transaction: "Tanggal Transaksi",
      payment_method: "Metode Pembayaran",
      va_number: "Nomor VA",
      price_paid: "Harga Bayar",
      view_order_details: "Lihat Detail Pemesanan",
      back_to_homepage: "Kembali ke Halaman Utama",
      order: "Pemesanan",
      order_flight: "Pemesanan Pesawat",
      logout: "Keluar",
      see_detail: "Lihat Detail",
      back_to_active_order: "kembali ke pemesanan aktif",
      see_full_details: "Lihat Detail",
      trip_details: "Detail Pemesanan",
      participant_details: "Detail Partisipan",
      admin_fee: "Biaya Admin",
      payment_details: "Detail Pembayaran",
      reschedule_policy: "Kebijakan Pengembalian",
      flight_details: "Detail Penerbangan",
      passanger_details: "Detail Penumpang",
      booking_code: "Kode Pemesanan",
      booking_id: "ID Pemesanan",
      update_profile: "Ubah Profil",
      update_password: "Ubah Kata Sandi",
      old_password: "Kata Sandi Lama",
      new_password: "Kata Sandi Baru",
      retype_new_password: "Ulangi Kata Sandi Baru",
      save: "Simpan",
      become_our_partner: "Gabung Mitra",
      otp_code: "Kode OTP",
      pin_code: "Kode PIN",
      pigijo_collaboration: "Pigijo Bekerja Sama dengan Bakti BCA",
      bahasa: "Pilih Bahasa",
      facility: "Fasilitas",
      baggage: "Bagasi",
      meal: "Makanan di pesawat",
    },
  },
};
i18next.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
  debug: true,
});
export default i18next;
