import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import DesktopWrapper from "../../../layout/desktop/DesktopWrapper";
import { useNavigate, useParams } from "react-router-dom";
import StepWizard from "react-step-wizard";
import UserContext from "../../../services/user";
import StepNavigation from "./components/stepNavigation";
import Step1Company from "./components/company/step1";
import Step2Company from "./components/company/step2";
import Step3Company from "./components/company/step3";
import Step4Company from "./components/company/step4";
import Step5Company from "./components/company/step5";
import Step6Company from "./components/company/step6";
import Step1Individual from "./components/individual/step1";
import Step2Individual from "./components/individual/step2";
import Step3Individual from "./components/individual/step3";
import Step4Individual from "./components/individual/step4";
import Step5Individual from "./components/individual/step5";
import Step1TravelAssistance from "./components/travelAssistance/step1";
import Step2TravelAssistance from "./components/travelAssistance/step2";
import Step3TravelAssistance from "./components/travelAssistance/step3";
import Step4TravelAssistance from "./components/travelAssistance/step4";
import Step5TravelAssistance from "./components/travelAssistance/step5";
import Step6TravelAssistance from "./components/travelAssistance/step6";
import Step7TravelAssistance from "./components/travelAssistance/step7";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function PartnerRegisterDesktopPage() {
  const { t } = useTranslation();
  const { data, getAllMaster, addMaster, addMasterForm } =
    useContext(UserContext);
  let { type } = useParams();
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [province, setProvince] = useState([]);
  const [bank, setBank] = useState([
    {
      value: "BCA",
      label: "BCA",
    },
    {
      value: "BNI",
      label: "BNI",
    },
  ]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitForm = () => {
    handleShow();
  };

  const onSubmit = async () => {
    let url = "user/register-";
    if (type === "individual" || type === "company") {
      url = url + "partner-" + type;
      let response = await addMaster(url, data, "Register Success");
      if (!response?.err && !response?.error) {
        handleClose();
        navigate("/partners/completed");
      } else {
        toast.error(response?.error);
      }
    } else {
      url = url + "travel-assistance";
      let response = await addMasterForm(url, data, "Register Success");
      if (!response?.err && !response?.error) {
        handleClose();
        navigate("/partners/completed");
      } else {
        toast.error(response?.error);
      }
    }
  };

  const getData = async () => {
    const newData = await getAllMaster(`address/province`);
    if (newData) {
      let dataProvinces = [
        {
          value: "",
          label: "- Select Province -",
        },
      ];
      dataProvinces.push(
        ...newData?.data?.map((item) => ({
          value: item.province_id,
          label: item.province,
        }))
      );

      setProvince(dataProvinces);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <DesktopWrapper>
      <Container>
        <div className="text-center mt-5 mb-4">
          <div className="h5">Complete Your Data</div>
          <div className="small text-muted fw-light">
            Please fill the data below correctly.
          </div>
        </div>
        {type === "company" && (
          <StepWizard
            nav={<StepNavigation type={type} />}
            className="mb-5 wizard-form"
          >
            <Step1Company dataProvince={province} />
            <Step2Company />
            <Step3Company />
            <Step4Company dataBank={bank} />
            <Step5Company />
            <Step6Company submit={submitForm} />
          </StepWizard>
        )}
        {type === "individual" && (
          <StepWizard
            nav={<StepNavigation type={type} />}
            className="mb-5 wizard-form"
          >
            <Step1Individual dataProvince={province} />
            <Step2Individual />
            <Step3Individual dataBank={bank} />
            <Step4Individual />
            <Step5Individual submit={submitForm} />
          </StepWizard>
        )}
        {type === "travel-assistance" && (
          <StepWizard
            nav={<StepNavigation type={type} />}
            className="mb-5 wizard-form"
          >
            <Step1TravelAssistance />
            <Step2TravelAssistance dataProvince={province} />
            <Step3TravelAssistance />
            <Step4TravelAssistance />
            <Step5TravelAssistance />
            <Step6TravelAssistance />
            <Step7TravelAssistance submit={submitForm} />
          </StepWizard>
        )}
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Body className="px-5 py-4">
            <div className="fw-bold h5 pb-3">Statement</div>
            <div className="text-muted fw-light pb-3">
              I declare that the data that I have filled in above is valid, I
              have checked the correctness of the data.
            </div>
            <div className="mt-4 w-50 d-flex justify-content-end text-end ms-auto">
              <Button
                variant="outline-primary"
                className="w-100"
                onClick={handleClose}
              >
                {t("cancel")}
              </Button>
              <Button
                variant="primary"
                className="ms-3 text-white w-100"
                onClick={() => onSubmit()}
              >
                Agree
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </Container>
    </DesktopWrapper>
  );
}
export default PartnerRegisterDesktopPage;
