import React from "react";
import { BrowserView } from "react-device-detect";
import BookingFormDesktopPage from "./desktop/bookingForm";

function BookingFormPage() {
  return (
    // <BrowserView>
      <BookingFormDesktopPage />
    // </BrowserView>
  );
}
export default BookingFormPage;
