import React from "react";
import { BrowserView } from "react-device-detect";
import LocalExperienceDesktopPage from "./desktop";

function LocalExperiencePage() {
  return (
    // <BrowserView>
      <LocalExperienceDesktopPage />
    // </BrowserView>
  );
}
export default LocalExperiencePage;
