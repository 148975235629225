import React, { useState } from "react";

function StepNavigation(params) {
  const [stepNavCompany, setStepNavCompany] = useState({
    individual: [
      "Personal Information",
      "Personal Contact",
      "Account Data",
      "Customer Service",
      "Ordering Terms",
    ],
    company: [
      "Company Information",
      "Company Details",
      "Person Responsible",
      "Company Account Data",
      "Company Costumer Service",
      "Ordering Terms",
    ],
    "travel-assistance": [
      "Profile",
      "Domicile Address",
      "Certificate/License",
      "Skill/Expertise",
      "Language",
      "Community",
      "Ordering Terms",
    ],
  });

  return (
    <div className="my-5 d-flex step-nav">
      {(params.type === "individual" ||
        params.type === "company" ||
        params.type === "travel-assistance") &&
        stepNavCompany[params.type]?.map((item, index) => (
          <div
            key={index}
            className={
              "px-2 text-center container-step-nav " +
              (index + 1 <= params.currentStep && "active")
            }
          >
            <div className={
                "number " + (index + 1 === params.currentStep && "active")
              }>{index + 1}</div>
            <div
              className={
                "small label " + (index + 1 === params.currentStep && "active")
              }
            >
              {item}
            </div>
          </div>
        ))}
    </div>
  );
}

export default StepNavigation;
