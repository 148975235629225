import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonCustom = ({ height, width, count, className }) => (
  <Skeleton width={width} height={height} count={count} className={className} />
);

const SkeletonImage = () => <Skeleton height="100%" width="100%" />;

export { SkeletonCustom, SkeletonImage };
