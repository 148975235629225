import React from "react";
import { BrowserView } from "react-device-detect";
import FlightDesktopPage from "./desktop";

function FlightPage() {
  return (
    // <BrowserView>
      <FlightDesktopPage />
    // </BrowserView>
  );
}
export default FlightPage;
