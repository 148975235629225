import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form, Image } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import ReactSelect from "react-select";
import FooterNavigation from "../footerNavigation";
import ImageIcon from "../../../../../assets/icons/image.svg";
import UserContext from "../../../../../services/user";

function Step3(params) {
  const { data, registerForm } = useContext(UserContext);
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": [] },
    maxFiles: 5,
    maxSize: 5000000,
    onDrop: (acceptedFiles) => {
      setFiles((currentFile) => [
        ...currentFile,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  const removeImage = (index) => {
    var array = [...files];
    array.splice(index, 1);
    setFiles(array);
  };

  const thumbs = files.map((file, index) => (
    <div className="dzu-previewContainer" key={index}>
      <Image src={file.preview} className="img-input" />
      <div className="dzu-previewStatusContainer">
        <Button
          size="sm"
          className="dzu-previewButton"
          onClick={() => removeImage(index)}
        >
          X
        </Button>
      </div>
    </div>
  ));

  const submitStep = async () => {
    const newData = Object.assign(data, { certificate: files });
    const response = await registerForm(newData);
    if (response) {
      params.nextStep();
    }
  };

  return (
    <Card className="card-step border-0 shadow-sm">
      <Card.Body>
        <div className="pb-3 text-center">
          Certificate of Expertise (Optional)
        </div>
        <div className="mb-3 p-5">
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <Image src={ImageIcon} />
            <p>Drag & drop files here, or click to select files</p>
            <div className="small text-grey">Max file size 1 MB</div>
          </div>
          <div className="dzu-preview">{thumbs}</div>
        </div>

        <FooterNavigation
          totalSteps={params.totalSteps}
          currentStep={params.currentStep}
          previousStep={params.previousStep}
          nextStep={submitStep}
          disabled={false}
          submit={params.submit}
        />
      </Card.Body>
    </Card>
  );
}

export default Step3;
