import React from "react";
import { Card } from "react-bootstrap";
import moment from "moment";

function FlightTab(params) {
  return (
    <Card className="border-0 shadow flight-tab">
      <Card.Body className="p-0">
        <div className="title">Your Flights</div>
        <div
          className={
            (params.type === 0 && "active") +
            " d-flex align-items-center flight-list"
          }
        >
          <div className="circle">1</div>
          <div className="ms-3">
            <div className="small text-grey mb-2">
              {moment(params?.data?.departdate).format("ddd, DD MMM YYYY")}
            </div>
            <div className="fw-normal">
              {params?.data?.fromcity} &#129058; {params?.data?.tocity}
            </div>
          </div>
        </div>
        {params.data?.returndate && (
          <div
            className={
              (params.type === 1 && "active") +
              " d-flex align-items-center flight-list"
            }
          >
            <div className="circle">2</div>
            <div className="ms-3">
              <div className="small text-grey mb-2">
                {moment(params?.data?.returndate).format("ddd, DD MMM YYYY")}
              </div>
              <div className="fw-normal">
                {params?.data?.tocity} &#129058; {params?.data?.fromcity}
              </div>
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

export default FlightTab;
