import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import DesktopWrapper from "../../../layout/desktop/DesktopWrapper";
import SampleTA from "../../../assets/images/partners/sampleTA.jpg";
import Star2Icon from "../../../assets/icons/star2.svg";
import ClockOutline2Icon from "../../../assets/icons/clockOutline2.svg";
import CertifiedIcon from "../../../assets/icons/certified.svg";
import LanguageIcon from "../../../assets/icons/language.svg";
import SectionGlobal from "../../../component/desktop/card/sectionProduct";
import UserContext from "../../../services/user";

function DetailTravelAssistanceDesktopPage() {
  const { loading, getMaster, addMaster } = useContext(UserContext);
  const [data, setData] = useState([]);
  const [dataLocalExperience, setDataLocalExperience] = useState([]);
  let { id } = useParams();

  const getData = async () => {
    const newData = await getMaster(`user/travel-assistance`, id);
    if (newData) {
      setData(newData.data);
      const newData1 = await addMaster(
        `product/filter-products?page=1&limit=4`,
        { owner: id }
      );
      if (newData1) {
        setDataLocalExperience(newData1.data);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <DesktopWrapper>
      <Container className="detail-product ta py-4">
        <Row>
          <Col sm="12" md="12" lg="4" xl="4">
            <Image
              src={data?.photoProfile?process.env.REACT_APP_FILE_URL+"/"+data?.photoProfile:SampleTA}
              className="ta-image rounded-4"
            />
          </Col>
          <Col sm="12" md="12" lg="8" xl="8" className="ps-5">
            <h4 className="fw-bold">
              {data?.firstName + " " + data?.lastName}
            </h4>
            <div className="d-flex align-items-center fw-light pb-2">
              <div className="text-muted">{data?.location}</div>
            </div>
            <p
              className="text-secondary fw-light py-3"
              style={{ lineHeight: "1.5rem" }}
            >
              {data?.travelAssistance?.shortDescription}
            </p>
            <Row className="fw-light gy-4">
              <Col sm="12" md="12" lg="12" xl="12">
                <div className="d-flex align-items-center">
                  <Image src={Star2Icon} />
                  <span className="ps-2 d-flex">
                    <div className="pe-1 ps-2">{data?.rating}</div>
                    <div>({data?.rating_count} Review)</div>
                  </span>
                </div>
              </Col>
              <Col sm="12" md="12" lg="12" xl="12">
                <div className="d-flex align-items-center">
                  <Image src={LanguageIcon} />
                  <span className="ps-2">
                    {data?.travelAssistance?.languages &&
                      data?.travelAssistance?.languages?.map((item, index) =>
                        index === data?.travelAssistance?.languages.length - 1
                          ? item
                          : item + ", "
                      )}
                  </span>
                </div>
              </Col>
              <Col sm="12" md="12" lg="12" xl="12">
                <div className="d-flex align-items-center">
                  <Image src={CertifiedIcon} />
                  <span className="ps-2">
                    {data?.travelAssistance?.certificate?.length > 0
                      ? "Pigijo Certified"
                      : "-"}
                  </span>
                </div>
              </Col>
              <Col sm="12" md="12" lg="12" xl="12">
                <div className="d-flex align-items-center">
                  <Image src={ClockOutline2Icon} />
                  <span className="ps-2">
                    Operational Hours : {data?.operational_hours || "-"}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm="12" md="12" lg="12" xl="12" className="my-5">
            <Card className="border-0 bg-light-grey">
              <Card.Body className="px-5">
                <h5 className="fw-bold pb-4">Interests & Skills {data?.firstName}</h5>
                <Row className="fw-light">
                  <Col>
                    <h5 className="text-primary fw-light pb-3">Interests</h5>
                    <ul>
                      {data?.travelAssistance?.interests?.map((item, index) => (
                        <li key={index} className="pb-3">
                          {item}
                        </li>
                      ))}
                    </ul>
                  </Col>
                  <Col>
                    <h5 className="text-primary fw-light pb-3">Skills</h5>
                    <ul>
                      {data?.travelAssistance?.skills?.map((item, index) => (
                        <li key={index} className="pb-3">
                          {item}
                        </li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <hr></hr>
          <SectionGlobal
            type={`Local Experience by ${data?.firstName}`}
            data={dataLocalExperience}
          />
        </Row>
      </Container>
    </DesktopWrapper>
  );
}
export default DetailTravelAssistanceDesktopPage;
