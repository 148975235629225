import React, { useContext, useEffect, useRef, useState } from "react";
import { Carousel, Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import UserContext from "../../../../services/user";
import { SkeletonCustom } from "../../../../component/global/skeleton";

const sideScroll = (element, speed, distance, step) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);
};

function Section1() {
  const { loading, getAllMaster } = useContext(UserContext);
  const [data, setData] = useState([]);
  const contentWrapper = useRef(null);

  const getData = async () => {
    const newData = await getAllMaster(`banner?page=1&limit=12&type=public`);
    if (newData) {
      setData(newData.data);
    }
  };

  const scroll = (scrollOffset) => {
    contentWrapper.current.scrollLeft += scrollOffset;
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const hasWindow = typeof window !== "undefined";
    const width = hasWindow ? window.innerWidth : null;
    if (width < 768 && contentWrapper?.current) {
      scroll(305);
    }
  }, [contentWrapper?.current]);

  return (
    <Container className="section1">
      {loading ? (
        <SkeletonCustom height="300px" />
      ) : (
        // <Carousel className="mb-5">
        <div className="group-all group-banner">
          <div
            className="btn-group prev-btn"
            onClick={() => sideScroll(contentWrapper.current, 10, 430, -10)}
          ></div>
          <div className="group-le" ref={contentWrapper}>
            {data &&
              data.map((item, index) => (
                <div key={index} className="mb-3 item-banner">
                  <Link
                    to={"/local-experience?banner=" + item._id}
                    rel="noreffer"
                  >
                    <Image
                      src={process.env.REACT_APP_FILE_URL + "/" + item.picture}
                      width="100%"
                      alt={item.metaTitle}
                      style={{ borderRadius: "10px" }}
                    />
                  </Link>
                </div>
              ))}
          </div>
          <div
            className="btn-group next-btn"
            onClick={() => sideScroll(contentWrapper.current, 10, 430, 10)}
          ></div>
        </div>
        // <Carousel.Item key={index} className="px-2">
        //   <Row className={"overflow-card align-items-center "+(data?.length>1&&("full"))}>
        //     {item &&
        //       item.map((subItem, subIndex) => (
        //         <Col
        //           sm="12"
        //           md="6"
        //           lg="4"
        //           xl="4"
        //           key={subIndex}
        //           className="px-2 mb-3"
        //         >
        //           <Link to={"/local-experience?banner="+subItem._id} rel="noreffer">
        //  <Image
        //    src={
        //      process.env.REACT_APP_FILE_URL +
        //      "/" +
        //      subItem.picture
        //    }
        //    width="100%"
        //    alt={subItem.metaTitle}
        //    style={{borderRadius:"10px"}}
        //  />
        //           </Link>
        //         </Col>
        //       ))}
        //   </Row>
        // </Carousel.Item>
        // ))
        // </Carousel>
      )}
      <hr></hr>
    </Container>
  );
}

export default Section1;
