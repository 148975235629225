import React, { useContext, useEffect, useState } from "react";
import { Badge, Button, Card, Image, Modal, Spinner } from "react-bootstrap";
import LocalExperienceIcon from "../../../../assets/icons/localExperience.svg";
import PersonOutlineIcon from "../../../../assets/icons/personOutline.svg";
import CalenderInIcon from "../../../../assets/icons/calenderIn.svg";
import PigijoIcon from "../../../../assets/Pigijo.svg";
import moment from "moment";
import { Link } from "react-router-dom";
import UserContext from "../../../../services/user";
import { useTranslation } from "react-i18next";

function OrderFlight(params) {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [dataDetail, setDataDetail] = useState([]);
  const [type, setType] = useState();
  const [indexs, setIndexs] = useState();
  const [modal, setModal] = useState({
    show: false,
    id: "",
    type: "",
    data: {},
  });
  const { loadingCheck, getAllMaster, getMaster, addMaster, downloadMaster } =
    useContext(UserContext);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(9999);

  const getData = async () => {
    const newData = await addMaster(
      `voltras/got-orders?page=${page}&limit=${pageLimit}`
    );
    if (newData) {
      setData(newData.data);
    }
  };

  const getDataDetail = async (id) => {
    const newData = await getMaster(`order`, id);
    if (newData) {
      setDataDetail(newData.data);
    }
  };

  const printTicket = async (id, index) => {
    setIndexs(index);
    setType("ticket");
    const newData = await downloadMaster(
      `voltras/e-ticket/${id}`,
      null,
      "e-ticket",
      "pdf"
    );
  };

  const printInsurance = async (id, index) => {
    setIndexs(index);
    setType("insurance");
    const newData = await downloadMaster(
      `voltras/print/${id}?type=PRINTINSURANCE`,
      null,
      "insurance",
      "pdf"
    );
  };

  // const cancelTicket = async () => {
  //   const newData = await getAllMaster(`voltras/cancel-flight/${modal.id}`);
  //   if (newData?.data) {
  //     toast.success("Cancel Booking Success");
  //   } else {
  //     toast.error("Cancel Booking Failed: " + newData?.err);
  //   }
  //   handleClose();
  //   getData();
  // };

  const handleClose = () => {
    setModal({ show: false, id: "", type: "", data: {} });
  };

  useEffect(() => {
    getData();
  }, []);

  const badgeBackground = (item) => {
    let color = "light-grey";

    if (item === "settlement" || item === "capture") {
      color = "light-success";
    } else if (item === "pending") {
      color = "light-info";
    } else if (item === "waiting_payment") {
      color = "light-warning";
    } else if (item === "Expired" || item === "deny") {
      color = "light-danger";
    }

    return color;
  };

  const badgeColor = (item) => {
    let color = "text-dark";

    if (item === "settlement" || item === "capture") {
      color = "text-success";
    } else if (item === "pending") {
      color = "text-info";
    } else if (item === "waiting_payment") {
      color = "text-warning";
    } else if (item === "Expired" || item === "deny") {
      color = "text-danger";
    }

    return color;
  };

  return data.length > 0 ? (
    <>
      {data &&
        data.map((item, index) => (
          <Card className="mb-3 border-0 shadow-sm" key={index}>
            <Card.Body>
              <div className="d-flex align-items-center mb-3">
                <Image src={LocalExperienceIcon} />
                <span className="small ps-2 fw-light">
                  {item?.type || "Flight"}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  {/* <Link to={`/order/detail/${item._id}`} className="h5 text-black text-decoration-none">
                  {item?.items?.length > 0 && item?.items[0].name}
                </Link> */}
                  <div className="text-grey fw-light mb-2">
                    {t("booking_id")}: {item.invoiceNumber}
                  </div>
                  <div className="d-flex fw-light small my-4 w-100">
                    <div>
                      <Image
                        src={PersonOutlineIcon}
                        style={{ maxHeight: "15px" }}
                      />
                      <span className="ps-2">
                        {item?.customers?.length} Passanger
                      </span>
                    </div>
                    <div className="ms-4">
                      <Image
                        src={CalenderInIcon}
                        style={{ maxHeight: "15px" }}
                      />
                      <span className="ps-2">
                        {moment(item.createdAt).format("ddd, DD MMMM YYYY")}
                      </span>
                    </div>
                    {/* <div className="ms-4">
                    <Image src={OrderIcon} style={{ maxHeight: "15px" }} />
                    <span className="ps-2">
                      {item.paymentMethod === "bank_transfer" &&
                        "VA: " + item?.va?.vaNumber}
                      {item.paymentMethod === "echannel" &&
                        "VA: " +
                          item?.bill?.billerCode +
                          " " +
                          item?.bill?.billKey}
                    </span>
                  </div> */}
                  </div>
                </div>
                <div>
                  {new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                  }).format(item?.grossAmount)}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <Badge
                  bg={badgeBackground(item.status)}
                  className={`px-4 py-2 ${badgeColor(item.status)}`}
                >
                  {item.status.toUpperCase()==="CAPTURE"?"SUCCESS":item.status.toUpperCase()}
                </Badge>
                <div>
                  {item?.items?.[0]?.pnrid && (item?.status === "settlement" || item?.status === "capture") && (
                    <>
                      {item?.items?.[0]?.withinsurance && (
                        <Button
                          className="text-white me-3"
                          size="sm"
                          variant="dark"
                          onClick={() => {
                            printInsurance(item?.items?.[0]?.pnrid, index);
                          }}
                          disabled={
                            loadingCheck &&
                            type === "insurance" &&
                            indexs === index
                          }
                        >
                          <div className="d-flex gap-1 align-items-center">
                            <div>Print Insurance</div>
                            {loadingCheck &&
                              type === "insurance" &&
                              indexs === index && (
                                <Spinner
                                  size="sm"
                                  animation="border"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </Spinner>
                              )}
                          </div>
                        </Button>
                      )}
                      <Button
                        className="text-white me-3"
                        size="sm"
                        variant="danger"
                        onClick={() => {
                          // setModal({
                          //   show: true,
                          //   type: "print-ticket",
                          //   id: item?._id,
                          // });
                          // getDataDetail(item?._id);
                          printTicket(item?._id, index);
                        }}
                        disabled={
                          loadingCheck && type === "ticket" && indexs === index
                        }
                      >
                        <div className="d-flex gap-1 align-items-center">
                          <div>Print Ticket {loadingCheck}</div>
                          {loadingCheck &&
                            type === "ticket" &&
                            indexs === index && (
                              <Spinner
                                size="sm"
                                animation="border"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </Spinner>
                            )}
                        </div>
                      </Button>
                    </>
                  )}
                  <Link to={"/order-flight/detail/" + item?._id}>
                    <Button className="text-white" size="sm" variant="primary">
                      Detail
                    </Button>
                  </Link>
                </div>
              </div>
            </Card.Body>
          </Card>
        ))}
      <Modal
        show={modal.show && modal.type === "print-ticket"}
        onHide={handleClose}
        centered
        className="e-ticket"
      >
        <Modal.Body className="h5 p-5 e-ticket">
          <div className="d-flex align-items-center justify-content-between e-ticket-header">
            <div className="e-ticket-header-type">E-Ticket</div>
            <Image className="e-ticket-header-logo" src={PigijoIcon} />
          </div>
          <div className=""></div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button
            variant="primary"
            className="text-white"
            onClick={printTicket}
          >
            Print
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Modal
        show={modal.show && modal.type === "delete"}
        onHide={handleClose}
        centered
      >
        <Modal.Body className="h5 pb-1">
          Are you sure you want to delete this data?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="text-white"
            onClick={cancelTicket}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  ) : (
    <Card className="border-0 shadow-sm mb-4">
      <Card.Body>
        <>No Transaction Data</>
      </Card.Body>
    </Card>
  );
}

export default OrderFlight;
