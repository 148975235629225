import AuthWrapper from "../../layout/desktop/AuthWrapper";
import { useContext, useEffect, useState } from "react";
import { Button, Form, Image } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ShowIcon from "../../assets/icons/show.svg";
import HideIcon from "../../assets/icons/hide.svg";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApiWithAuth from "../../config/api/ApiWithAuth";
import AuthContext from "../../services/auth";
import SocialMediaLogin from "./socialMediaLogin";
import { useTranslation } from "react-i18next";

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

function LoginPage(params) {
  const { t } = useTranslation();
  const { loading, login } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (values) => {
    let response = await login(values.email, values.password);
    if (response) {
      setTimeout(() => {
        ApiWithAuth.setToken(response?.accessToken?.token);
        if (location.state?.prev) {
          navigate(-1);
        } else {
          navigate("/");
        }
      }, 500);
    }
  };

  return (
    <AuthWrapper>
      <h4 className="mb-3">{t("login")}</h4>
      <h6 className="text-grey fw-light">
        {t("enjoy_a_variety_of_special_offers_for_pigijo_users")}
      </h6>
      <form className="form my-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4 text-start">
          <Form.Control
            type="email"
            {...register("email")}
            className="control-input mt-2"
            placeholder={t("email_address")}
          />
          <small className="text-danger">{errors.email?.message}</small>
        </div>
        <div className="mb-4 text-start">
          <div className="position-relative">
            <Form.Control
              type={showPassword ? "text" : "password"}
              {...register("password")}
              className="control-input mt-2"
              placeholder={t("password")}
            />
            <span
              className="password-icon"
              onClick={() => setShowPassword(showPassword ? false : true)}
            >
              <Image src={showPassword ? ShowIcon : HideIcon} />
            </span>
          </div>
          <small className="text-danger">{errors.password?.message}</small>
        </div>
        <Link
          to="/send-reset-password"
          className="text-decoration-none text-end"
        >
          <div role="button" className="text-primary mb-3">
            {t("forget_password")}
          </div>
        </Link>
        <div className="mt-2 mb-4 text-end">
          <a
            href={process.env.REACT_APP_ADMIN_URL + "/login-partner"}
            className="text-primary text-decoration-none"
          >
            Login as Partner / Travel Assistance?
          </a>
        </div>
        <Button
          variant="primary"
          className="text-white w-100 mt-1"
          type="submit"
          disabled={loading}
        >
          {t("login")}
        </Button>
        <SocialMediaLogin />
        <div className="mt-4 fw-light">
          {t("dont_have_an_account")}
          <Link
            to="/register"
            className="text-primary text-decoration-none fw-normal"
          >
            {" "}
            {t("register_now")}
          </Link>
        </div>
        <div className="mt-2 fw-light">
          {t("or")}{" "}
          <Link
            to="/resend-email"
            className="text-primary text-decoration-none fw-normal"
          >
            {t("resend_verification_email")}
          </Link>
        </div>
      </form>
    </AuthWrapper>
  );
}

export default LoginPage;
