import React from "react";
import HeroSection from "../../../component/desktop/card/hero";
import HeroSection2 from "../../../component/desktop/card/hero2";
import DesktopWrapper from "../../../layout/desktop/DesktopWrapper";
import ListFlight from "./components/list";

function FlightDesktopPage() {
  return (
    <DesktopWrapper>
      {/* <HeroSection active={3} /> */}
      <HeroSection2 active={2} />
      <ListFlight />
    </DesktopWrapper>
  );
}
export default FlightDesktopPage;
