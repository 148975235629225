import React from "react";
import { Container } from "react-bootstrap";
import DesktopWrapper from "../../layout/desktop/DesktopWrapper";

function PrivacyPolicyPage() {
  return (
    <DesktopWrapper>
      <Container className="additional-page" style={{ textAlign: "justify" }}>
        <h4 className="mt-5 mb-3">Privacy Policy</h4>
        <h6 className="mb-3 fw-light">Last Updated: January 2021</h6>
        <h6 className="mt-4">About Pigijo</h6>
        <p className="fw-lighter text-dark-grey">
          Welcome to the website pigijo.com. This website is owned, operated,
          and operated by PT Tourindo Guide Indonesia, Tbk. a limited liability
          company established under the laws of the Republic of Indonesia. We
          provide the Website and services available online through the Website.
          : www.pigijo.com, mobile website: m.pigijo.com or mobile application:
          pigijo for iOS and Android users, and various accesses, media, devices
          and other platforms (“Online Services”), either already available or
          will be available at a later time.
        </p>
        <h6 className="mt-4">What are the Pigijo.com Privacy Rules?</h6>
        <p className="fw-lighter text-dark-grey">
          This Privacy Policy helps and explains all the services we provide for
          you ("User") to use, both services that we operate alone and those
          that are operated through our services and / or partners. To maintain
          User trust in us, We will always maintain all the confidentiality
          contained in User's personal data, because we consider User privacy to
          be very important to us.In this privacy, we include an explanation of
          how we collect, use, disclose, process and protect User personal
          information and data that are our rules. rules ("Personal Data").
        </p>
        <h6 className="mt-4">What Information Do We Collect and Use?</h6>
        <p className="fw-lighter text-dark-grey">
          When a User accesses the Website and / or online services that we
          provide, we will collect all of the user's personal information and
          data with the following conditions:
        </p>
        <p className="fw-lighter text-dark-grey">
          <ol>
            <li>
              We will collect information about the computer or any media that
              the User uses, including the IP address, operating system, browser
              used, URL, page, geographic location and access time as well as
              other data related to the use of the User's computer ("IP
              Details").
            </li>
            <li>
              We will ask Users to fill in User personal data correctly,
              clearly, completely, accurately and not misleadingly, such as
              name, email address, telephone number, full address, information
              used for payment, credit card information ( credit card number and
              credit card validity period) and other data we need to make
              transactions through the Website and other online services that we
              provide so that users can take advantage of the services that
              users need. We are not responsible for any losses that may occur
              due to information and / or false, clear, complete, accurate and
              misleading data that the User provides;
            </li>
            <li>
              We may use User personal data and other information collected for
              the purpose of Social Media marketing using direct and open
              graphic techniques and for conventional digital marketing
              purposes, such as sending Users automatic newsletters by
              electronic mail to notify new product information, offers. special
              or other information that we think will be of interest to users.
            </li>
            <li>
              In using our services, the information that users provide us can
              use and provide to third parties who cooperate with us, as far as
              for the benefit of transactions and use of our services.
            </li>
            <li>
              We can use all the information we receive to protect ourselves
              against all claims and applicable laws related to the use of
              services and violations that users commit to our website on all
              the provisions as set out in the terms of service pigijo.com and
              usage guidelines Our products and services, including without
              limitation if needed by order of the Court in the legal process;
            </li>
            <li>
              Users are responsible for the confidentiality of User personal
              information and data, including being responsible for all access
              and use of the Website using passwords and accounts and tokens
              that Users have that are used by anyone, either with the user's
              permission or not with the user's permission. For the security of
              User's confidential data, we strongly recommend that users keep
              accounts and passwords that users have as good as possible and /
              or make periodic changes to passwords. Any unauthorized use and
              without the knowledge and permission of the User is the
              responsibility of the User himself and us. is not responsible for
              any losses incurred as a result of the user's negligence.
            </li>
            <li>
              The user must immediately notify us of the use of a password or
              account without the user's permission or all forms of breaches or
              threats of security breaches on this website.
            </li>
            <li>
              The user has the right to change and or delete the address data
              that the User has provided and has stored in our system by
              contacting our Customer Service.
            </li>
          </ol>
        </p>
        <h6 className="mt-4">Newsletter</h6>
        <p className="fw-lighter text-dark-grey">
          Newsletter is a means that we provide to facilitate the process of
          communicating and providing information on the provision of our
          products and services as well as other information that we think will
          be of interest to users. Newsletters that we send will be received
          automatically and periodically by Users via email. has been
          registered, after the User has finished making orders and / or a
          personal account on the website pigijo.com. Users can opt-out of this
          feature in an easy and free way, via manage subscriptions in the
          profile menu or by using the "Unsubcribe" link in the email you
          receive.
        </p>
        <h6 className="mt-4">Privacy Policy Changes</h6>
        <p className="fw-lighter text-dark-grey">
          We reserve the right to make changes, additions and or subtractions of
          all or part of the provisions of this privacy rule from time to time
          without prior notice and we will notify all changes that we make
          through the Website. We hope that Users can periodically check about
          this policy. By accessing and using our services, we immediately
          assume that the user understands and agrees to all the privacy
          policies listed on our website at that time.
        </p>
        <h6 className="mt-4">FAQ</h6>
        <p className="fw-lighter text-dark-grey">
          If the User has questions or requires further information regarding
          the User's order, the User can contact our Customer Service or can
          send an email to the number and address as listed on the Website.
        </p>
      </Container>
    </DesktopWrapper>
  );
}
export default PrivacyPolicyPage;
