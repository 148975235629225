import React from "react";
import { BrowserView } from "react-device-detect";
import HomeDesktopPage from "./desktop";

function HomePage(props) {
  return (
    // <BrowserView>
      <HomeDesktopPage type={props.type} />
    // </BrowserView>
  );
}
export default HomePage;
