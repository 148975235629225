import React, { useReducer } from "react";
import { toast } from "react-toastify";
import ApiWithAuth from "../../config/api/ApiWithAuth";
import AssetContext from "./index";
import AssetReducer from "./reducer";
import {
  GET_ALL,
  GET,
  ADD,
  UPDATE,
  DELETE,
  BOOK,
  PAYMENT,
  REGISTER,
} from "./types";

const MasterState = ({ children }) => {
  const initialState = {
    data: [],
    loading: true,
    loadingCheck: true,
  };

  const [state, dispatch] = useReducer(AssetReducer, initialState);

  const book = async (
    startDate,
    qty,
    _id,
    wholesalesDiscount,
    campaignDiscount,
    totalPrice,
    voucherCode
  ) => {
    try {
      const data = {
        qty: qty,
        _id: _id,
        startDate: startDate,
        wholesalesDiscount: wholesalesDiscount,
        campaignDiscount: campaignDiscount,
        totalPrice: totalPrice,
        voucherCode: voucherCode
      };
      dispatch({ type: BOOK, payload: data });
      return data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const payment = async (data) => {
    try {
      dispatch({ type: PAYMENT, payload: data });
      return data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const registerForm = async (data) => {
    try {
      dispatch({ type: REGISTER, payload: data });
      return data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const getAllMaster = async (path, parameter) => {
    try {
      const data = await ApiWithAuth.getDataWithAuth(`/${path}`, parameter);
      if (data?.data?.data) {
        // dispatch({ type: GET_ALL, payload: data?.data?.data });
      } else {
        toast.error(data?.data?.error);
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const getMaster = async (path, id) => {
    try {
      const data = await ApiWithAuth.getDataWithAuth(`/${path}/${id}`);
      if (data?.data?.data) {
        dispatch({ type: GET, payload: data?.data?.data });
      } else {
        toast.error(data?.data?.error);
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const addMasterNew = async (path, body, message) => {
    try {
      const data = await ApiWithAuth.postDataWithAuth(
        `/${path}`,
        JSON.stringify(body)
      );
      if (data?.data?.statusCode === 200) {
        if (message) {
          toast.success(message);
        }
      } else {
        toast.error(JSON.stringify(data?.data?.err));
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const addMaster = async (path, body, message) => {
    try {
      const data = await ApiWithAuth.postDataWithAuth(
        `/${path}`,
        JSON.stringify(body)
      );
      if (data?.data?.statusCode === 200) {
        dispatch({ type: ADD });
        if (message) {
          toast.success(message);
        }
      } else {
        toast.error(JSON.stringify(data?.data?.err));
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const loadingAction = () => {
    dispatch({ type: "loading" });
  };

  const addMasterForm = async (path, body, message) => {
    try {
      const data = await ApiWithAuth.postDataFormWithAuth(`/${path}`, body);
      if (data?.data?.data) {
        dispatch({ type: ADD });
        if (message) {
          toast.success(message);
        }
      } else {
        toast.error(JSON.stringify(data?.data?.err || data?.data?.error));
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const updateMaster = async (path, id, body) => {
    try {
      const data = await ApiWithAuth.putDataWithAuth(
        `/${path}/${id}`,
        JSON.stringify(body)
      );
      if (data?.data?.data) {
        dispatch({ type: UPDATE });
        toast.success("Updated Data Success");
      } else {
        toast.error(data?.data?.error);
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const deleteMaster = async (path, id) => {
    try {
      const data = await ApiWithAuth.deleteDataWithAuth(`/${path}/${id}`);
      if (data?.data?.data) {
        dispatch({ type: DELETE });
        toast.success("Deleted Data Success");
      } else {
        toast.error(data?.data?.error);
      }
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const downloadMaster = async (path, parameter, fileName, ext) => {
    try {
      dispatch({
        loadingCheck: true,
      });
      const data = await ApiWithAuth.downloadDataWithAuth(
        `/${path}`,
        parameter,
        fileName,
        ext
      );
      dispatch({
        loadingCheck: false,
      });
      return data?.data;
    } catch (err) {
      console.error(err.message);
    }
  };

  const { data, loading, loadingCheck } = state;

  return (
    <AssetContext.Provider
      value={{
        data,
        book,
        payment,
        registerForm,
        loading,
        loadingCheck,
        loadingAction,
        getAllMaster,
        getMaster,
        addMaster,
        addMasterForm,
        addMasterNew,
        updateMaster,
        deleteMaster,
        downloadMaster,
      }}
    >
      {children}
    </AssetContext.Provider>
  );
};

export default MasterState;
