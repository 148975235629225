import React, { useContext, useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import FooterNavigation from "../footerNavigation";
import UserContext from "../../../../../services/user";
import { useTranslation } from "react-i18next";

function Step4(params) {
  const { t } = useTranslation();
  const { data, registerForm } = useContext(UserContext);
  const [error, setError] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [newSkill, setNewSkill] = useState("");
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [skill, setSkill] = useState([
    "Hiking",
    "Climbing",
    "Diving",
    "Snorkeling",
    "Caving",
    "Culinary",
  ]);

  const submitStep = async () => {
    if (selectedSkill.length < 1) {
      setError(true);
    } else {
      setError(false);
      const newData = Object.assign(data, { skills: selectedSkill });
      const response = await registerForm(newData);
      if (response) {
        params.nextStep();
      }
    }
  };

  return (
    <Card className="card-step border-0 shadow-sm">
      <Card.Body>
        <div className="pb-3 d-flex justify-content-between">
          <div>Skill/Expertise</div>
          <div
            role="button"
            className="text-primary small"
            onClick={() => setShowInput(true)}
          >
            + Add your own Expertise
          </div>
        </div>
        <div className="mb-3 p-4 custom-checkbox">
          <Row>
            {skill &&
              skill.map((item, index) => (
                <Col
                  sm="12"
                  md="6"
                  lg="4"
                  xl="4"
                  className="radio-group"
                  key={index}
                >
                  <Form.Check
                    type="checkbox"
                    id={`skill-${index}`}
                    label={item}
                    checked={selectedSkill.includes(item)}
                    onChange={(input) => {
                      setError(false);
                      input.target.checked
                        ? setSelectedSkill([
                            ...selectedSkill,
                            input.target.value,
                          ])
                        : setSelectedSkill(
                            selectedSkill.filter(
                              (item) => item !== input.target.value
                            )
                          );
                    }}
                    value={item}
                    reverse
                  />
                </Col>
              ))}
          </Row>
          {showInput && (
            <div className="mt-5">
              <InputGroup>
                <Form.Control
                  placeholder="Add New Skill"
                  value={newSkill}
                  onChange={(text) => setNewSkill(text.target.value)}
                />
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    setNewSkill("");
                    setShowInput(false);
                  }}
                >
                  {t("cancel")}
                </Button>
                <Button
                  variant="primary"
                  className="text-white px-4"
                  onClick={() => {
                    setError(false);
                    setSkill([...skill, newSkill]);
                    setSelectedSkill([...selectedSkill, newSkill]);
                    setNewSkill("");
                    setShowInput(false);
                  }}
                >
                  Add
                </Button>
              </InputGroup>
            </div>
          )}
        </div>
        {error && (
          <small className="text-danger">Skill are required. Minimum 1</small>
        )}

        <FooterNavigation
          totalSteps={params.totalSteps}
          currentStep={params.currentStep}
          previousStep={params.previousStep}
          nextStep={submitStep}
          disabled={false}
          submit={params.submit}
        />
      </Card.Body>
    </Card>
  );
}

export default Step4;
