import React from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import DesktopWrapper from "../../../layout/desktop/DesktopWrapper";
import ConfirmedImage from "../../../assets/icons/confirmed.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function PartnersDesktopPage() {
  const { t } = useTranslation();
  return (
    <DesktopWrapper>
      <Container className="text-center">
        <Image src={ConfirmedImage} className="mt-5 mb-4" />
        <div className="w-100 fw-bold h4 mb-3">Data sent successfully!</div>
        <div className="text-grey fw-light mb-5">
          Thank you for registering partners. This process will <br></br> take
          1-2 business days, please check back later.
        </div>
        <Button as={Link} to="/" className="text-white px-5 py-3 mb-5">
          {t("back_to_homepage")}
        </Button>
      </Container>
    </DesktopWrapper>
  );
}
export default PartnersDesktopPage;
