import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Form, Image, Modal, Row } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import Select from "react-select";
import id from "date-fns/locale/id";
import moment from "moment";
import RightArrowIcon from "../../../../assets/icons/rightArrow.svg";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../../services/user";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
registerLocale("id", id);

function ModalSearch(props) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [voucherCode, setVoucherCode] = useState(false);
  const [showPrice, setShowPrice] = useState(false);
  const [wholesalesDiscount, setWholesalesDiscount] = useState(0);
  const [campaignDiscount, setCampaignDiscount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [options, setOptions] = useState([]);
  const [startDate, setStartDate] = useState(moment().add(1, "days").valueOf());
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();

  const { loading, book, data } = useContext(UserContext);

  const getData = async (startDate, qty, _id) => {
    const newData = await book(
      startDate,
      qty,
      _id,
      wholesalesDiscount,
      campaignDiscount,
      totalPrice,
      voucherCode
    );
    if (newData) {
      setShow(false);
      navigate("/local-experience/booking/" + props?.id);
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (localStorage.getItem("_id")) {
      setShow(true);
    } else {
      toast.error("Hi, You must log in first before you can search ticket!");
      navigate("/login", { state: { prev: true } });
    }
  };
  const handleSubmit = () => {
    let allow = false;
    const startDateMoment = moment(startDate);
    if (data?.scheduleType?.allSeasonType === "every day") {
      allow = true;
    } else if (data?.scheduleType?.allSeasonType === "every week") {
      if (
        data?.scheduleType?.days.includes(
          startDateMoment.format("dddd").toLocaleLowerCase()
        )
      ) {
        allow = true;
      }
    } else if (data?.scheduleType?.allSeasonType === "every month") {
      if (data?.scheduleType?.date.includes(startDateMoment.month() + 1)) {
        allow = true;
      }
    } else if (data?.scheduleType?.allSeasonType === "seasonal") {
      if (
        startDateMoment.isBetween(
          moment(data?.scheduleType?.startDate),
          moment(data?.scheduleType?.endDate).add(1, "days")
        )
      ) {
        allow = true;
      }
    } else if (data?.scheduleType?.allSeasonType === "specific date") {
      if (
        data?.scheduleType?.schedule.includes(
          startDateMoment.format("DD/MM/YYYY")
        )
      ) {
        allow = true;
      }
    }

    if (allow) {
      if (selectedOption?.value) {
        getData(startDate, selectedOption?.value, props?.id);
      } else {
        toast.error("Hi, You must select number of guests first!");
      }
    } else {
      toast.error("Departure Date Not Available");
    }
  };

  const optionChange = (opt) => {
    setSelectedOption(opt);
  };

  useEffect(() => {
    let newOption = [];
    for (
      let i = data?.productDetail?.minPerson;
      i <= data?.productDetail?.maxPerson;
      i++
    ) {
      newOption.push({
        value: i,
        label: i + t("guests"),
      });
    }

    setOptions(newOption);
  }, [data?.productDetail?.minPerson, t("guests")]);

  useEffect(() => {
    if (startDate && selectedOption) {
      if (
        moment(startDate).isBetween(
          moment(data?.pricingDetail?.campaign?.startDate, "YYYY-MM-DD"),
          moment(data?.pricingDetail?.campaign?.endDate, "YYYY-MM-DD").add(
            1,
            "days"
          )
        ) &&
        selectedOption?.value
      ) {
        const maxPax =
          data?.pricingDetail?.campaign?.maxPax - selectedOption?.value;
        let total = 0;
        if (maxPax > 0) {
          total = selectedOption?.value * data?.pricingDetail?.campaign?.total;
        } else {
          total =
            data?.pricingDetail?.campaign?.maxPax *
              data?.pricingDetail?.campaign?.total +
            (selectedOption?.value - data?.pricingDetail?.campaign?.maxPax) *
              data?.pricingDetail?.publishedPrice;
        }
        let discountCampaign =
          data?.pricingDetail?.publishedPrice * selectedOption?.value - total;

        setTotalPrice(total);
        setCampaignDiscount(discountCampaign);
        setWholesalesDiscount(0);
      } else {
        const i = data?.wholeSale.findIndex(
          (e) => e.pax === selectedOption.value
        );
        if (i > -1) {
          let wholesalesPrices =
            data?.wholeSale[i].type === "amount"
              ? data?.wholeSale[i].total
              : props?.data?.pricingDetail?.publishedPrice *
                data?.wholeSale[i].total;
          let totalPrices =
            (props?.data?.pricingDetail?.publishedPrice - wholesalesPrices) *
            selectedOption.value;
          setTotalPrice(totalPrices);
          setWholesalesDiscount(wholesalesPrices * selectedOption.value);
          setCampaignDiscount(0);
        } else {
          setTotalPrice(
            props?.data?.pricingDetail?.publishedPrice * selectedOption.value
          );
        }
      }
    }
  }, [startDate, selectedOption]);

  return (
    <>
      <Button
        variant="primary"
        className="text-white w-100"
        onClick={handleShow}
      >
        {t("book_now")}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        dialogClassName="modal-search"
      >
        <Modal.Body>
          <Row>
            <Col sm="12" md="12" lg="6" xl="6" className="vertical-line">
              <div className="pb-3 fs-5">{t("choose_date")}</div>
              <div>
                <DatePicker
                  selected={startDate}
                  locale="id"
                  inline
                  onChange={(date) => setStartDate(date)}
                  filterDate={(date) => {
                    return moment() < date;
                  }}
                  className="inline-calender"
                />
              </div>
            </Col>
            <Col sm="12" md="12" lg="6" xl="6">
              <div className="mb-4">
                <div className="pb-3 fs-5">{t("guest")}</div>
                <Select
                  className="select-input"
                  classNamePrefix="select"
                  defaultValue={selectedOption}
                  onChange={(opt) => optionChange(opt)}
                  placeholder={t("select_number_of_guests")}
                  isSearchable={false}
                  options={options}
                />
              </div>
              <div className="mb-4">
                <div className="pb-3 fs-5">{t("voucher_code")}</div>
                <Form.Control
                  className="control-input"
                  placeholder={t("enter_voucher_code")}
                  onChange={(evt) => {
                    setVoucherCode(evt?.target?.value);
                  }}
                />
              </div>
              <div className="mb-4">
                <div className="pb-3 fs-5">{t("order_summary")}</div>
                <Card className="order-summary">
                  <Card.Body className="px-2 py-3">
                    <div className="d-flex position-relative">
                      <Image
                        src={
                          props.data?.generalInformation?.picture?.length > 0 &&
                          process.env.REACT_APP_FILE_URL +
                            "/" +
                            props?.data?.generalInformation?.picture[0]
                        }
                      />
                      <div className="ps-3 d-block">
                        <div className="text-title">
                          {props?.data?.generalInformation?.name}
                        </div>
                        <div className="mt-auto position-absolute">
                          {props?.data?.pricingDetail?.crossOutPrice !==
                            props.data?.pricingDetail?.publishedPrice && (
                            <span className="small text-decoration-line-through text-muted">
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(
                                props?.data?.pricingDetail?.crossOutPrice
                              )}
                            </span>
                          )}
                          <div>
                            <span className="fw-bold">
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                                minimumFractionDigits: 0,
                              }).format(
                                props?.data?.pricingDetail?.publishedPrice
                              )}
                            </span>
                            <span> / pax</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="text-muted">
                          {t("date_of_departure")}
                        </div>
                        <div className="fw-bold">
                          {moment(startDate).format("LL")}
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="text-muted">{t("trip_duration")}</div>
                        <div className="fw-bold">
                          {props?.data?.pricingDetail?.duration} {t("days")}
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
                <div className="d-flex mt-3 align-item-center justify-content-between">
                  <div className="text-muted">{t("total_price")}</div>
                  <div className="text-primary fs-5 fw-bold d-flex align-items-between">
                    <div className="pe-2">
                      {selectedOption &&
                        new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          minimumFractionDigits: 0,
                        }).format(totalPrice)}
                    </div>
                    <Image
                      src={RightArrowIcon}
                      style={{ transform: "rotate(90deg)" }}
                      role="button"
                      onClick={() => setShowPrice(true)}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <Button
                  variant="outline-primary"
                  className="w-25 me-3"
                  onClick={handleClose}
                >
                  {t("cancel")}
                </Button>
                <Button
                  variant={selectedOption ? "primary" : "grey"}
                  className="text-white w-75"
                  disabled={selectedOption ? false : true}
                  onClick={handleSubmit}
                >
                  {t("book_now")}
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={showPrice}
        onHide={() => setShowPrice(false)}
        size="sm"
        centered
        className="bg-grey bg-opacity-75"
      >
        <Modal.Body>
          <div className="mb-4">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="text-muted">Price (x{selectedOption?.value})</div>
              <div>
                {selectedOption &&
                  new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                  }).format(
                    props?.data?.pricingDetail?.crossOutPrice *
                      selectedOption?.value
                  )}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="text-muted">{t("discount")}</div>
              <div>
                -{" "}
                {selectedOption &&
                  new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                  }).format(
                    (props?.data?.pricingDetail?.crossOutPrice -
                      props?.data?.pricingDetail?.publishedPrice) *
                      selectedOption?.value
                  )}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="text-muted">
                {t("wholesales")} (x{selectedOption?.value})
              </div>
              <div>
                -{" "}
                {selectedOption &&
                  new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                  }).format(wholesalesDiscount)}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="text-muted">
                Campaign (x{selectedOption?.value})
              </div>
              <div>
                -{" "}
                {selectedOption &&
                  new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                  }).format(campaignDiscount)}
              </div>
            </div>
            <hr></hr>
            <div className="d-flex align-items-center justify-content-between">
              <div className="fw-bold">{t("total_price")}</div>
              <div className="fw-bold">
                {selectedOption &&
                  new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                  }).format(totalPrice)}
              </div>
            </div>
          </div>
          <Button
            className="text-white w-100"
            onClick={() => setShowPrice(false)}
          >
            Close
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalSearch;
