import { Image, Nav } from "react-bootstrap";
import UserIcon from "../../../assets/icons/user.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function LoginModal(params) {
  const { t } = useTranslation();
  return (
    <>
      <Nav.Item
        className="btn btn-light rounded-pill p-2 m-2 fw-normal d-flex align-items-end"
        // onClick={handleShow}
        to="/login"
        as={Link}
      >
        <Image src={UserIcon} />
        <span>{t("login")}</span>
      </Nav.Item>
    </>
  );
}

export default LoginModal;
