import React, { useContext } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Card, Form } from "react-bootstrap";
import FooterNavigation from "../footerNavigation";
import { useForm } from "react-hook-form";
import UserContext from "../../../../../services/user";
import { useTranslation } from "react-i18next";

const schema = yup
  .object({
    personalCustomerService: yup.object().shape({
      phoneNumber: yup.string().required("Phone Number is required"),
      handphoneNumber: yup.string().required("Handphone Number is required"),
      email: yup.string().email("Email must be a valid email").required("Email is required"),
      // emergencyCall: yup.string().required(),
      insurance: yup.string().required("Insurance is required"),
    }),
  })
  .required();

function Step4(params) {
  const { t } = useTranslation();
  const { data, registerForm } = useContext(UserContext);
  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const submitStep = async (values) => {
    const newData = Object.assign(data, values);
    const response = await registerForm(newData);
    if (response) {
      params.nextStep();
    }
  };

  return (
    <Card className="card-step border-0 shadow-sm">
      <Card.Body>
        <div className="pb-3">Customer Service</div>
        <div className="mb-3">
          <Form.Control
            className="control-input"
            placeholder={t("phone_number")}
            {...register("personalCustomerService.phoneNumber")}
          />
          <small className="text-danger">
            {errors.personalCustomerService?.phoneNumber?.message}
          </small>
        </div>
        <div className="mb-3">
          <Form.Control
            className="control-input"
            placeholder="Handphone Number"
            {...register("personalCustomerService.handphoneNumber")}
          />
          <small className="text-danger">
            {errors.personalCustomerService?.handphoneNumber?.message}
          </small>
        </div>
        <div className="mb-3">
          <Form.Control
            type="email"
            className="control-input"
            placeholder={t("email_address")}
            {...register("personalCustomerService.email")}
          />
          <small className="text-danger">
            {errors.personalCustomerService?.email?.message}
          </small>
        </div>
        <div className="mb-3">
          <Form.Control
            className="control-input"
            placeholder="Emergency Call (optional)"
            {...register("personalCustomerService.emergencyCall")}
          />
          <small className="text-danger">
            {errors.personalCustomerService?.emergencyCall?.message}
          </small>
        </div>
        <div className="radio-group mb-3">
          <div className="pb-3">Insurance</div>
          <Form.Check
            label="Available and Included in Products"
            {...register("personalCustomerService.insurance")}
            type="radio"
            id={`radio-1`}
            value="Available and Included in Product"
            className="pb-2"
          />
          <Form.Check
            label="Available but Not Included in the Product"
            {...register("personalCustomerService.insurance")}
            type="radio"
            id={`radio-2`}
            value="Available but Not Included in the Product"
            className="pb-2"
          />
          <Form.Check
            label="Not Available"
            {...register("personalCustomerService.insurance")}
            type="radio"
            id={`radio-3`}
            value="Not Available"
            className="pb-2"
          />
          <small className="text-danger">
            {errors.personalCustomerService?.insurance?.message}
          </small>
        </div>
        <FooterNavigation
          totalSteps={params.totalSteps}
          currentStep={params.currentStep}
          previousStep={params.previousStep}
          nextStep={handleSubmit(submitStep)}
          disabled={false}
          submit={params.submit}
        />
      </Card.Body>
    </Card>
  );
}

export default Step4;
