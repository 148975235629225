import React from "react";
import { BrowserView } from "react-device-detect";
import DestinationDesktopPage from "./desktop";

function DestinationPage() {
  return (
    // <BrowserView>
      <DestinationDesktopPage />
    // </BrowserView>
  );
}
export default DestinationPage;
