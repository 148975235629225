import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import GoogleMapReact from "google-maps-react-markers";
import DesktopWrapper from "../../../layout/desktop/DesktopWrapper";
import HeroProduct from "../../../component/desktop/card/heroProduct";
import StarIcon from "../../../assets/icons/star.svg";
import ClockOutlineIcon from "../../../assets/icons/clockOutline.svg";
import InfoOutlineIcon from "../../../assets/icons/infoOutline.svg";
import PeopleOutlineIcon from "../../../assets/icons/peopleOutline.svg";
import PinOutlineIcon from "../../../assets/icons/pinOutline.svg";
import CheckmarkIcon from "../../../assets/icons/checkmark.svg";
import CrossIcon from "../../../assets/icons/cross.svg";
import PinMapsIcon from "../../../assets/icons/pinMaps.svg";
import ShieldCheckmarkIcon from "../../../assets/icons/shieldCheckmark.svg";
import PeopleOutlineOrangeIcon from "../../../assets/icons/peopleOutlineOrange.svg";
import FillTrayFullIcon from "../../../assets/icons/fillTrayFull.svg";
import ModalSearch from "./components/modalSearch";
import UserContext from "../../../services/user";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import moment from "moment";
import { SkeletonCustom } from "../../../component/global/skeleton";
import { useTranslation } from "react-i18next";

function DetailLocalExperienceDesktopPage() {
  const { t } = useTranslation();
  let { id } = useParams();
  const { loading, getMaster } = useContext(UserContext);
  const [data, setData] = useState({});
  const [showMore, setShowMore] = useState(false);

  const getData = async () => {
    const newData = await getMaster("product", id);
    if (newData) {
      setData(newData.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const changeArray = (data) => {
    return data && data?.map((item, index) => " " + item.toUpperCase());
  };

  const changeArrayMonth = (data) => {
    return (
      data &&
      data?.map(
        (item, index) =>
          " " +
          moment()
            .month(item - 1)
            .format("MMMM")
      )
    );
  };

  const changeArrayDate = (data) => {
    return (
      data &&
      data?.map(
        (item, index) => " " + moment(item, "DD/MM/YYYY").format("DD MMMM YYYY")
      )
    );
  };

  return (
    <DesktopWrapper>
      <Container className="detail-product py-4">
        {loading ? (
          <SkeletonCustom className="mb-4" height="600px" />
        ) : (
          <HeroProduct
            picture={data?.generalInformation?.picture}
            data={data}
          />
        )}
        <Row>
          <Col sm="12" md="7" lg="9" xl="9">
            <Row>
              <Col sm="12" md="12" lg="12" xl="12">
                {loading ? (
                  <SkeletonCustom height="100px" />
                ) : (
                  <>
                    <h4 className="fw-bold">
                      {data?.generalInformation?.name}
                    </h4>
                    <div className="d-flex align-items-center fw-light pb-2 location-le">
                      {/* <Image src={StarIcon} width="27px" height="27px" />
                      <div className="pe-1 ps-2">{data?.rating || "5"}</div>
                      <div>(-)</div> */}
                      <div className="text-muted">
                        {data?.productDetail?.city +
                          ", " +
                          data?.productDetail?.province}
                      </div>
                      <div className="px-1">&#x2022;</div>
                      <div className="text-muted">
                        {data?.type?.toUpperCase()}
                      </div>
                    </div>
                  </>
                )}
                {loading ? (
                  <SkeletonCustom height="300px" className="py-3" />
                ) : (
                  <p
                    className="text-secondary fw-light py-3 detail-description"
                    style={{
                      lineHeight: "1.5rem",
                      fontFamily: "Poppins !important",
                    }}
                  >
                    <div className="mobile-version detail-description">
                      <div className="mobile-short">
                        {!showMore && (
                          <>
                            {data?.generalInformation?.shortDescription}
                            <span
                              className="ms-1 text-primary"
                              role="button"
                              onClick={() => setShowMore(true)}
                            >
                              Show more
                            </span>
                          </>
                        )}
                      </div>
                      <div className="mobile-long detail-description">
                        {showMore && (
                          <>
                            {data?.generalInformation?.description &&
                              parse(data?.generalInformation?.description)}
                            <span
                              className="text-primary"
                              role="button"
                              onClick={() => setShowMore(false)}
                            >
                              Show less
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="desktop-version">
                      {data?.generalInformation?.description &&
                        parse(data?.generalInformation?.description)}
                    </div>
                  </p>
                )}
                {loading ? (
                  <SkeletonCustom height="200px" className="py-3" />
                ) : (
                  <Row className="fw-light gy-4 font-sans fs-12">
                    <Col xs="6" sm="6" md="3" lg="3" xl="3">
                      <div className="d-flex align-items-center">
                        <Image src={PinOutlineIcon} />
                        <span className="ps-2">
                          {data?.productDetail?.city +
                            ", " +
                            data?.productDetail?.province}
                        </span>
                      </div>
                    </Col>
                    <Col xs="6" sm="6" md="2" lg="2" xl="2">
                      <div className="d-flex align-items-center">
                        <Image src={ClockOutlineIcon} />
                        <span className="ps-2">
                          {data?.pricingDetail?.duration} {t("days")}
                        </span>
                      </div>
                    </Col>
                    <Col xs="6" sm="6" md="4" lg="4" xl="4">
                      <div className="d-flex align-items-center">
                        <Image src={PeopleOutlineIcon} />
                        <span className="ps-2">
                          {data?.productDetail?.tripType?.toUpperCase()}{" "}
                          {t("up_to")} {data?.productDetail?.maxPerson}{" "}
                          {t("person")}
                        </span>
                      </div>
                    </Col>
                    <Col xs="6" sm="6" md="3" lg="3" xl="3">
                      <div className="d-flex align-items-center">
                        <Image src={InfoOutlineIcon} />
                        <span className="ps-2">
                          {t("can_not_be_cancelled")}
                        </span>
                      </div>
                    </Col>
                  </Row>
                )}
                <hr className="my-5"></hr>
              </Col>
              <Col sm="12" md="12" lg="12" xl="12">
                <Row className="fw-light gy-4">
                  <Col sm="12" md="12" lg="12" xl="12" className="h5 fw-bold">
                    {t("whats_included")}
                  </Col>
                  {loading ? (
                    <SkeletonCustom height="200px" />
                  ) : (
                    data?.facilities?.include?.map(
                      (item, index) =>
                        item.value && (
                          <Col sm="12" md="12" lg="6" xl="6" key={index}>
                            <div className="d-flex align-items-center">
                              <Image src={`/assets/icon/${item.icon}`} />
                              <span className="ps-2">{item.value}</span>
                            </div>
                          </Col>
                        )
                    )
                  )}
                </Row>
                <hr className="my-3"></hr>
              </Col>

              <Col sm="12" md="12" lg="12" xl="12">
                <Row className="fw-light gy-4">
                  <Col sm="12" md="12" lg="12" xl="12" className="h5 fw-bold">
                    {t("schedule")}
                  </Col>
                  {loading ? (
                    <SkeletonCustom height="200px" />
                  ) : (
                    <Col sm="12" md="12" lg="12" xl="12">
                      {data?.scheduleType?.allSeasonType === "every day" &&
                        "Every Day"}
                      {data?.scheduleType?.allSeasonType === "every week" &&
                        "Every Week: " + changeArray(data?.scheduleType?.days)}
                      {data?.scheduleType?.allSeasonType === "every month" &&
                        "Every Month: " +
                          changeArrayMonth(data?.scheduleType?.date)}
                      {data?.scheduleType?.allSeasonType === "seasonal" &&
                        "Seasonal: " +
                          moment(data?.scheduleType?.startDate).format("LL") +
                          " s.d " +
                          moment(data?.scheduleType?.endDate).format("LL")}
                      {data?.scheduleType?.allSeasonType === "specific date" &&
                        "Specific Date: " +
                          changeArrayDate(data?.scheduleType?.schedule)}
                    </Col>
                  )}
                </Row>
                <hr className="my-3"></hr>
              </Col>

              <Col sm="12" md="12" lg="12" xl="12">
                <Row className="fw-light gy-4">
                  <Col sm="12" md="12" lg="12" xl="12" className="h5 fw-bold">
                    {t("itinerary")}
                  </Col>
                  {loading ? (
                    <SkeletonCustom height="200px" />
                  ) : (
                    <Col sm="12" md="12" lg="12" xl="12">
                      <div className="timeline font-sans">
                        <div className="tl-item">
                          <div className="tl-dot"></div>
                          <div className="tl-content">
                            <div className="tl-date fw-bold mt-1">
                              Titik pertemuan
                            </div>
                            <div className="tl-body">
                              {data?.productDetail?.meetingLocation}
                            </div>
                          </div>
                        </div>
                        {data?.itinerary &&
                          data?.itinerary.map(
                            (item, index) =>
                              item.activity &&
                              item.activity.map((subItem, subIndex) => (
                                <div
                                  className="tl-item"
                                  key={index + "_" + subIndex}
                                >
                                  <div className="tl-dot"></div>
                                  <div className="tl-content">
                                    <div className="tl-date fw-bold mt-1">
                                      {subIndex === 0 && (
                                        <div className="pb-2 fs-5">
                                          DAY {item.day}
                                        </div>
                                      )}
                                      <span>{subItem.time}</span>
                                    </div>
                                    <div className="tl-body">
                                      {subItem.description}
                                    </div>
                                  </div>
                                </div>
                              ))
                          )}
                      </div>
                    </Col>
                  )}
                </Row>
                <hr className="my-3"></hr>
              </Col>

              <Col sm="12" md="12" lg="12" xl="12">
                <Row className="fw-light gy-4">
                  <Col sm="12" md="12" lg="12" xl="12" className="h5 fw-bold">
                    {t("meeting_point")}
                  </Col>
                  {loading ? (
                    <SkeletonCustom height="200px" />
                  ) : (
                    <Col sm="12" md="12" lg="12" xl="12">
                      <div className="">
                        {data?.productDetail?.meetingLocation}
                      </div>
                      <div
                        className="mt-4"
                        style={{ height: "400px", width: "auto" }}
                      >
                        <GoogleMapReact
                          apiKey={process.env.REACT_APP_API_KEY}
                          className="rounded"
                          defaultCenter={{
                            lat: parseFloat(data?.productDetail?.lat),
                            lng: parseFloat(data?.productDetail?.long),
                          }}
                          defaultZoom={13}
                        >
                          <div
                            lat={parseFloat(data?.productDetail?.lat)}
                            lng={parseFloat(data?.productDetail?.long)}
                          >
                            <Image src={PinMapsIcon} />
                          </div>
                        </GoogleMapReact>
                      </div>
                    </Col>
                  )}
                </Row>
                <hr className="my-5"></hr>
              </Col>

              <Col sm="12" md="12" lg="12" xl="12">
                <Row className="fw-light gy-4">
                  <Col sm="12" md="12" lg="12" xl="12" className="h5 fw-bold">
                    {t("important_information")}
                  </Col>
                  {loading ? (
                    <SkeletonCustom height="200px" />
                  ) : (
                    <Col sm="12" md="12" lg="12" xl="12">
                      <Row className="fw-light gy-4 align-items-center font-sans important-policy">
                        <Col sm="12" md="12" lg="12" xl="12">
                          <div className="d-flex align-items-start">
                            <Image src={ShieldCheckmarkIcon} />
                            <div className="ps-3">
                              <div className="fw-bold pb-2">
                                {t("cancellation_policy")}
                              </div>
                              {data?.additionalInfo?.cancellationPolicy?.map(
                                (item, index) => (
                                  <div
                                    className="item-important-policy mb-2"
                                    key={index}
                                  >
                                    {item}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col sm="12" md="12" lg="12" xl="12">
                          <div className="d-flex align-items-start">
                            <Image src={PeopleOutlineOrangeIcon} />
                            <div className="ps-3">
                              <div className="fw-bold pb-2">
                                {t("terms_conditions")}
                              </div>
                              {data?.additionalInfo?.termAndCondition?.map(
                                (item, index) => (
                                  <div
                                    className="item-important-policy mb-2"
                                    key={index}
                                  >
                                    {item}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col sm="12" md="12" lg="12" xl="12">
                          <div className="d-flex align-items-start">
                            <Image src={FillTrayFullIcon} />
                            <div className="ps-3">
                              <div className="fw-bold pb-2">
                                {t("additional_info")}
                              </div>
                              {data?.additionalInfo?.additionalInfo?.map(
                                (item, index) => (
                                  <div
                                    className="item-important-policy mb-2"
                                    key={index}
                                  >
                                    {item}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col sm="12" md="5" lg="3" xl="3">
            <Card className="border-0 shadow-sm sticky-price">
              <Card.Body>
                <div className="text-muted small pb-3">{t("start_from")}</div>
                <div className="pb-4">
                  <span className="fw-bold fs-4">
                    {new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      minimumFractionDigits: 0,
                    }).format(data?.pricingDetail?.publishedPrice)}
                  </span>
                  <span> / pax</span>
                </div>
                <ModalSearch data={data} id={id} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </DesktopWrapper>
  );
}
export default DetailLocalExperienceDesktopPage;
